import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Dashboard.css';
import SidebarEmployee from '../../components/sidebar/SidebarEmployee';
import SearchBarEmployee from '../../components/sidebar/SearchBarEmployee';
import ViewBonousInsentive from '../Bonous_Insentive/ViewBonousInsentive';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner


function E_BonousList({ handleLogout, username }) {
    const [employees, setEmployees] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [bonusIncentive, setBonusIncentive] = useState([]);
    const [paymentBonusIncentive, setPaymentBonusIncentive] = useState([]);
    const [filteredBonusIncentive, setFilteredBonusIncentive] = useState([]);
    const [loadingBonusIncentive, setLoadingBonusIncentive] = useState(false);
    const [loadingPaymentBonusIncentive, setLoadingPaymentBonusIncentive] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [viewBonousInsentive, setviewBonousInsentive] = useState(null);
    const [isviewBonousInsentive, setIsviewBonousInsentive] = useState(false);
    const [paymentBonousInsentive, setpaymentBonousInsentive] = useState(null);
    const [ispaymentBonousInsentive, setIspaymentBonousInsentive] = useState(false);

    const employeeId = localStorage.getItem('employeeId');

    useEffect(() => {
        if (employeeId) {
            fetchEmployeeDetails(employeeId);
            fetchBonusHistory(employeeId);
            fetchBonusPaymentHistory(employeeId);
        }
    }, [employeeId]);

    useEffect(() => {
        filterBonusIncentiveByDate();
    }, [startDate, endDate, bonusIncentive]);


    const fetchEmployeeDetails = async (employeeId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);
            setEmployees(response.data || []);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchBonusHistory = async (employeeId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/bonousinsentivehistory/${employeeId}`);
            setBonusIncentive(response.data || []);
        } catch (error) {
            console.error("Error fetching bonus/incentive history:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const filterBonusIncentiveByDate = () => {
        if (startDate && endDate) {
            const start = new Date(startDate).setHours(0, 0, 0, 0);
            const end = new Date(endDate).setHours(0, 0, 0, 0);
            const filtered = bonusIncentive.filter(record => {
                const paymentDate = new Date(record.paymentDate).setHours(0, 0, 0, 0);
                return paymentDate >= start && paymentDate <= end;
            });
            setFilteredBonusIncentive(filtered);
        } else {
            setFilteredBonusIncentive(bonusIncentive);
        }
    };

    const fetchBonusPaymentHistory = async (employeeId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/bonousinsentive/paymenthistory/${employeeId}`);
            setPaymentBonusIncentive(response.data || []);
        } catch (error) {
            console.error("Error fetching bonus/incentive payment history:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const calculateTotalPayments = (bonusId) => {
        const payments = paymentBonusIncentive.filter(payment => payment.bonousinsentiveId === bonusId);
        return payments.reduce((total, payment) => total + parseFloat(payment.paymentAmount), 0);
    };

    const getStatusStyle = (status) => {
        switch (status) {
            case 'Completed':
                return { backgroundColor: 'blue', color: 'white' };
            case 'Partially Paid':
                return { backgroundColor: 'yellow', color: 'black' };
            case 'Pending':
                return { backgroundColor: 'red', color: 'white' };
            default:
                return {};
        }
    };

    const handleViewBonousInsentive = (record) => {
        setviewBonousInsentive(record);
        setIsviewBonousInsentive(true);
    };

    const handlePaymentBonousInsentive = (record) => {
        setpaymentBonousInsentive(record);
        setIspaymentBonousInsentive(true);
    };

    return (
        <div>
            <div className='d-flex w-100 h-100 bg-white'>
                <SidebarEmployee />
                <div className='w-100'>
                    <SearchBarEmployee username={username} handleLogout={handleLogout} />
                    <div className="container-fluid">
                        <ToastContainer />

                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">Bonus and Incentive List</h6>
                                    </div>
                                    <div style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }} className="card-body">
                                        <div className='row' >
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {/* Correct usage of spinner */}
                                                    <ThreeDots
                                                        color="#00BFFF"
                                                        height={80}
                                                        width={80}
                                                    />
                                                </div>
                                            ) : (
                                                filteredBonusIncentive.length > 0 ? (
                                                    filteredBonusIncentive.map((record) => {
                                                        const totalPayments = calculateTotalPayments(record.id);
                                                        const amountDue = parseFloat(record.amount) - totalPayments;
                                                        const paymentStatus = amountDue === 0 ? 'Completed'
                                                            : amountDue < parseFloat(record.amount) ? 'Partially Paid'
                                                                : 'Pending';

                                                        return (
                                                            <div className="col-xl-3 col-md-6 mb-4" key={record.id}>
                                                                <div className="card shadow h-100 py-1">
                                                                    <div className="card-body">
                                                                        <div className="row no-gutters align-items-center">
                                                                            <div className="col-md-12">
                                                                                <p className="m-0 fw-bolder">
                                                                                    Declaration Date: {new Date(record.declarationDate).toLocaleDateString()}
                                                                                </p>

                                                                                <div className="row">
                                                                                    <div className="col-sm">
                                                                                        Reason
                                                                                    </div>
                                                                                    <div className="col-sm">
                                                                                        <p className="m-0">: {record.reason}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-sm">
                                                                                        Payment Type
                                                                                    </div>
                                                                                    <div className="col-sm">
                                                                                        <p className="m-0 text-capitalize">: {record.paymentType}</p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="row">
                                                                                    <div className="col-sm">
                                                                                        Total Amount
                                                                                    </div>
                                                                                    <div className="col-sm">
                                                                                        <p className="m-0">: &#x20B9;{parseFloat(record.amount).toFixed(2)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-sm">
                                                                                        Amount Paid
                                                                                    </div>
                                                                                    <div className="col-sm">
                                                                                        <p className="m-0">: &#x20B9;{totalPayments.toFixed(2)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-sm">
                                                                                        Amount Due
                                                                                    </div>
                                                                                    <div className="col-sm">
                                                                                        <p className="m-0">: &#x20B9;{amountDue.toFixed(2)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <hr />
                                                                                <div className="d-flex align-items-center justify-content-center">
                                                                                    <span className="badge p-2" style={getStatusStyle(paymentStatus)}>
                                                                                        {paymentStatus}
                                                                                    </span>
                                                                                    <button
                                                                                        className="m-1 btn btn-info btn-sm"
                                                                                        onClick={() => handleViewBonousInsentive(record)}
                                                                                    >
                                                                                        <i className="fa fa-eye" aria-hidden="true"></i> View
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <div className="col-12" style={{ textAlign: 'center' }}>
                                                        <h5>No Bonus records found.</h5>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isviewBonousInsentive && (
                <ViewBonousInsentive
                    record={viewBonousInsentive}
                    onClose={() => setIsviewBonousInsentive(false)}
                />
            )}
        </div>
    );
}

export default E_BonousList;
