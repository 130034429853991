import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Dashboard.css';
import SidebarEmployee from '../../components/sidebar/SidebarEmployee';
import SearchBarEmployee from '../../components/sidebar/SearchBarEmployee';
import RepaymentHistory from '../EmployeeMaster/RepaymentHistory';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner


function E_loanList({ handleLogout, username }) {
    const [loanRecords, setLoanRecords] = useState([]);
    const [repaymentRecords, setRepaymentRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [isLoanRepaymentHistory, setIsLoanRepaymentHistory] = useState(false);
    const [selectedLoan, setSelectedLoan] = useState(null);
    const employeeId = localStorage.getItem('employeeId');

    useEffect(() => {
        if (employeeId) {
            fetchEmployeeDetails(employeeId);
        }
        fetchLoanDetails();
        fetchRepaymentDetails();
    }, [employeeId]);

    const fetchEmployeeDetails = async (employeeId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);
            if (response.data.length > 0) {
                const employeeData = response.data[0];
                setSelectedEmployee(employeeData);
                console.log("Employee Details:", employeeData);
            }
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const fetchLoanDetails = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/loandetails/${employeeId}`);
            setLoanRecords(response.data);
        } catch (error) {
            console.error("Error fetching loan details:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchRepaymentDetails = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/repaymentdetails/${employeeId}`);
            setRepaymentRecords(response.data);
        } catch (error) {
            console.error("Error fetching repayment details:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleLoanRepaymentHistory = (record) => {
        setSelectedLoan(record);
        setIsLoanRepaymentHistory(true);
    };

    const calculateTotalPayments = (loanId) => {
        const payments = repaymentRecords.filter(payment => payment.loanId === loanId);
        return payments.reduce((total, payment) => total + parseFloat(payment.repaymentAmount || 0), 0);
    };

    const getStatusStyle = (status) => {
        switch (status) {
            case 'Completed':
                return { backgroundColor: 'blue', color: 'white' };
            case 'Partially Paid':
                return { backgroundColor: 'yellow', color: 'black' };
            case 'Pending':
                return { backgroundColor: 'red', color: 'white' };
            default:
                return {};
        }
    };

    const getTotalRepaymentAmount = (loanId) => {
        const repaymentsForLoan = repaymentRecords.filter(repayment => repayment.loanId === loanId);
        const totalRepaymentAmount = repaymentsForLoan.reduce((total, repayment) => total + parseFloat(repayment.repaymentAmount || 0), 0);
        return totalRepaymentAmount.toFixed(2);
    };

    const calculateLoanDue = (loanAmount, totalRepaymentAmount) => {
        const loanDue = parseFloat(loanAmount) - parseFloat(totalRepaymentAmount);
        return loanDue.toFixed(2);
    };

    const getLoanStatus = (loanDue) => {
        return parseFloat(loanDue) === 0 ? 'Closed' : 'Open';
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    };

    return (
        <div className=''>
            <div className='d-flex w-100 h-100 bg-white'>
                <SidebarEmployee />
                <div className='w-100'>
                    <SearchBarEmployee username={username} handleLogout={handleLogout}/>
                    <div className="container-fluid">
                        <ToastContainer />
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">Loan List</h6>
                                    </div>
                                    <div style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }} className="card-body">
                                        <div className="row">
                                            {/* <!-- Salary Ledger Card --> */}
                                            {loading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {/* Correct usage of spinner */}
                                                    <ThreeDots
                                                        color="#00BFFF"
                                                        height={80}
                                                        width={80}
                                                    />
                                                </div>
                                            ) : (
                                            loanRecords.length > 0 ? (
                                                loanRecords.map((record) => {
                                                    return (
                                                        <div className="col-xl-3 col-md-6 mb-4" key={record.id}>
                                                            <div className="card shadow h-100 py-1">
                                                                <div className="card-body">
                                                                    <div className="row no-gutters align-items-center">
                                                                        <div className="col-md-12">

                                                                            <p className="m-0 fw-bolder">
                                                                                Loan No: {record.loanNumber}
                                                                            </p>
                                                                            <div class="row">
                                                                                <div class="col-sm">
                                                                                    Month & Year
                                                                                </div>
                                                                                <div class="col-sm">
                                                                                    <p className="m-0">:{new Date(record.loanDate).toLocaleDateString()}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-sm">
                                                                                    Loan For 
                                                                                </div>
                                                                                <div class="col-sm">
                                                                                    <p className="m-0 text-capitalize">: {record.loanFor}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-sm">
                                                                                    Loan Amt.
                                                                                </div>
                                                                                <div class="col-sm">
                                                                                    <p className="m-0">: &#x20B9;{record.loanAmount.toFixed(2)}</p>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            
                                                                            <div class="row">
                                                                                <div class="col-sm">
                                                                                   Repayment Amt.
                                                                                </div>
                                                                                <div class="col-sm">
                                                                                    <p className="m-0">: &#x20B9;{getTotalRepaymentAmount(record.id)}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-sm">
                                                                                    Amount Due
                                                                                </div>
                                                                                <div class="col-sm">
                                                                                    <p className="m-0">: &#x20B9;{calculateLoanDue(record.loanAmount, getTotalRepaymentAmount(record.id))}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row">
                                                                                <div class="col-sm">
                                                                                    Status 
                                                                                </div>
                                                                                <div class="col-sm">
                                                                                    <p style={getStatusStyle(getLoanStatus(calculateLoanDue(record.loanAmount, getTotalRepaymentAmount(record.id))))} className="m-0">: {getLoanStatus(calculateLoanDue(record.loanAmount, getTotalRepaymentAmount(record.id)))}</p>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="d-flex align-items-center justify-content-center">
                                                                                <button className="btn btn-outline-success py-1 px-4" onClick={() => handleLoanRepaymentHistory(record)}>View</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div className="col-12" style={{ textAlign: 'center' }}>
                                                    <h5>No loan records found.</h5>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isLoanRepaymentHistory && (
                <RepaymentHistory
                    loan={selectedLoan}
                    onClose={() => setIsLoanRepaymentHistory(false)}
                />
            )}
        </div>
    );
}

export default E_loanList;
