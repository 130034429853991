import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';

const EditProject = ({ project, onClose, onUpdate }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        projectName: '',
        companyName: '',
        projectType: '',
        principalEmployeeName: '',
        principalEmployeeProjectAddress: '',
        employeerstate: '',
        employeercity: '',
        employeerpincode: '',
        projectAddress: '',
        projectstate: '',
        projectcity: '',
        projectpincode: '',
        projectManagerName: '',
        projectDescription: '',
        document: null,
        employee_id: '',
        username: localStorage.getItem('username'),
    });

    useEffect(() => {
        if (project) {
            setFormData({ ...project, document: null });  // Keeping picture as null initially
        }
    }, [project]);

    const [errors, setErrors] = useState({});
    const [employees, setEmployees] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [states, setStates] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [employeeResponse, companyResponse, stateResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`),
                    axios.get(`${process.env.REACT_APP_LOCAL_URL}/companies`),
                    axios.get(`${process.env.REACT_APP_LOCAL_URL}/states`),
                ]);

                setEmployees(employeeResponse.data);
                setCompanies(companyResponse.data);
                setStates(stateResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleChange = async (e) => {
        const { name, value, type, files } = e.target;

        if (name === "projectManagerName") {
            // Find the selected employee based on the employee name
            const selectedEmployee = employees.find(employee => employee.employeeName === value);
            setFormData({
                ...formData,
                [name]: value, // Set the selected project manager name
                employee_id: selectedEmployee ? selectedEmployee.id : "", // Set the corresponding employee_id
            });
        } else if (name === "companyName") {
            const selectedCompany = companies.find(company => company.companyName === value);
            setFormData({
                ...formData,
                [name]: value,
                company_id: selectedCompany ? selectedCompany.id : "",
            });
        } else if (type === 'file') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: files[0],
            }));
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const validate = () => {
        let formErrors = {};
        if (!formData.projectName) formErrors.projectName = 'Project Name is required';
        if (!formData.companyName) formErrors.companyName = 'Company Name is required';
        if (!formData.projectType) formErrors.projectType = 'Project Type is required';
        if (!formData.principalEmployeeName) formErrors.principalEmployeeName = 'Principal Employee Name is required';
        if (!formData.principalEmployeeProjectAddress) formErrors.principalEmployeeProjectAddress = 'Address is required';
        if (!formData.employeerstate) formErrors.employeerstate = 'State is required';
        if (!formData.employeercity) formErrors.employeercity = 'City is required';
        if (!formData.employeerpincode) formErrors.employeerpincode = 'Pincode is required';
        if (!formData.projectAddress) formErrors.projectAddress = 'Address is required';
        if (!formData.projectstate) formErrors.projectstate = 'State is required';
        if (!formData.projectcity) formErrors.projectcity = 'City is required';
        if (!formData.projectpincode) formErrors.projectpincode = 'Pincode is required';
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!validate()) {
            return;
        }

        const formDataToSend = new FormData();
        Object.keys(formData).forEach(key => {
            formDataToSend.append(key, formData[key]);
        });

        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/projectData/${project.id}`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Data uploaded successfully:', response.data);

            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload();
            }, 1000); // 1 second delay
        } catch (error) {
            console.error('Error uploading data:', error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleClose = () => {
        onClose();
    };

    return (
        <div id="addProject" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} encType="multipart/form-data" autoComplete="off" noValidate>
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Project</h5>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                            <div>
                                <div className="form-group">
                                    <label>Project Name<span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="projectName"
                                        type="text"
                                        className={`form-control ${errors.projectName ? 'is-invalid' : ''}`}
                                        value={formData.projectName}
                                        onChange={handleChange}
                                        required
                                        placeholder="Project Name"
                                    />
                                    {errors.projectName && <small className="text-danger">{errors.projectName}</small>}
                                </div>
                                <div className="form-group">
                                    <label>Project Address<span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="projectAddress"
                                        type="text"
                                        className={`form-control ${errors.projectAddress ? 'is-invalid' : ''}`}
                                        value={formData.projectAddress}
                                        onChange={handleChange}
                                        required
                                        placeholder="Project Address"
                                    />
                                    {errors.projectAddress && <small className="text-danger">{errors.projectAddress}</small>}
                                </div>
                                <div className='form-row'>
                                    <div className="form-group col-md-4">
                                        <label>State<span style={{ color: "red" }}>*</span></label>
                                        <select
                                            name="projectstate"
                                            className={`form-control ${errors.projectstate ? 'is-invalid' : ''}`}
                                            value={formData.projectstate}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="" disabled hidden>Select State</option>
                                            {states.map(state => (
                                                <option key={state.id} value={state.statename}>{state.statename}</option>
                                            ))}
                                        </select>
                                        {errors.projectstate && <small className="text-danger">{errors.projectstate}</small>}
                                    </div>

                                    <div className="form-group col-md-4">
                                        <label>City<span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="projectcity"
                                            type="text"
                                            className={`form-control ${errors.projectcity ? 'is-invalid' : ''}`}
                                            value={formData.projectcity}
                                            onChange={handleChange}
                                            required
                                            placeholder="City"
                                        />
                                        {errors.projectcity && <small className="text-danger">{errors.projectcity}</small>}
                                    </div>

                                    <div className="form-group col-md-4">
                                        <label>Pincode<span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="projectpincode"
                                            type="text"
                                            className={`form-control ${errors.projectpincode ? 'is-invalid' : ''}`}
                                            value={formData.projectpincode}
                                            onChange={handleChange}
                                            required
                                            placeholder="Pincode"
                                        />
                                        {errors.projectpincode && <small className="text-danger">{errors.projectpincode}</small>}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Project Description</label>
                                    <textarea
                                        name="projectDescription"
                                        className="form-control"
                                        value={formData.projectDescription}
                                        onChange={handleChange}
                                        placeholder="Project Description"
                                    ></textarea>
                                </div>

                                <div className="form-group">
                                    <label>Company Name<span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="companyName"
                                        id="companyName"
                                        className={`form-control ${errors.companyName ? 'is-invalid' : ''}`}
                                        value={formData.companyName}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" disabled hidden>Select Company</option>
                                        {companies.map(company => (
                                            <option key={company.id} value={company.companyName}>{company.companyName}</option>
                                        ))}
                                    </select>
                                    {errors.companyName && <small className="text-danger">{errors.companyName}</small>}
                                </div>

                                <div className="form-group">
                                    <label>Project Type<span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="projectType"
                                        className={`form-control ${errors.projectType ? 'is-invalid' : ''}`}
                                        value={formData.projectType}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" disabled hidden>Select Project Type</option>
                                        <option value="Govt">Govt. Project</option>
                                        <option value="Private">Private Project</option>
                                    </select>
                                    {errors.projectType && <small className="text-danger">{errors.projectType}</small>}
                                </div>

                                <div className="form-group">
                                    <label>Principal Employeer Name<span style={{ color: "red" }}>*</span></label>

                                    <input
                                        name="principalEmployeeName"
                                        type="text"
                                        className={`form-control ${errors.principalEmployeeName ? 'is-invalid' : ''}`}
                                        value={formData.principalEmployeeName}
                                        onChange={handleChange}
                                        required
                                        placeholder="Principal Employeer Name"
                                    />
                                    {errors.principalEmployeeName && <small className="text-danger">{errors.principalEmployeeName}</small>}
                                </div>

                                <div className="form-group">
                                    <label>Principal Employeer Address<span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="principalEmployeeProjectAddress"
                                        type="text"
                                        className={`form-control ${errors.principalEmployeeProjectAddress ? 'is-invalid' : ''}`}
                                        value={formData.principalEmployeeProjectAddress}
                                        onChange={handleChange}
                                        required
                                        placeholder="Employeer Address"
                                    />

                                    {errors.principalEmployeeProjectAddress && <small className="text-danger">{errors.principalEmployeeProjectAddress}</small>}
                                </div>

                                <div className='form-row'>
                                    <div className="form-group col-md-4">
                                        <label>State<span style={{ color: "red" }}>*</span></label>
                                        <select
                                            name="employeerstate"
                                            className={`form-control ${errors.employeerstate ? 'is-invalid' : ''}`}
                                            value={formData.employeerstate}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="" disabled hidden>Select State</option>
                                            {states.map(state => (
                                                <option key={state.id} value={state.statename}>{state.statename}</option>
                                            ))}
                                        </select>
                                        {errors.employeerstate && <small className="text-danger">{errors.employeerstate}</small>}
                                    </div>

                                    <div className="form-group col-md-4">
                                        <label>City<span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="employeercity"
                                            type="text"
                                            className={`form-control ${errors.employeercity ? 'is-invalid' : ''}`}
                                            value={formData.employeercity}
                                            onChange={handleChange}
                                            required
                                            placeholder="City"
                                        />
                                        {errors.employeercity && <small className="text-danger">{errors.city}</small>}
                                    </div>

                                    <div className="form-group col-md-4">
                                        <label>Pincode<span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="employeerpincode"
                                            type="text"
                                            className={`form-control ${errors.employeerpincode ? 'is-invalid' : ''}`}
                                            value={formData.employeerpincode}
                                            onChange={handleChange}
                                            required
                                            placeholder="Pincode"
                                        />
                                        {errors.employeerpincode && <small className="text-danger">{errors.employeerpincode}</small>}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>Project Manager Name</label>
                                    <select
                                        name="projectManagerName"
                                        className="form-control"
                                        value={formData.projectManagerName}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" disabled hidden>Select Project Manager</option>
                                        {employees.map(employee => (
                                            <option key={employee.id} value={employee.employeeName}>
                                                {employee.employeeName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Upload Document</label>
                                    <input
                                        name="document"
                                        type="file"
                                        className="form-control-file"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? 'Loading...' : 'Submit'}
                            </button>
                            <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditProject;
