import React, { useState, useEffect } from "react";
import axios from "axios";
import OfficeDesc from "./OfficeDesc";
import EditOffice from "./EditOffice";
import DeleteConfirmationModal from "../DeleteConfirmationModal"; // Import the new component
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddOfficeForm from "./AddOfficeForm";
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner



function Officelist({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [offices, setOffices] = useState([]);
    const [selectedOffice, setSelectedOffice] = useState(null);
    const [showOfficeDetails, setShowOfficeDetails] = useState(false);
    const [isAddOfficeModalOpen, setIsAddOfficeModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editOffice, setEditOffice] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [deleteOffice, setDeleteOffice] = useState(null); // State to store data of office being deleted
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State to manage delete confirmation modal
    const [deleteReason, setDeleteReason] = useState(""); // State to store deletion reason

    useEffect(() => {
        fetchOffices();
    }, []);

    const fetchOffices = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/offices`);
            setOffices(response.data);
        } catch (error) {
            console.error("Error fetching offices:", error);
        }finally {
            setIsLoading(false);
        }
    };

    const handleAddOffice = () => {
        setIsAddOfficeModalOpen(true);
    };

    const handleCloseOfficeModal = () => {
        setIsAddOfficeModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleOfficeDetails = (office) => {
        setSelectedOffice(office);
        setShowOfficeDetails(true);
    };

    const handleEditOfficeClick = (office) => {
        setEditOffice(office);
        setSelectedOffice(office); // Update selectedOffice state
        setIsEditModalOpen(true);
    };

    const handleEditOfficeClose = () => {
        setSelectedOffice(null);
    };

    const handleBackToTable = () => {
        setSelectedOffice(null);
        setShowOfficeDetails(false);
    };

    const handleDeleteOffice = (office) => {
        setDeleteOffice(office);
        setIsDeleteModalOpen(true);
    };

    const handleUpdateOffice = async (updatedOffice) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/offices/${updatedOffice.id}`, updatedOffice);
            console.log("Office updated:", response.data);
            const updatedOffices = offices.map(office => (office.id === updatedOffice.id ? response.data : office));
            setOffices(updatedOffices);
        } catch (error) {
            console.error("Error updating office:", error);
        }
    };

    const handleDeleteConfirmation = async () => {
        try {
            // Perform deletion in the database
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/offices/${deleteOffice.id}`);

            // Save the deleted data to delete_details table
            const deletedOffice = { ...deleteOffice, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedOffice);

            // Remove the deleted site from the UI
            setOffices((prevOffices) =>
                prevOffices.filter((office) => office.id !== deleteOffice.id)
            );
            // Close the delete modal
            setIsDeleteModalOpen(false);
            toast.success("Successfully Deleted")
            console.log("Office deleted successfully");
        } catch (error) {
            console.error("Error deleting office:", error);
        }
    };

    const handleUpdateOffices = () => {
        toast.success("successfully uploaded");
        fetchOffices();
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = offices.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    return (
        <div className='d-flex w-100 h-100 bg-white '>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} /> {/* Pass username and handleLogout props */}
                <div className="container-fluid">
                    <ToastContainer />
                    {!showOfficeDetails && (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                            Office List
                                        </h6>
                                        <div className="d-flex gap-2">
                                            {/* <button onClick={(e)=>window.print()} className="btn btn-outline-success">
                                                <i className="fa fa-download"></i> 
                                            </button> */}
                                            <div onClick={handleAddOffice} className="btn btn-outline-primary">
                                                Add New Office
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card-body" style={{ height: "calc(100% - 40px)" }}                                   >
                                        <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {/* Correct usage of spinner */}
                                                    <ThreeDots
                                                        color="#00BFFF"
                                                        height={80}
                                                        width={80}
                                                    />
                                                </div>
                                            ) : (
                                                <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                    <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                        <tr>
                                                            <th>Office Name</th>
                                                            <th>Contact No.</th>
                                                            <th>Email</th>
                                                            <th>City</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <style>
                                                            {`.hyperlink:hover {color: blue;}`}
                                                        </style>
                                                        {currentItems.length === 0 ? (
                                                            <tr>
                                                                <td colSpan="12" className="text-center">Thier is No Office List .</td>
                                                            </tr>
                                                        ) : (currentItems.map((office, index) => (
                                                            <tr key={index}>
                                                                <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleOfficeDetails(office)}>{office.officeName}</td>
                                                                <td>{office.mobile1}</td>
                                                                <td>{office.email1}</td>
                                                                <td>{office.city}</td>
                                                                <td>
                                                                    <div className="btn-group">
                                                                        <button className="btn btn-sm btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                                        </button>
                                                                        <div className="dropdown-menu actionmenu" x-placement="bottom-start">
                                                                            <a className="dropdown-item" href="#" onClick={() => handleOfficeDetails(office)}><i className="fa fa-file"></i> Detail</a>
                                                                            <a className="dropdown-item" href="#" onClick={() => handleEditOfficeClick(office)}><i className="fas fa-edit"></i> Edit</a>
                                                                            <a className="dropdown-item" href="#" onClick={() => handleDeleteOffice(office)}><i className="fa fa-trash"></i> Delete</a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>

                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(offices.length / itemsPerPage) }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(offices.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showOfficeDetails && selectedOffice && (
                        <OfficeDesc
                            office={selectedOffice}
                            onClose={handleBackToTable}
                        />
                    )}
                    {selectedOffice && !showOfficeDetails && (
                        <EditOffice office={selectedOffice} onClose={handleEditOfficeClose} onUpdate={handleUpdateOffices} />
                    )}
                    {isAddOfficeModalOpen && <AddOfficeForm onClose={handleCloseOfficeModal} onUpdate={handleUpdateOffices} />}
                    <DeleteConfirmationModal
                        isOpen={isDeleteModalOpen}
                        itemName={deleteOffice ? deleteOffice.officeName : ""}
                        onDelete={handleDeleteConfirmation}
                        onClose={() => setIsDeleteModalOpen(false)}
                        deleteReason={deleteReason} // Corrected prop name
                        setDeleteReason={setDeleteReason} // Corrected prop name
                    />
                </div>
            </div>
        </div>
    );
}

export default Officelist;
