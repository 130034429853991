import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import Advancepayment from '../SalaryMaster/AdvancePayment';

const AddAdvanceRepaymentForm = ({ onClose, onUpdate, advanceRepayment, employeeId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        ...advanceRepayment,
        date: '',
        receivingMode: '',
        amount: '',
        bankName: '',
        accountNumber: '',
        ifscCode: '',
        branchName: '',
        username: localStorage.getItem('username'),
    });

    useEffect(() => {
        console.log("advanceRepayment", advanceRepayment)
        console.log("employeeId", employeeId)
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/api/repayment`, {
                ...formData,
                employee_id: employeeId,
                advancePaymentId: advanceRepayment[0].id,
            });
            console.log('Data uploaded successfully:', response.data);
            onClose();
            onUpdate();
        } catch (error) {
            console.error('Error uploading data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div id="addAdvanceRepayment" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                        <div className="modal-header">
                            <h5 className="modal-title">Add Advance Repayment</h5>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                            <div>
                                <div className="form-group">
                                    <label>Receiving Date<span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="date"
                                        type="date"
                                        className="form-control"
                                        value={formData.date}
                                        onChange={handleChange}
                                        required
                                    />

                                </div>

                                <div className="form-group">
                                    <label>Receiving Mode<span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="receivingMode"
                                        className="form-control"
                                        value={formData.receivingMode}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" disabled hidden>Select Mode</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Bank">Bank</option>
                                    </select>

                                </div>

                                {formData.receivingMode === 'Bank' && (
                                    <div>
                                        <div className="form-group">
                                            <label>Bank Name</label>
                                            <input
                                                name="bankName"
                                                type="text"
                                                className="form-control"
                                                placeholder="Bank Name"
                                                value={formData.bankName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Account Number</label>
                                            <input
                                                name="accountNumber"
                                                type="text"
                                                className="form-control"
                                                placeholder="Account Number"
                                                value={formData.accountNumber}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>IFSC Code</label>
                                            <input
                                                name="ifscCode"
                                                type="text"
                                                className="form-control"
                                                placeholder="IFSC Code"
                                                value={formData.ifscCode}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Branch Name</label>
                                            <input
                                                name="branchName"
                                                type="text"
                                                className="form-control"
                                                placeholder="Branch Name"
                                                value={formData.branchName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                )}

                                <div className="form-group">
                                    <label>Receiving Amount<span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="amount"
                                        type="number"
                                        className="form-control"
                                        placeholder="Receiving Amount"
                                        value={formData.amount}
                                        onChange={handleChange}
                                        required
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? 'Loading...' : 'Submit'}
                            </button>
                            <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddAdvanceRepaymentForm;

