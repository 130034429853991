import React, { useEffect, useState } from 'react';
import myLogo from '../../images/salary.jpg'; // Updated image reference for advances
import axios from 'axios';

const AdvanceReportPreview = ({ record, onClose }) => {
  // Correctly access filteredAdvances from record.advanceData
  const filteredAdvances = record.advanceData || [];
  const month = record.selectedMonth;
  const year = record.selectedYear;

  const [setting, setSetting] = useState({});

  useEffect(() => {
    const fetchSetting = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
        setSetting(response.data);
      } catch (error) {
        console.error('Error fetching settings', error);
      }
    };
    fetchSetting();
  }, []);

  // State to hold the grand totals
  const [grandTotals, setGrandTotals] = useState({
    totalEmployees: 0,
    totalAmount: 0,
  });

  // Effect to calculate totals whenever filteredAdvances changes
  useEffect(() => {
    calculateTotals();
  }, [filteredAdvances]);

  // Function to calculate the grand totals
  const calculateTotals = () => {
    const totals = filteredAdvances.reduce((acc, record) => {
      if (record.employee_id) {
        acc.totalEmployees += 1;
        acc.totalAmount += record.amount ? parseFloat(record.amount) : 0;
      }
      return acc;
    }, {
      totalEmployees: 0,
      totalAmount: 0,
    });

    setGrandTotals(totals);
  };

  // Function to get month name from number
  const getMonthName = (monthNumber) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return months[monthNumber - 1]; // monthNumber is 1-based, array is 0-based
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="container-fluid">
      <div className="row p-1">
        <div className="modal-content">
          <div className="modal-header m-0 p-2 d-flex align-items-center justify-content-between px-3">
            <div>
              <div style={{ height: "50px", width: "100%" }} className='logo p-1'>
                <img
                  src={setting.landingPageLogo
                    ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${setting.landingPageLogo}`
                    : myLogo}
                  style={{ height: "100%", objectFit: "cover" }}
                  alt="LOGO"
                />
              </div>
            </div>
            <h4 style={{ color: "#00509d" }} className='title-detail text-uppercase fw-bolder font-bold m-0'>Advance Payment Report</h4>
            <div>
              <h5 style={{ color: "#00509d" }} className='title-detail text-uppercase fw-bolder font-bold m-0'>{setting.title || 'Title'}</h5>
              <small style={{ color: "#00509d" }} className='title-detail text-uppercase fw-bolder font-bold m-0'>Date - {getMonthName(month)} {year}</small>
            </div>
          </div>

          <div className="card-body form-row">
            <div className='col-md-12'>
              <table className="table table-striped table-bordered rounded border">
                <thead>
                  <tr>
                    
                    <th>Employee</th>
                    <th>Employee Code</th>
                    <th>Advance Amt.</th>
                    <th>Payment Mode</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAdvances.length === 0 ? (
                    <tr>
                      <td colSpan="12" className="text-center">No Advance Details Found.</td>
                    </tr>
                  ) : (
                    filteredAdvances.map((advance, index) => (
                      <tr key={index}>
                       
                        <td>{advance.employeeName || 'N/A'}</td>
                        <td>{advance.employeeCode || 'N/A'}</td>
                        <td className='text-end'>&#x20B9;{advance.amount != null ? advance.amount.toFixed(2) : '0.00'}</td>
                        <td>{advance.paymentModeName || 'N/A'}</td>
                        <td>{advance.description || 'N/A'}</td>
                      </tr>
                    ))
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    
                    <th>Totals - {grandTotals.totalEmployees}</th>
                    <th className='text-end'>&#x20B9;{grandTotals.totalAmount.toFixed(2)}</th>
                    <th></th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div className="modal-footer p-1 d-flex align-items-center justify-content-between">
            <div>
              <small className='p-0 m-0'>Powered By - Salary HUB</small>
            </div>
            <div className='d-flex gap-2'>
              <button onClick={handlePrint} className="btn btn-success print-button"><i className="fa fa-download"></i> Print</button>
              <button onClick={onClose} className="btn btn-danger print-button">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvanceReportPreview;
