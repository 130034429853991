import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../components/sidebar/Sidebar";
import SearchBar from "../../components/sidebar/SearchBar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import myImage from '../../images/employee_profile.png';
import RequestLeave from "./RequestLeave";
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner


function AllLeaveList({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [leaveRequests, setLeaveRequests] = useState([]);
    const [filteredLeaveRequests, setFilteredLeaveRequests] = useState([]);
    const [isAddLeaveModal, setIsAddLeaveModal] = useState(false);
    const [isLeaveDetailsModal, setIsLeaveDetailsModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(25);
    const [selectedLeaveRequest, setSelectedLeaveRequest] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(''); // Initialize with an empty string for default value
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year

    useEffect(() => {
        fetchAllLeaveRequests();
    }, [selectedMonth, selectedYear]); // Fetch data whenever month/year changes

    const fetchAllLeaveRequests = async () => {
        setIsLoading(true);
        try {
            const employeesResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            const leaveResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/activeinactive/allleave`);

            const leaveData = leaveResponse.data;
            const employeesData = employeesResponse.data;

            // Filter leave data for the selected year
            const filteredYearData = leaveData.filter(item => {
                const itemDate = new Date(item.fromDate);
                return itemDate.getFullYear() === selectedYear;
            });

            // Further filter based on the selected month if it's not empty
            const filteredMonthData = selectedMonth === ''
                ? filteredYearData // If no month selected, use year filtered data
                : filteredYearData.filter(item => {
                    const itemDate = new Date(item.fromDate);
                    return itemDate.getMonth() === selectedMonth - 1; // Adjust for 0-based month index
                });

            // Combine leave data with employee data
            const combinedData = employeesData
                .filter(employee => filteredMonthData.some(leave => leave.employeeId === employee.id))
                .map(employee => {
                    const matchingLeave = filteredMonthData.find(leave => leave.employeeId === employee.id);
                    return {
                        ...employee,
                        activeInactiveDetails: matchingLeave || {}
                    };
                });

            setLeaveRequests(combinedData); // Update state with combined data
            paginate(1); // Initialize pagination on data fetch
        } catch (error) {
            console.error("Error fetching combined data:", error);
            toast.error("Error fetching data. Please try again later.");
        }
        finally {
            setIsLoading(false);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        const indexOfLastItem = pageNumber * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setFilteredLeaveRequests(leaveRequests.slice(indexOfFirstItem, indexOfLastItem));
    };

    const handleAddLeaveModal = () => setIsAddLeaveModal(true);
    const handleCloseAddLeaveModal = () => setIsAddLeaveModal(false);

    const handleLeaveDetailsModal = (leaveRequest) => {
        setSelectedLeaveRequest(leaveRequest);
        setIsLeaveDetailsModal(true);
    };

    const handleCloseLeaveDetailsModal = () => setIsLeaveDetailsModal(false);

    const handleUpdateLeaveList = () => fetchAllLeaveRequests();

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    <div className="card shadow mb-4">
                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between gap-3">
                            <div>
                                <h6 className="m-0 font-weight-bold text-primary">
                                    All Leave Requests
                                </h6>
                            </div>
                            <div className="d-flex gap-3">
                                <div className='d-flex align-items-center justify-content-center gap-1'>
                                    <label className='pt-2 text-black fw-bolder'>Filter:</label>
                                    <select className="form-control" value={selectedMonth}
                                        onChange={(e) => setSelectedMonth(e.target.value === '' ? '' : parseInt(e.target.value))}
                                    >
                                        <option value="">Select Month</option>
                                        {Array.from({ length: 12 }, (_, i) => (
                                            <option key={i} value={i + 1}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
                                        ))}
                                    </select>
                                    <select className="form-control" value={selectedYear}
                                        onChange={(e) => setSelectedYear(e.target.value === '' ? new Date().getFullYear() : parseInt(e.target.value))}
                                    >
                                        <option value="">Select Year</option>
                                        {Array.from({ length: 10 }, (_, i) => (
                                            <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                                        ))}
                                    </select>
                                </div>
                                <button onClick={handleAddLeaveModal} className="btn btn-primary">
                                    Add Leave Request
                                </button>
                            </div>

                        </div>
                        <div className="card-body">
                            <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                                {isLoading ? (
                                    <div className="d-flex justify-content-center align-items-center">
                                        {/* Correct usage of spinner */}
                                        <ThreeDots
                                            color="#00BFFF"
                                            height={80}
                                            width={80}
                                        />
                                    </div>
                                ) : (
                                    <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                        <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                            <tr>
                                                <th>Employee Photo</th>
                                                <th>Employee Name</th>
                                                <th>Status</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredLeaveRequests.length === 0 ? (
                                                <tr>
                                                    <td colSpan="12" className="text-center">Thier is No Company List.</td>
                                                </tr>
                                            ) : (
                                                filteredLeaveRequests.map((leaveRequest, index) => (
                                                    <React.Fragment key={index}>
                                                        <tr>
                                                            <td>
                                                                <img
                                                                    src={leaveRequest.passportSizePhoto
                                                                        ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${leaveRequest.passportSizePhoto}`
                                                                        : myImage}
                                                                    style={{ width: "90px" }} className="employee-image"
                                                                    
                                                                />
                                                            </td>
                                                            <td>{leaveRequest.employeeName}</td>
                                                            <td>{leaveRequest.activeInactiveDetails.status}</td>
                                                            <td>{formatDate(leaveRequest.activeInactiveDetails.fromDate)}</td>
                                                            <td>{formatDate(leaveRequest.activeInactiveDetails.toDate)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="5">
                                                                <span style={{ fontWeight: "700" }}>Description: </span>{leaveRequest.activeInactiveDetails.description}
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                )}
                            </div>

                            <ul className="pagination">
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => paginate(currentPage - 1)}>Previous</button>
                                </li>
                                {Array.from({ length: Math.ceil(leaveRequests.length / itemsPerPage) }, (_, i) => (
                                    <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                        <button className="page-link" onClick={() => paginate(i + 1)}>{i + 1}</button>
                                    </li>
                                ))}
                                <li className={`page-item ${currentPage === Math.ceil(leaveRequests.length / itemsPerPage) ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => paginate(currentPage + 1)}>Next</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {isAddLeaveModal && <RequestLeave onUpdate={handleUpdateLeaveList} onClose={handleCloseAddLeaveModal} />}
                </div>
            </div>
            {isLeaveDetailsModal && selectedLeaveRequest && (
                <div className="modal" id="leaveDetailsModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Leave Request Details</h4>
                                <button type="button" className="close" onClick={handleCloseLeaveDetailsModal}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <p><strong>Employee Name:</strong> {selectedLeaveRequest.employeeName}</p>
                                <p><strong>Status:</strong> {selectedLeaveRequest.activeInactiveDetails.status}</p>
                                <p><strong>From Date:</strong> {formatDate(selectedLeaveRequest.activeInactiveDetails.fromDate)}</p>
                                <p><strong>To Date:</strong> {formatDate(selectedLeaveRequest.activeInactiveDetails.toDate)}</p>
                                <p><strong>Description:</strong> {selectedLeaveRequest.activeInactiveDetails.description}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleCloseLeaveDetailsModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AllLeaveList;
