import React, { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';

const AddLoanRepayment = ({ onClose, onUpdate, loan }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [repaymentData, setRepaymentData] = useState({
        ...loan,
        employeeName: loan ? loan.employeeName : '',
        loanNumber: loan ? loan.loanNumber : '',
        loanAmount: loan ? loan.loanAmount.toFixed(2) : '', // Format loan amount to 2 decimal places
        repaymentAmount: '',
        repaymentDate: '',
        repaymentMode: '',
        repaymentdescription: ''
    });
    useEffect(() => {
        console.log("employloan",  loan)
    }, [])


    const handleChange = (e) => {
        const { name, value } = e.target;
        setRepaymentData({
            ...repaymentData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        console.log("aadf", repaymentData)

        try {
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/addLoanRepayment`, repaymentData);
            console.log('Repayment added successfully:', response.data);
            onClose();
            onUpdate();
        } catch (error) {
            console.error('Error adding repayment:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    // Format loan date to display in the desired format
    const formattedLoanDate = new Date(repaymentData.loanDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });

    return (
        <div id="addRepayment" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Loan Repayment</h4>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>Department Name:</label>
                                    <input type="text" className="form-control" value={repaymentData.departmentName} disabled />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Employee Name:</label>
                                    <input type="text" className="form-control" value={repaymentData.employeeName} disabled />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Loan Number:</label>
                                    <input type="text" className="form-control" value={repaymentData.loanNumber} disabled />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Loan Amount:</label>
                                    <input type="text" className="form-control" value={repaymentData.loanAmount} disabled />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Loan Date:</label>
                                    <input type="text" className="form-control" value={formattedLoanDate} disabled />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Repayment Amount:</label>
                                    <input type="number" className="form-control" name="repaymentAmount" placeholder="Repayment Amount" value={repaymentData.repaymentAmount} onChange={handleChange} required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Repayment Date:</label>
                                    <input type="date" className="form-control" name="repaymentDate" placeholder="Repayment Date" value={repaymentData.repaymentDate} onChange={handleChange} required />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Repayment Mode:</label>
                                    <input type="text" className="form-control" name="repaymentMode" placeholder="Repayment Mode" value={repaymentData.repaymentMode} onChange={handleChange} required />
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Description:</label>
                                    <textarea className="form-control" name="repaymentdescription" placeholder="Description" value={repaymentData.repaymentdescription} onChange={handleChange} />
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? 'Loading...' : 'Submit'}
                            </button>
                            <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddLoanRepayment;


