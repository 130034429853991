import axios from 'axios';
import React from 'react';
import { useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner
import myImage from '../../images/employee_profile.png';

function AttendanceDetailsModal({ record, onClose }) {
    console.log("record", record);
    const [setting, setSetting] = useState({});
    const [loading, setLoading] = useState(false); // State for loading status
    const [employeeDetails, setEmployeeDetails] = useState({});


    useEffect(() => {
        const fetchSetting = async () => {
            setLoading(true); // Set loading to true before fetching data
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
                setSetting(response.data);
            } catch (error) {
                console.error('Error fetching Dashboard Logo', error);
            } finally {
                setLoading(false); // Set loading to false after fetching is complete
            }
        };
        fetchSetting();
    }, []);

    useEffect(() => {
        const fetchEmployeeDetails = async () => {
            setLoading(true); // Set loading to true before fetching data
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${record.employeeId}`);
                const employeeData = response.data.length > 0 ? response.data[0] : null;
                setEmployeeDetails(employeeData);
            } catch (error) {
                console.error('Error fetching Dashboard Logo', error);
            } finally {
                setLoading(false); // Set loading to false after fetching is complete
            }
        };
        fetchEmployeeDetails();
    }, []);

    const handlePrint = () => {
        window.print();
    };
    useEffect(() => {
        console.log(employeeDetails)
    }, [])


    // Helper function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    };

    // Sort the attendance data by date in ascending order
    const sortedAttendance = [...record.attendanceData.filteredAttendance].sort((a, b) => new Date(a.date) - new Date(b.date));

    return (
        <div className="container-fluid" >
            <div className="row p-1">
                <div className="modal-content">
                    <div className="modal-header m-0 p-2">
                        <div>
                            <div style={{ height: "50px", width: "100%" }} className='logo p-1'>
                                {/* <img className='w-100 h-100' src={`${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${setting.landingPageLogo}`} alt="LOGO" /> */}
                                <img src={setting.landingPageLogo
                                    ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${setting.landingPageLogo}`
                                    : myImage}
                                    style={{ width: "90px" }} className='w-100 h-100'
                                />
                            </div>
                            <h5 style={{ color: "#00509d" }} className='title-detail text-uppercase fw-bolder font-bold m-0'>{setting.title}</h5>
                        </div>
                        <div>
                            <h2 style={{ color: "#00509d" }} className="title-detail fw-bolder font-bold m-0">
                                {employeeDetails.employeeName}
                            </h2>

                            <h5 className="title-detail m-0">
                                Employee Code: {employeeDetails.employeeCode}
                            </h5>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className='col-md-12 '>
                                <div className="card-footer rounded border col-md-12 ">
                                    <h5 className="text-primary text-center fw-semibold">Attendance Summary Days</h5>
                                    <hr />
                                    <div className="d-flex gap-3 align-items-center justify-content-center ">
                                        <div className="bg-success text-white p-1 text-center border rounded">Present: {record.totalPresent}</div>
                                        <div className="bg-success text-white p-1 text-center border rounded">Weekly Off: {record.totalWeeklyOff}</div>
                                        <div className="bg-success text-white p-1 text-center border rounded">Paid Leave: {record.totalPaidLeave}</div>
                                        <div className="bg-success text-white p-1 text-center border rounded">Overtime: {record.totalOvertime}</div>
                                        <div className="bg-danger text-white p-1 text-center border rounded">Absent: {record.totalAbsent}</div>
                                        <div className="bg-danger text-white p-1 text-center border rounded">Half Day: {record.totalHalfDay}</div>
                                        <div className="bg-danger text-white p-1 text-center border rounded">Unpaid Leave: {record.totalUnpaidLeave}</div>
                                        <div className="bg-primary text-white p-1 text-center border rounded">Total Days: {record.totalAttencance}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 '>
                                {loading ? (
                                    <div className="d-flex justify-content-center align-items-center">
                                        {/* Correct usage of spinner */}
                                        <ThreeDots
                                            color="#00BFFF"
                                            height={80}
                                            width={80}
                                        />
                                    </div>
                                ) : (
                                    <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                                        <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                            <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                <tr>
                                                    <th>Serial No</th>
                                                    <th>Name</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sortedAttendance.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="3" className="text-center">There is No Attendnce Details.</td>
                                                    </tr>
                                                ) : (
                                                    sortedAttendance.map((attendance, index) => (
                                                        <tr key={`${attendance.id}-${attendance.date}`}>
                                                            <td>{index + 1}</td>
                                                            <td>{attendance.employeeName}</td>
                                                            <td>{formatDate(attendance.date)}</td>
                                                            <td>{attendance.status}</td>
                                                        </tr>
                                                    )))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button onClick={handlePrint} className="btn btn-success print-button"><i className="fa fa-download"></i> Print</button>
                            <button onClick={onClose} className="btn btn-danger print-button"><i className="fa fa-arrow-left"></i> Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AttendanceDetailsModal;
