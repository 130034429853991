import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../style1.css';
import DateChangeApproved from "./DateChangeApproved";
import myImage from '../../images/employee_profile.png';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner


function ApprovedStatus({ onClose, showRejectStatusModal }) {
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [ApprovedStatus, setApprovedStatus] = useState([]);
    const [dateChange, setDateChange] = useState(null);
    const [isAddDateChangeModal, setIsAddDateChangeModal] = useState(false);

    useEffect(() => {
        fetchCombinedData();
    }, []);

    const fetchCombinedData = async () => {
        setIsLoading(true);
        try {
            const employeesResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            const ApprovedStatusResponse = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/approved`);

            const ApprovedStatusData = ApprovedStatusResponse.data;

            const combinedData = employeesResponse.data
                .filter(employee => ApprovedStatusData.some(ai => ai.employeeId === employee.id))
                .map(employee => {
                    const matchingApprovedStatus = ApprovedStatusData.find(ai => ai.employeeId === employee.id);
                    return {
                        ...employee,
                        activeInactiveDetails: matchingApprovedStatus || {}
                    };
                });

            setApprovedStatus(combinedData);
        } catch (error) {
            console.error("Error fetching combined data:", error);
            toast.error("Error fetching data. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDateChange = (ApprovedStatus, activeInactiveDetails) => {
        const dateChange = { ...ApprovedStatus, activeInactiveDetails };
        setDateChange(dateChange);
        setIsAddDateChangeModal(true);
    };

    const handleCloseDateChange = () => {
        setIsAddDateChangeModal(false);
    };

    const handleUpdateApproveList = () => {
        fetchCombinedData();
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = ApprovedStatus.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className="container-fluid p-0">
            <ToastContainer />
            <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center gap-3">
                    <h6 className="m-0 font-weight-bold text-primary">
                        Approved Status
                    </h6>
                    <h6 style={{ cursor: "pointer" }} className="m-0 font-weight-bold text-info" onClick={() => onClose()}>
                        Requests Leave
                    </h6>
                    <h6 style={{ cursor: "pointer" }} className="m-0 font-weight-bold text-info" onClick={() => showRejectStatusModal()}>
                        Reject Status
                    </h6>
                </div>
                <div className="card-body">
                    <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                        {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center">
                                {/* Correct usage of spinner */}
                                <ThreeDots
                                    color="#00BFFF"
                                    height={80}
                                    width={80}
                                />
                            </div>
                        ) : (
                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                    <tr>
                                        <th>Employee Photo</th>
                                        <th>Employee Name</th>
                                        <th>Status</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {currentItems.length === 0 ? (
                                        <tr>
                                            <td colSpan="12" className="text-center">Thier is No Approved List.</td>
                                        </tr>
                                    ) : (
                                        currentItems.map((ApprovedStatus, index) => (
                                            <tr key={index}>
                                                <td>

                                                    <img
                                                        src={ApprovedStatus.passportSizePhoto
                                                            ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${ApprovedStatus.passportSizePhoto}`
                                                            : myImage}
                                                        style={{ width: "90px" }} className="employee-image"
                                                        
                                                    />
                                                </td>
                                                <td>{ApprovedStatus.employeeName}</td>
                                                <td>{ApprovedStatus.activeInactiveDetails.status}</td>
                                                <td>{formatDate(ApprovedStatus.activeInactiveDetails.fromDate)}</td>
                                                <td>{formatDate(ApprovedStatus.activeInactiveDetails.toDate)}</td>
                                                <td>{ApprovedStatus.activeInactiveDetails.description}</td>
                                                <td>
                                                    <button onClick={() => handleDateChange(ApprovedStatus, ApprovedStatus.activeInactiveDetails)} className="btn btn-outline-primary">
                                                        Action
                                                    </button>
                                                </td>
                                            </tr>
                                        )))}
                                </tbody>
                            </table>
                        )}
                    </div>

                    <ul className="pagination">
                        {Array.from({ length: Math.ceil(ApprovedStatus.length / itemsPerPage) }, (_, i) => (
                            <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                <button className="page-link" onClick={() => handlePageChange(i + 1)}>
                                    {i + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
                {isAddDateChangeModal && <DateChangeApproved dateChange={dateChange} onUpdate={handleUpdateApproveList} onClose={handleCloseDateChange} />}
            </div>
        </div>
    );
}

export default ApprovedStatus;
