import React, { useState, useEffect } from 'react';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

const EditOffice = ({ office, onClose, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    officeName: '',
    personName1: '',
    personName2: '',
    address: '',
    country: 'IN',
    city: '',
    state: '',
    pincode: '',
    email1: '',
    email2: '',
    mobile1: '',
    mobile2: '',
    mobile3: '',
    picture: null,
    remarks: '',
    employeeName: '',
    employee_id: '',
    username: localStorage.getItem('username'),
  });

  const [errors, setErrors] = useState({});
  const [employees, setEmployees] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [states, setStates] = useState([]);

  // Populate form data when office prop is available
  useEffect(() => {
    if (office) {
      setFormData({ ...office, picture: null });  // Keeping picture as null initially
    }
  }, [office]);

  // Fetch employees and states data
  useEffect(() => {
    const fetchEmployeesAndStates = async () => {
      try {
        const [employeesResponse, statesResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`),
          axios.get(`${process.env.REACT_APP_LOCAL_URL}/states`)
        ]);
        setEmployees(employeesResponse.data);
        setStates(statesResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchEmployeesAndStates();
  }, []);

  // Handle image change with compression
  const handleImageChange = async (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
      const maxSize = 200 * 1024; // 200KB max size
      const allowedTypes = ['image/jpeg', 'image/png'];

      if (!allowedTypes.includes(file.type)) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Only JPG and PNG files are allowed.',
        }));
        return;
      }

      if (file.size > maxSize) {
        const options = {
          maxSizeMB: 0.2,
          maxWidthOrHeight: 500,
          useWebWorker: true,
        };
        try {
          const compressedFile = await imageCompression(file, options);
          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: compressedFile,
          }));
        } catch (error) {
          console.error('Error compressing image:', error);
        }
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: file,
        }));
      }

      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: '',
      }));
    }
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Form validation
  const validate = () => {
    let formErrors = {};
    if (!formData.officeName) formErrors.officeName = 'Office Name is required';
    if (!formData.address) formErrors.address = 'Address is required';
    if (!formData.city) formErrors.city = 'City is required';
    if (!formData.state) formErrors.state = 'State is required';
    if (!formData.email1) formErrors.email1 = 'Primary Email is required';
    if (!formData.mobile1) formErrors.mobile1 = 'Primary Mobile is required';

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!validate()) {
      setIsLoading(false);
      return;
    }

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });

    try {
      const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/officeData/${office.id}`, formDataToSend);
      console.log('Data updated successfully:', response.data);
      onUpdate();
      setTimeout(() => {
        onClose();
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div id="addOffice" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <form onSubmit={handleSubmit} encType="multipart/form-data" autoComplete="off" noValidate>
            <div className="modal-header">
              <h5 className="modal-title">Edit Office</h5>
              <button type="button" className="close" onClick={onClose}>&times;</button>
            </div>
            <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
              <div>
                <div className="form-group">
                  <label>Office Name<span style={{ color: "red" }}>*</span></label>
                  <input
                    name="officeName"
                    type="text"
                    className={`form-control ${errors.officeName ? 'is-invalid' : ''}`}
                    value={formData.officeName}
                    onChange={handleChange}
                    required
                    placeholder="Office Name"
                  />
                  {errors.officeName && <small className="text-danger">{errors.officeName}</small>}
                </div>

                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label>Mobile 1<span style={{ color: "red" }}>*</span></label>
                    <input
                      name="mobile1"
                      type="text"
                      className={`form-control ${errors.mobile1 ? 'is-invalid' : ''}`}
                      value={formData.mobile1}
                      onChange={handleChange}
                      required
                      placeholder="Mobile 1"
                    />
                    {errors.mobile1 && <small className="text-danger">{errors.mobile1}</small>}
                  </div>
                  <div className="form-group col-md-4">
                    <label>Mobile 2</label>
                    <input
                      name="mobile2"
                      type="text"
                      className="form-control"
                      value={formData.mobile2}
                      onChange={handleChange}
                      placeholder="Mobile 2"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Mobile 3</label>
                    <input
                      name="mobile3"
                      type="text"
                      className="form-control"
                      value={formData.mobile3}
                      onChange={handleChange}
                      placeholder="Mobile 3"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label>Email 1<span style={{ color: "red" }}>*</span></label>
                    <input
                      name="email1"
                      type="email"
                      className={`form-control ${errors.email1 ? 'is-invalid' : ''}`}
                      value={formData.email1}
                      onChange={handleChange}
                      required
                      placeholder="Email 1"
                    />
                    {errors.email1 && <small className="text-danger">{errors.email1}</small>}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Email 2</label>
                    <input
                      name="email2"
                      type="email"
                      className="form-control"
                      value={formData.email2}
                      onChange={handleChange}
                      placeholder="Email 2"
                    />
                  </div>
                </div>

                <label htmlFor="">Address Details: -</label>
                <div className="form-group">
                  <label>Address<span style={{ color: "red" }}>*</span></label>
                  <input
                    name="address"
                    type="text"
                    className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                    value={formData.address}
                    onChange={handleChange}
                    required
                    placeholder="Address"
                  />
                  {errors.address && <small className="text-danger">{errors.address}</small>}
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label>State<span style={{ color: "red" }}>*</span></label>
                    <select
                      name="state"
                      id="state"
                      className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                      value={formData.state}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled hidden>Select State</option>
                      {states.map(state => (
                        <option key={state.id} value={`${state.statename}`}>{state.statename}</option>
                      ))}
                    </select>
                    {errors.state && <small className="text-danger">{errors.state}</small>}
                  </div>
                  <div className="form-group col-md-6">
                    <label>City<span style={{ color: "red" }}>*</span></label>
                    <input
                      name="city"
                      type="text"
                      className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                      value={formData.city}
                      onChange={handleChange}
                      placeholder="City"
                      required
                    />
                    {errors.city && <small className="text-danger">{errors.city}</small>}
                  </div>
                  <div className="form-group col-md-6">
                    <label>Pincode</label>
                    <input
                      name="pincode"
                      type="text"
                      className="form-control"
                      value={formData.pincode}
                      onChange={handleChange}
                      placeholder="Pincode"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label>Picture</label>
                  <input
                    name="picture"
                    type="file"
                    className="form-control"
                    accept=".jpg, .jpeg, .png"
                    onChange={handleImageChange}
                  />
                  {validationErrors.picture && <small className="text-danger">{validationErrors.picture}</small>}
                </div>
                <div className="form-group">
                  <label>Remarks</label>
                  <textarea
                    name="remarks"
                    className="form-control"
                    value={formData.remarks}
                    onChange={handleChange}
                    placeholder="Remarks"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" onClick={onClose}>
                Close
              </button>
              <button type="submit" className="btn btn-primary" disabled={isLoading}>
                {isLoading ? 'Updating...' : 'Save changes'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditOffice;
