import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Dashboard.css';
import SidebarEmployee from '../../components/sidebar/SidebarEmployee';
import SearchBarEmployee from '../../components/sidebar/SearchBarEmployee';
import SalaryPreview from '../SalaryMaster/SalaryPreview';
import PaymentHistory from '../SalaryMaster/PaymentHistory';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner


function E_SalaryLedger({ handleLogout, username }) {
    const [employee, setEmployee] = useState(null);
    const [payroll, setPayroll] = useState([]);
    const [filteredPayroll, setFilteredPayroll] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [showSidebar, setShowSidebar] = useState(true);
    const [showSearchBar, setShowSearchBar] = useState(true);
    const [showSalaryPreview, setShowSalaryPreview] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState({});
    const [paymentFormHistory, setPaymentFormHistory] = useState(null);
    const [isPaymentHistory, setIsPaymentHistory] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const employeeId = localStorage.getItem('employeeId');

    useEffect(() => {
        if (employeeId) {
            fetchEmployeeDetails(employeeId);
            fetchPayrollByEmployee(employeeId);
        }
    }, [employeeId]);

    useEffect(() => {
        filterPayroll();
    }, [selectedYear, payroll]);

    useEffect(() => {
        fetchPaymentDetails();
    }, [payroll]);

    const fetchEmployeeDetails = async (employeeId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);
            setEmployee(response.data);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchPayrollByEmployee = async (employeeId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/payroll/${employeeId}`);
            setPayroll(response.data);
        } catch (error) {
            console.error('Error fetching payroll by employee:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchPaymentDetails = async () => {
        setIsLoading(true);
        try {
            const details = {};
            for (const record of payroll) {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/paymentform/${record.id}`);
                details[record.id] = response.data.reduce((sum, payment) => sum + payment.amountPaid, 0);
            }
            setPaymentDetails(details);
        } catch (error) {
            console.error('Error fetching payment details:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const filterPayroll = () => {
        const filteredRecords = payroll.filter(record =>
            record.year === selectedYear
        );
        setFilteredPayroll(filteredRecords);
    };

    const handleOpenSalaryPreview = (record) => {
        setSelectedRecord(record);
        setShowSidebar(false);
        setShowSearchBar(false);
        setShowSalaryPreview(true);
        setTimeout(() => {
            window.print();
        }, 0);
    };


    const handleClosePreview = () => {
        setShowSalaryPreview(false);
        setShowSidebar(true);
        setShowSearchBar(true);
        setSelectedRecord(null);
    };

    const handlePaymentHistory = (record) => {
        setPaymentFormHistory(record);
        setIsPaymentHistory(true);
    };

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    return (
        <div className="">
            <div className="d-flex w-100 h-100 bg-white">
                {showSearchBar && <SidebarEmployee username={username} handleLogout={handleLogout} />}
                <div className="w-100">
                    {showSearchBar && (
                        <SearchBarEmployee
                            className="searchbarr"
                            username={username}
                            handleLogout={handleLogout}
                        />
                    )}
                    <div className="container-fluid">
                        <ToastContainer />
                        {showSalaryPreview ? (
                            <SalaryPreview
                                record={selectedRecord}
                                onClose={handleClosePreview}
                            />
                        ) : (
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card shadow mb-4">
                                        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                            <h6 className="m-0 font-weight-bold text-primary">Salary List</h6>
                                            <div className="d-flex align-items-center justify-content-center gap-1">
                                                <label className="pt-2 text-black fw-bolder">Filter:</label>
                                                <select
                                                    className="form-control"
                                                    value={selectedYear}
                                                    onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                                                >
                                                    <option value="">Select Year</option>
                                                    {Array.from({ length: 10 }, (_, i) => (
                                                        <option key={i} value={new Date().getFullYear() - i}>
                                                            {new Date().getFullYear() - i}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }} className="card-body">
                                            <div className="row">
                                                {/* <!-- Salary Ledger Card --> */}
                                                {isLoading ? (
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        {/* Correct usage of spinner */}
                                                        <ThreeDots
                                                            color="#00BFFF"
                                                            height={80}
                                                            width={80}
                                                        />
                                                    </div>
                                                ) : (filteredPayroll.length > 0 ? (
                                                    filteredPayroll.map((record) => {
                                                        const amountPaid = paymentDetails[record.id] || 0;
                                                        const amountDue = record.netSalaryPayableMonth - amountPaid;
                                                        return (
                                                            <div className="col-xl-3 col-md-6 mb-4" key={record.id}>
                                                                <div className="card shadow h-100 py-1">
                                                                    <div className="card-body">
                                                                        <div className="row no-gutters align-items-center">
                                                                            <div className="col-md-12">
                                                                                <p className="m-0 fw-bolder">
                                                                                    Name: {record.employeeName} <br />
                                                                                    <small>{record.departmentName}</small>
                                                                                </p>

                                                                                <div class="row">
                                                                                    <div class="col-sm">
                                                                                        Month & Year
                                                                                    </div>
                                                                                    <div class="col-sm">
                                                                                        <p className="m-0">:    {monthNames[record.month - 1]} - {record.year}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm">
                                                                                        In Hand Salary
                                                                                    </div>
                                                                                    <div class="col-sm">
                                                                                        <p className="m-0">: &#x20B9;{record.totalInHandSalaryMonth.toFixed(2)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm">
                                                                                        Net Salary
                                                                                    </div>
                                                                                    <div class="col-sm">
                                                                                        <p className="m-0">: &#x20B9;{record.netSalaryPayableMonth.toFixed(2)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm">
                                                                                        Amount Paid
                                                                                    </div>
                                                                                    <div class="col-sm">
                                                                                        <p className="m-0">: &#x20B9;{amountPaid.toFixed(2)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-sm">
                                                                                        Amount Due
                                                                                    </div>
                                                                                    <div class="col-sm">
                                                                                        <p className="m-0">: &#x20B9;{amountDue.toFixed(2)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <hr />
                                                                                <div className="d-flex align-items-center justify-content-center">
                                                                                    <button
                                                                                        className="m-1 btn btn-success btn-sm"
                                                                                        onClick={() => handleOpenSalaryPreview(record)}
                                                                                    >
                                                                                        <i className="fa fa-download" aria-hidden="true"></i> Print
                                                                                    </button>
                                                                                    <button
                                                                                        className="m-1 btn btn-info btn-sm"
                                                                                        onClick={() => handlePaymentHistory(record)}
                                                                                    >
                                                                                        <i className="fa fa-eye" aria-hidden="true"></i> View
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <div className="col-12" style={{ textAlign: 'center' }}>
                                                        <h5>No salary history found for this employee.</h5>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isPaymentHistory && (
                            <PaymentHistory
                                record={paymentFormHistory}
                                onClose={() => setIsPaymentHistory(false)}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>


    );
}

export default E_SalaryLedger;










