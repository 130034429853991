import React, { useState, useEffect } from 'react';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { ThreeDots } from 'react-loader-spinner';

const AddOfficeForm = ({ onClose, onUpdate }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        officeName: '',
        personName1: '',
        personName2: '',
        address: '',
        country: 'IN',
        city: '',
        state: '',
        pincode: '',
        email1: '',
        email2: '',
        mobile1: '',
        mobile2: '',
        mobile3: '',
        picture: null,
        remarks: '',
        employeeName: '',
        employee_id: '',
        username: localStorage.getItem('username'),
    });

    const [errors, setErrors] = useState({});
    const [employees, setEmployees] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [states, setStates] = useState([]);

    useEffect(() => {
        // Fetch employees and states from the API
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
                setEmployees(response.data);
            } catch (error) {
                console.error('Error fetching employees:', error);
            }
        };

        const fetchStates = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/states`);
                setStates(response.data);
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };

        fetchEmployees();
        fetchStates();
    }, []);

    // Handle image change with compression
    const handleImageChange = async (e) => {
        const { name, files } = e.target;
        const file = files[0];

        if (file) {
            const maxSize = 200 * 1024; // 200KB max size
            const allowedTypes = ['image/jpeg', 'image/png'];

            // Validate file type
            if (!allowedTypes.includes(file.type)) {
                setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: 'Only JPG and PNG files are allowed.',
                }));
                return;
            }

            // Validate file size
            if (file.size > maxSize) {
                // Compress image if it's larger than max size
                const options = {
                    maxSizeMB: 0.2, // Max size in MB
                    maxWidthOrHeight: 500, // Max width/height for image
                    useWebWorker: true,
                };
                try {
                    const compressedFile = await imageCompression(file, options);
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        [name]: compressedFile,
                    }));
                } catch (error) {
                    console.error('Error compressing image:', error);
                }
                return;
            }

            // No compression needed
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: file,
            }));

            // Clear validation errors
            setValidationErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }
    };

    // Handle form field changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Form validation
    const validate = () => {
        let formErrors = {};
        if (!formData.officeName) formErrors.officeName = 'Office Name is required';
        if (!formData.address) formErrors.address = 'Address is required';
        if (!formData.city) formErrors.city = 'City is required';
        if (!formData.state) formErrors.state = 'State is required';
        if (!formData.email1) formErrors.email1 = 'Primary Email is required';
        if (!formData.mobile1) formErrors.mobile1 = 'Primary Mobile is required';

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!validate()) {
            return;
        }

        // Create FormData object to handle file and data submission
        const formDataToSend = new FormData();
        Object.keys(formData).forEach((key) => {
            formDataToSend.append(key, formData[key]);
        });

        try {
            // Submit form data to the server
            const response = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/officeData`, formDataToSend);
            console.log('Data uploaded successfully:', response.data);

            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload();
            }, 1000); // 1 second delay
        } catch (error) {
            console.error('Error uploading data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };


    return (
        <div id="addOffice" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} encType="multipart/form-data" autoComplete="off" noValidate>
                        <div className="modal-header">
                            <h5 className="modal-title">Add Office</h5>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                            <div>
                                <div className="form-group">
                                    <label>Office Name<span style={{ color: "red" }}>*</span></label>
                                    <input
                                        name="officeName" type="text"
                                        className={`form-control ${errors.officeName ? 'is-invalid' : ''}`}
                                        required
                                        placeholder="Office Name"
                                        onChange={handleChange} />
                                    {errors.officeName && <small className="text-danger">{errors.officeName}</small>}
                                </div>

                                {/* <div className="form-group">
                                    <label htmlFor="employeeName">Person Name <span style={{ color: "red" }}>*</span></label>
                                    <select
                                        name="employeeName"
                                        id="employeeName"
                                        className={`form-control ${errors.employeeName ? 'is-invalid' : ''}`}
                                        value={formData.employeeName}
                                        onChange={handleChange}
                                        required
                                    >

                                        <option value="" disabled hidden>Select Employee</option>
                                        {employees.map((employee) => (
                                            <option key={employee.id} value={employee.employeeName}>
                                                {employee.employeeName}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.employeeName && <small className="text-danger">{errors.employeeName}</small>}
                                </div> */}
                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label>Mobile 1<span style={{ color: "red" }}>*</span></label>
                                        <input name="mobile1" type="text"
                                            className={`form-control ${errors.mobile1 ? 'is-invalid' : ''}`}
                                            required placeholder="Mobile 1" onChange={handleChange} />
                                        {errors.mobile1 && <small className="text-danger">{errors.mobile1}</small>}
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Mobile 2</label>
                                        <input name="mobile2" type="text"
                                            className="form-control"
                                            placeholder="Mobile 2" onChange={handleChange} />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Mobile 3</label>
                                        <input name="mobile3" type="text" className="form-control" placeholder="Mobile 3" onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Email 1<span style={{ color: "red" }}>*</span></label>
                                        <input name="email1" type="email"
                                            className={`form-control ${errors.email1 ? 'is-invalid' : ''}`}
                                            required placeholder="Email 1" onChange={handleChange} />
                                        {errors.email1 && <small className="text-danger">{errors.email1}</small>}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Email 2</label>
                                        <input name="email2" type="email" className="form-control" placeholder="Email 2" onChange={handleChange} />
                                    </div>
                                </div>
                                <label htmlFor="">Address Details : -</label>
                                <div className="form-group">
                                    <label>Address<span style={{ color: "red" }}>*</span></label>
                                    <input name="address" type="text"
                                        className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                                        required placeholder="Address" onChange={handleChange} />
                                    {errors.address && <small className="text-danger">{errors.address}</small>}
                                </div>
                                <div className='form-row'>
                                    <div className="form-group col-md-4">
                                        <label>State<span style={{ color: "red" }}>*</span></label>
                                        <select
                                            name="state"
                                            id="state"
                                            className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                                            value={formData.state}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="" disabled hidden>Select State</option>
                                            {states.map(state => (
                                                <option key={state.id} value={`${state.statename}`}>{state.statename}</option>
                                            ))}
                                        </select>
                                        {errors.state && <small className="text-danger">{errors.state}</small>}
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>City<span style={{ color: "red" }}>*</span></label>
                                        <input
                                            name="city"
                                            type="text"
                                            className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                            value={formData.city}
                                            onChange={handleChange}
                                            required
                                            placeholder="City"
                                        />
                                        {errors.city && <small className="text-danger">{errors.city}</small>}
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Pincode</label>
                                        <input name="pincode" type="text" className="form-control" required placeholder="Pincode" onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Remarks </label>
                                    <textarea name="remarks" className="form-control" placeholder="Remarks" onChange={handleChange}></textarea>
                                </div>
                                <div className="form-group">
                                    <label>Upload Picture</label>
                                    <input
                                        name="picture"
                                        type="file"
                                        className="form-control"
                                        onChange={handleImageChange}
                                        required
                                    />
                                    {validationErrors.picture && (
                                        <div className="invalid-feedback">{validationErrors.picture}</div>
                                    )}
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? 'Loading...' : 'Submit'}
                            </button>
                            <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddOfficeForm;
