import React, { useEffect, useState } from 'react';
import myLogo from '../../images/salary.jpg';
import axios from 'axios';

const SalaryReportPreview = ({ record, onClose }) => {
    // Destructure SalaryEpfEsicData from the record prop
    const { filteredsalaryepfesic = [] } = record.SalaryEpfEsicData || {};
    const month = record.selectedMonth;
    const year = record.selectedYear;
    console.log(record)
    console.log(month, year)

    const [setting, setSetting] = useState({});
    useEffect(() => {
        const fetchSetting = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
                setSetting(response.data);
            } catch (error) {
                console.error('Error fetching Dashboard Logo', error);
            }
        };
        fetchSetting();
    }, []);

    // State to hold the grand totals
    const [grandTotals, setGrandTotals] = useState({
        totalEmployees: 0,
        totalSalaryWithContribution: 0,
        totalEpfEmployerMonth: 0,
        totalEsicEmployerMonth: 0,
        totalEpfEmployeeMonth: 0,
        totalEsicEmployeeMonth: 0,
        totalTdsDeductionMonth: 0,
        totalNetSalaryPayableMonth: 0
    });

    // Effect to calculate totals whenever filteredsalaryepfesic changes
    useEffect(() => {
        calculateTotals();
    }, [filteredsalaryepfesic]);

    // Function to calculate the grand totals
    const calculateTotals = () => {
        const totals = filteredsalaryepfesic.reduce((acc, record) => {
            acc.totalEmployees += 1;
            acc.totalSalaryWithContribution += record.salaryWithContribution || 0;
            acc.totalEpfEmployerMonth += record.epfEmployerMonth || 0;
            acc.totalEsicEmployerMonth += record.esicEmployerMonth || 0;
            acc.totalEpfEmployeeMonth += record.epfEmployeeMonth || 0;
            acc.totalEsicEmployeeMonth += record.esicEmployeeMonth || 0;
            acc.totalTdsDeductionMonth += record.totalTdsDeductionMonth || 0;
            acc.totalNetSalaryPayableMonth += record.netSalaryPayableMonth || 0;
            return acc;
        }, {
            totalEmployees: 0,
            totalSalaryWithContribution: 0,
            totalEpfEmployerMonth: 0,
            totalEsicEmployerMonth: 0,
            totalEpfEmployeeMonth: 0,
            totalEsicEmployeeMonth: 0,
            totalTdsDeductionMonth: 0,
            totalNetSalaryPayableMonth: 0
        });

        setGrandTotals(totals);
    };

    // Function to get month name from number
    const getMonthName = (monthNumber) => {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return months[monthNumber - 1]; // monthNumber is 1-based, array is 0-based
    };

    const handlePrint = () => {
        window.print();
    };


    return (
        <div className="container-fluid" >
            <div className="row p-1">
                <div className="modal-content">
                    <div className="modal-header m-0 p-2 d-flex align-items-center justify-content-between px-3">
                        <div >
                            <div style={{ height: "50px", width: "100%" }} className='logo p-1'>
                                <img
                                    src={setting.landingPageLogo
                                        ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${setting.landingPageLogo}`
                                        : myLogo}
                                    style={{ height: "100%", objectFit: "cover" }}
                                    alt="LOGO"
                                />
                            </div>
                           
                        </div>
                        <h4 style={{ color: "#00509d" }} className='title-detail text-uppercase fw-bolder font-bold m-0'>SALARY REPORT</h4>
                        <div>
                        <h5 style={{ color: "#00509d" }} className='title-detail text-uppercase fw-bolder font-bold m-0'>{setting.title}</h5>
                            <small style={{ color: "#00509d" }} className='title-detail text-uppercase fw-bolder font-bold m-0'>Date - {getMonthName(month)} {year}</small>
                        </div>
                    </div>

                    <div className="card-body form-row">
                        <div className='col-md-12' style={{ maxHeight: "450px", overflowY: "auto" }}>
                            <table className="table table-striped table-bordered rounded border" >
                                <thead>
                                    <tr>
                                      
                                        <th>Employee</th>
                                        <th>Employee Code</th>
                                        <th>Salary With Contribution</th>
                                        <th>Employer EPF</th>
                                        <th>Employer ESIC</th>
                                        <th>Employee EPF</th>
                                        <th>Employee ESIC</th>
                                        <th>TDs Deduction</th>
                                        <th>Total Net Salary Payable</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredsalaryepfesic.length === 0 ? (
                                        <tr>
                                            <td colSpan="9" className="text-center">No Salary Details Found.</td>
                                        </tr>
                                    ) : (
                                        filteredsalaryepfesic.map((record, index) => (
                                            <tr key={index}>
                                                <td>{record.employeeName}</td>
                                                <td>{record.employeeCode}</td>
                                                <td>&#x20B9;{record.salaryWithContribution != null ? record.salaryWithContribution.toFixed(2) : '0.00'}</td>
                                                <td>&#x20B9;{record.epfEmployerMonth != null ? record.epfEmployerMonth.toFixed(2) : '0.00'}</td>
                                                <td>&#x20B9;{record.esicEmployerMonth != null ? record.esicEmployerMonth.toFixed(2) : '0.00'}</td>
                                                <td>&#x20B9;{record.epfEmployeeMonth != null ? record.epfEmployeeMonth.toFixed(2) : '0.00'}</td>
                                                <td>&#x20B9;{record.esicEmployeeMonth != null ? record.esicEmployeeMonth.toFixed(2) : '0.00'}</td>
                                                <td>&#x20B9;{record.totalTdsDeductionMonth != null ? record.totalTdsDeductionMonth.toFixed(2) : '0.00'}</td>
                                                <td>&#x20B9;{record.netSalaryPayableMonth != null ? record.netSalaryPayableMonth.toFixed(2) : '0.00'}</td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Totals - {grandTotals.totalEmployees}</th>
                                        <th></th>
                                        <th>&#x20B9;{grandTotals.totalSalaryWithContribution != null ? grandTotals.totalSalaryWithContribution.toFixed(2) : '0.00'}</th>
                                        <th>&#x20B9;{grandTotals.totalEpfEmployerMonth != null ? grandTotals.totalEpfEmployerMonth.toFixed(2) : '0.00'}</th>
                                        <th>&#x20B9;{grandTotals.totalEsicEmployerMonth != null ? grandTotals.totalEsicEmployerMonth.toFixed(2) : '0.00'}</th>
                                        <th>&#x20B9;{grandTotals.totalEpfEmployeeMonth != null ? grandTotals.totalEpfEmployeeMonth.toFixed(2) : '0.00'}</th>
                                        <th>&#x20B9;{grandTotals.totalEsicEmployeeMonth != null ? grandTotals.totalEsicEmployeeMonth.toFixed(2) : '0.00'}</th>
                                        <th>&#x20B9;{grandTotals.totalTdsDeductionMonth != null ? grandTotals.totalTdsDeductionMonth.toFixed(2) : '0.00'}</th>
                                        <th>&#x20B9;{grandTotals.totalNetSalaryPayableMonth != null ? grandTotals.totalNetSalaryPayableMonth.toFixed(2) : '0.00'}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className=" modal-footer p-1 d-flex align-items-center justify-content-between">
                        <div>
                            <small className='p-0 m-0'>Powered By - Salary HUB</small>
                        </div>
                        <div className='d-flex gap-2'>
                            <button onClick={handlePrint} className="btn btn-success print-button"><i className="fa fa-download"></i> Print</button>
                            <button onClick={onClose} className="btn btn-danger print-button">Close</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default SalaryReportPreview;
