import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Correct named import
import Login from './authentication/Login';
import Dashboard from './pages/Dashboard';
import ForgotPassword from './authentication/ForgotPassword';
import Employeelist from './pages/EmployeeMaster/Employeelist';
import Officelist from './pages/OurOfficeMaster/Officelist';
import CompanyList from './pages/Company Master/CompanyList';
import RequestLeaveList from './pages/LeaveSection/RequestLeaveList';
import DepartmentList from './pages/Department_Position/DepartmentList';
import ProjectList from './pages/Project_Master/ProjectList';
import AddAttendance from './pages/AttendenceMaster/AddAttendance';
import AttendanceList from './pages/AttendenceMaster/AttendanceList';
import AttendancePreview from './pages/AttendenceMaster/AttendancePreview';
import Advancepayment from './pages/SalaryMaster/AdvancePayment';
import LoanList from './pages/LoanMaster/LoanList';
import Bonous_Insentive_List from './pages/Bonous_Insentive/Bonous_Insentive_List';
import AddSalarySlip from './pages/SalaryMaster/AddSalarySlip';
import SalaryList from './pages/SalaryMaster/SalaryList';
import EditSalarySlip from './pages/SalaryMaster/EditSalarySlip';
import ApplicationSetting from './pages/SettingMaster/ApplicationSetting';
import HRManagerTimLine from './pages/EmployeeMaster/HRManagerTimLine';
import Resign_TerminationList from './pages/LeaveSection/Resign_TerminationList';
import PaymentModeList from './pages/PaymentModeMaster/PaymentModeList';
import AllLeaveList from './pages/LeaveSection/AllLeaveList';
import Profile from './pages/SettingMaster/Profile';
import UserDashboard from './pages/UserDashboard';
import SidebarEmployee from './components/sidebar/SidebarEmployee';
import SearchBarEmployee from './components/sidebar/SearchBarEmployee';
import E_AttendanceDetails from './pages/UserDetails/E_AttendanceDetails';
import E_AdvancePaymentList from './pages/UserDetails/E_AdvancePaymentList';
import E_loanList from './pages/UserDetails/E_loanList';
import E_BonousList from './pages/UserDetails/E_BonousList';
import E_SalaryLedger from './pages/UserDetails/E_SalaryLedger';
import E_EmployeeLeaveList from './pages/UserDetails/E_EmployeeLeaveList';
import E_CheckStatus from './pages/UserDetails/E_CheckStatus';
import E_Profile from './pages/UserDetails/E_Profile';
import PageNotFound from './pages/PageNotFound';
import Salary_Epf_Esic from './pages/ReportMaster/Salary_Epf_Esic';
import LoanReport from './pages/ReportMaster/LoanReport';
import AdvancePaymentReport from './pages/ReportMaster/AdvancePaymentReport';
import BonousReport from './pages/ReportMaster/BonusReport';
import LeaveReport from './pages/ReportMaster/LeaveReport';
import DepartmentAttendance from './pages/ReportMaster/DepartmentAttendance';
import EmployeeJoining from './pages/ReportMaster/EmployeeJoining';
import EmployeeResignTermination from './pages/ReportMaster/EmployeeResignTermination';
import SalaryReport from './pages/ReportMaster/SalaryReport';
import Signup from './authentication/Signup';
import ResetPassword from './authentication/ResetPassword';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [userType, setUserType] = useState(''); // Add userType state
  const [employeeId, setEmployeeId] = useState(''); // Add employeeId state
  const [loading, setLoading] = useState(true);

  const checkAuth = () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);

        // Expiry time is in seconds, convert to milliseconds by multiplying by 1000
        if (decodedToken.exp * 1000 < Date.now()) {
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          localStorage.removeItem('userType');
          localStorage.removeItem('employeeId');
          localStorage.removeItem('fetchemail');
          return false;
        }
        return true;
      } catch (error) {
        return false;
      }
    }
    return false;
  };

  useEffect(() => {
    const isAuthenticated = checkAuth();
    if (isAuthenticated) {
      setUsername(localStorage.getItem('username'));
      setUserType(localStorage.getItem('userType'));
      setEmployeeId(localStorage.getItem('employeeId'));
    }
    setIsAuthenticated(isAuthenticated);
    setLoading(false);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('userType');
    localStorage.removeItem('employeeId');
    localStorage.removeItem('fetchemail');

    setIsAuthenticated(false);
    setUsername('');
    setUserType(''); // Clear userType on logout
    setEmployeeId(''); // Clear employeeId on logout
  };

  const handleLogin = ({ token, username, userType, employeeId }) => {
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
    localStorage.setItem('userType', userType);
    localStorage.setItem('employeeId', employeeId);
    setIsAuthenticated(true);
    setUsername(username);
    setUserType(userType);
    setEmployeeId(employeeId);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={isAuthenticated ? (<Navigate to={userType === 'user' ? "/userdashboard" : "/dashboard"} replace />) : (<Login handleLogin={handleLogin} />)} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        {/* Routes accessible by 'user' */}
        {userType === 'user' && (
          <>
            <Route path="/userdashboard" element={isAuthenticated ? <UserDashboard username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/sidebaremployee" element={isAuthenticated ? <SidebarEmployee username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/searchbaremployee" element={isAuthenticated ? <SearchBarEmployee username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/eattendancelist" element={isAuthenticated ? <E_AttendanceDetails username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/eadvancelist" element={isAuthenticated ? <E_AdvancePaymentList username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/eloanlist" element={isAuthenticated ? <E_loanList username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/ebonouslist" element={isAuthenticated ? <E_BonousList username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/esalaryledger" element={isAuthenticated ? <E_SalaryLedger username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/eemployeeleavelist" element={isAuthenticated ? <E_EmployeeLeaveList username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/echeckstatus" element={isAuthenticated ? <E_CheckStatus username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/eprofile" element={isAuthenticated ? <E_Profile username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
          </>
        )}
        {/* Routes accessible by 'manager' or 'admin' */}
        {(userType === 'manager' || userType === 'admin') && (
          <>
            <Route path="/dashboard" element={isAuthenticated ? (<Dashboard username={username} employeeId={employeeId} userType={userType} handleLogout={handleLogout} />) : (<Navigate to="/" replace />)} />
            <Route path="/employeelist" element={isAuthenticated ? <Employeelist username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/officelist" element={isAuthenticated ? <Officelist username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/companylist" element={isAuthenticated ? <CompanyList username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/requestleavelist" element={isAuthenticated ? <RequestLeaveList username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/departmentlist" element={isAuthenticated ? <DepartmentList username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/projectlist" element={isAuthenticated ? <ProjectList username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/addattendance" element={isAuthenticated ? <AddAttendance username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/attendancelist" element={isAuthenticated ? <AttendanceList username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/attendancePreview" element={isAuthenticated ? <AttendancePreview username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/advancepayment" element={isAuthenticated ? <Advancepayment username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/loanlist" element={isAuthenticated ? <LoanList username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/bonousinsentivelist" element={isAuthenticated ? <Bonous_Insentive_List username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/addsalaryslip" element={isAuthenticated ? <AddSalarySlip username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/salarylist" element={isAuthenticated ? <SalaryList username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/editsalaryslip" element={isAuthenticated ? <EditSalarySlip username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/applicationsetting" element={isAuthenticated ? <ApplicationSetting username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/hrmanagertimeline" element={isAuthenticated ? <HRManagerTimLine username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/resignterminationlist" element={isAuthenticated ? <Resign_TerminationList username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/paymentmodelist" element={isAuthenticated ? <PaymentModeList username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/allleavelist" element={isAuthenticated ? <AllLeaveList username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/allepfesic" element={isAuthenticated ? <Salary_Epf_Esic username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/allsalaryreport" element={isAuthenticated ? <SalaryReport username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/loanreport" element={isAuthenticated ? <LoanReport username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/advancereport" element={isAuthenticated ? <AdvancePaymentReport username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/bonousinsentivereport" element={isAuthenticated ? <BonousReport username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/leavereport" element={isAuthenticated ? <LeaveReport username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/departmentattendancereport" element={isAuthenticated ? <DepartmentAttendance username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/employeejoining" element={isAuthenticated ? <EmployeeJoining username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />
            <Route path="/employeeresigntermination" element={isAuthenticated ? <EmployeeResignTermination username={username} userType={userType} employeeId={employeeId} handleLogout={handleLogout} /> : <Navigate to="/" replace />} />

          </>
        )}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
