// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import SidebarEmployee from '../../components/sidebar/SidebarEmployee';
// import SearchBarEmployee from '../../components/sidebar/SearchBarEmployee';
// import '../Dashboard.css';

// function E_EmployeeLeaveList({ handleLogout, username }) {
//     const [employeeDetails, setEmployeeDetails] = useState(null); // State for employee details
//     const [employeeHistory, setEmployeeHistory] = useState([]);
//     const [currentPage, setCurrentPage] = useState(1); // State for current page in pagination
//     const itemsPerPage = 10; // Number of items per page for pagination

//     const employeeId = localStorage.getItem('employeeId'); // Retrieve employeeId from localStorage

//     useEffect(() => {
//         if (employeeId) { // Fetch only if employeeId exists
//             fetchEmployeeDetails(employeeId);
//             fetchEmployeeHistory(employeeId); // Fetch employee history when employeeId is available
//         }
//     }, [employeeId]);

//     const fetchEmployeeDetails = async (employeeId) => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);
//             const employeeData = response.data.length > 0 ? response.data[0] : null;
//             setEmployeeDetails(employeeData);
//             console.log("employeeDetails", employeeData);
//         } catch (error) {
//             console.error('Error fetching employee details:', error);
//             toast.error('Error fetching employee details');
//         }
//     };

//     const fetchEmployeeHistory = async (employeeId) => {
//         try {
//             const response = await axios.get(
//                 `${process.env.REACT_APP_LOCAL_URL}/activeinactive_employee/${employeeId}`
//             );
//             setEmployeeHistory(response.data);
//         } catch (error) {
//             console.error("Error fetching check-in/out history:", error);
//             toast.error('Error fetching employee history');
//         }
//     };

//     const formatDate = (dateString) => {
//         const date = new Date(dateString);
//         return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
//     };

//     const paginate = (pageNumber) => {
//         setCurrentPage(pageNumber);
//     };

//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItemsemployeehistory = employeeHistory ? employeeHistory.slice(indexOfFirstItem, indexOfLastItem) : [];

//     return (
//         <div className='d-flex w-100 h-100 bg-white'>
//             <SidebarEmployee />
//             <div className='w-100'>
//                 <SearchBarEmployee />
//                 <div className="container-fluid my-2">
//                     <ToastContainer />
//                     <div className="row">
//                         <div className="col-xl-12">
//                             <div className="card shadow mb-4">
//                                 <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
//                                     <h6 className="m-0 font-weight-bold text-primary">Leave List</h6>
//                                 </div>
//                                 <div style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }} className="card-body">
//                                     <div className="row">
//                                         {/* <!-- Salary Ledger Card --> */}
//                                         {currentItemsemployeehistory.length > 0 ? (
//                                             currentItemsemployeehistory.map((entry) => {
//                                                 return (
//                                                     <div className="col-xl-3 col-md-6 mb-4" key={entry.id}>
//                                                         <div className="card shadow h-100 py-1">
//                                                             <div className="card-body">
//                                                                 <div className="row no-gutters align-items-center">
//                                                                     <div className="col-md-12">
//                                                                         <div class="row">
//                                                                             <div class="col-sm">
//                                                                                 Name
//                                                                             </div>
//                                                                             <div class="col-sm">
//                                                                                 <p className="m-0">: {employeeDetails?.employeeName || 'N/A'}</p>
//                                                                             </div>
//                                                                         </div>
//                                                                         <div class="row">
//                                                                             <div class="col-sm">
//                                                                                 Status
//                                                                             </div>
//                                                                             <div class="col-sm">
//                                                                                 <p className="m-0">: {entry.status}</p>
//                                                                             </div>
//                                                                         </div>
//                                                                         <div class="row">
//                                                                             <div class="col-sm">
//                                                                                 Reason
//                                                                             </div>
//                                                                             <div class="col-sm">
//                                                                                 <p className="m-0">: {entry.reason}</p>
//                                                                             </div>
//                                                                         </div>
//                                                                         <div class="row">
//                                                                             <div class="col-sm">
//                                                                                 From Date
//                                                                             </div>
//                                                                             <div class="col-sm">
//                                                                                 <p className="m-0">: {formatDate(entry.fromDate)}</p>
//                                                                             </div>
//                                                                         </div>
//                                                                         <div class="row">
//                                                                             <div class="col-sm">
//                                                                                 To Date
//                                                                             </div>
//                                                                             <div class="col-sm">
//                                                                                 <p className="m-0">: {entry.toDate ? formatDate(entry.toDate) : 'N/A'}</p>
//                                                                             </div>
//                                                                         </div>
//                                                                         <hr />
//                                                                         <div className="d-flex">
//                                                                         <p className="m-0">Description: {entry.description}</p>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 );
//                                             })
//                                         ) : (
//                                             <div className="col-12" style={{ textAlign: 'center' }}>
//                                                 <h5>No Leave Taken.</h5>
//                                             </div>
//                                         )}
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default E_EmployeeLeaveList;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SidebarEmployee from '../../components/sidebar/SidebarEmployee';
import SearchBarEmployee from '../../components/sidebar/SearchBarEmployee';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner

import '../Dashboard.css';

function E_EmployeeLeaveList({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [employeeDetails, setEmployeeDetails] = useState(null); // State for employee details
    const [employeeHistory, setEmployeeHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // State for current page in pagination
    const itemsPerPage = 10; // Number of items per page for pagination

    const employeeId = localStorage.getItem('employeeId'); // Retrieve employeeId from localStorage

    useEffect(() => {
        if (employeeId) { // Fetch only if employeeId exists
            fetchEmployeeDetails(employeeId);
            fetchEmployeeHistory(employeeId); // Fetch employee history when employeeId is available
        }
    }, [employeeId]);

    const fetchEmployeeDetails = async (employeeId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);
            const employeeData = response.data.length > 0 ? response.data[0] : null;
            setEmployeeDetails(employeeData);
            console.log("employeeDetails", employeeData);
        } catch (error) {
            console.error('Error fetching employee details:', error);
            toast.error('Error fetching employee details');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchEmployeeHistory = async (employeeId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_LOCAL_URL}/activeinactive_employee/${employeeId}`
            );
            const history = response.data;
            const groupedHistory = groupLeaveAndActive(history);
            setEmployeeHistory(groupedHistory);
        } catch (error) {
            console.error("Error fetching check-in/out history:", error);
            toast.error('Error fetching employee history');
        } finally {
            setIsLoading(false);
        }
    };

    const groupLeaveAndActive = (history) => {
        const grouped = [];
        let currentGroup = null;

        history.forEach(entry => {
            if (entry.status === 'leave' || entry.status === 'resign_terminate') {
                if (currentGroup) {
                    grouped.push(currentGroup);
                }
                currentGroup = { ...entry, activeDate: null };
            } else if (entry.status === 'active') {
                if (currentGroup) {
                    currentGroup.activeDate = entry.fromDate;
                    currentGroup.activeDescription = entry.description;
                    grouped.push(currentGroup);
                    currentGroup = null;
                }
            }
        });

        if (currentGroup) {
            grouped.push(currentGroup);
        }

        return grouped;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItemsemployeehistory = employeeHistory ? employeeHistory.slice(indexOfFirstItem, indexOfLastItem) : [];

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            <SidebarEmployee />
            <div className='w-100'>
                <SearchBarEmployee username={username} handleLogout={handleLogout} />
                <div className="container-fluid my-2">
                    <ToastContainer />
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 className="m-0 font-weight-bold text-primary">Leave List</h6>
                                </div>
                                <div style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }} className="card-body">
                                    <div className="row">
                                        {/* <!-- Leave and Active Card --> */}
                                        {isLoading ? (
                                            <div className="d-flex justify-content-center align-items-center">
                                                {/* Correct usage of spinner */}
                                                <ThreeDots
                                                    color="#00BFFF"
                                                    height={80}
                                                    width={80}
                                                />
                                            </div>
                                        ) : (
                                            currentItemsemployeehistory.length > 0 ? (
                                                currentItemsemployeehistory.map((entry) => {
                                                    return (
                                                        <div className="col-xl-3 col-md-6 mb-4" key={entry.id}>
                                                            <div className="card shadow h-100 py-1">
                                                                <div className="card-body">
                                                                    <div className="row no-gutters align-items-center">
                                                                        <div className="col-md-12">
                                                                            <div className="row">
                                                                                <div className="col-sm">
                                                                                    Name
                                                                                </div>
                                                                                <div className="col-sm">
                                                                                    <p className="m-0">: {employeeDetails?.employeeName || 'N/A'}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm">
                                                                                    Status
                                                                                </div>
                                                                                <div className="col-sm">
                                                                                    <p className="m-0">: {entry.status}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm">
                                                                                    Reason
                                                                                </div>
                                                                                <div className="col-sm">
                                                                                    <p className="m-0">: {entry.reason}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm">
                                                                                    From Date
                                                                                </div>
                                                                                <div className="col-sm">
                                                                                    <p className="m-0">: {formatDate(entry.fromDate)}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm">
                                                                                    To Date
                                                                                </div>
                                                                                <div className="col-sm">
                                                                                    <p className="m-0">: {entry.toDate ? formatDate(entry.toDate) : 'N/A'}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm">
                                                                                    Description
                                                                                </div>
                                                                                <div className="col-sm">
                                                                                    <p className="m-0">: {entry.description || 'N/A'}</p>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            {entry.activeDate && (
                                                                                <>

                                                                                    <div className="row">
                                                                                        <div className="col-sm">
                                                                                            Status
                                                                                        </div>
                                                                                        <div className="col-sm">
                                                                                            <p className="m-0">: Active</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm">
                                                                                            Date
                                                                                        </div>
                                                                                        <div className="col-sm">
                                                                                            <p className="m-0">: {formatDate(entry.activeDate)}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div className="col-12" style={{ textAlign: 'center' }}>
                                                    <h5>No Leave Taken.</h5>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default E_EmployeeLeaveList;
