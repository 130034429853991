import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';

const EditDepartment = ({ department, onClose, onUpdate }) => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (department) {
            setFormData({ ...department });  // Keeping picture as null initially
        }
    }, [department]);

    const { name, description } = formData;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/departments/${department.id}`, {
                name,
                description
            });
            console.log('Added department successfully:', response.data);

            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload();
            }, 1000); // 1 second delay
        } catch (error) {
            console.error('Error adding department:', error);
            setError('Failed to add department');
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div id="EditDepartment" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Department</h5>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <div className="form-group">
                                <label>Department Name<span style={{ color: "red" }}>*</span></label>
                                <input name="name" value={name} onChange={handleChange} type="text" className="form-control" required placeholder="Department Name" />
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <textarea name="description" value={description} onChange={handleChange} className="form-control" placeholder="Description"></textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? 'Loading...' : 'Submit'}
                            </button>
                            <button type="button" className="btn btn-default" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditDepartment;
