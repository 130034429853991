import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner


const SalaryHistory = ({ onClose, employee }) => {
    const [salaryHistory, setSalaryHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log("employee", employee);
        fetchSalaryHistory();
    }, [employee]);

    const handleClose = () => {
        onClose();
    };

    // Function to fetch salary history details for a specific employee
    const fetchSalaryHistory = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/salaryHistory/${employee.id}`);
            console.log('Fetched employee Salary History:', response.data);
            setSalaryHistory(response.data);
        } catch (error) {
            console.error('Error fetching employee Salary History:', error);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div id="salaryHistoryModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Salary History</h4>
                        <button type="button" className="close" onClick={handleClose}>&times;</button>
                    </div>
                    {isLoading ? (
                        <div className="d-flex justify-content-center align-items-center">
                            {/* Correct usage of spinner */}
                            <ThreeDots
                                color="#00BFFF"
                                height={80}
                                width={80}
                            />
                        </div>
                    ) : (
                        <div className="modal-body p-0" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                            {salaryHistory.length > 0 ? salaryHistory.map((salary, index) => (
                                <div className='bg-light rounded d-flex justify-content-around  border m-1' key={index}>
                                    <div className='w-100'>
                                        <div className=' w-100 p-2 d-flex justify-content-between'>
                                            <div>
                                                <h6>Date: {new Date(salary.date).toLocaleDateString() || "-"}</h6>
                                                <h6>Basic Salary: {salary.basicSalary || "-"}</h6>
                                                <h6>VDA: {salary.vda || "-"}</h6>
                                                <h6>House Rent Allowance : {salary.houserentallowances || "-"}</h6>
                                                <h6>Conveyance Allowance : {salary.conveyanceallowances || "-"}</h6>
                                                <h6>Dearness Allowance : {salary.dearnessallowances || "-"}</h6>
                                                <h6>Special Allowance : {salary.specialallowances || "-"}</h6>

                                                <h6>Gross Salary: {salary.grossSalary || "-"}</h6>
                                            </div>
                                            <div>
                                                <h6>EPF & ESIC Applicable: {salary.epfesicApplicable ? 'Yes' : 'No'}</h6>
                                                <h6>EPF (Employer): {salary.epfEmployer || "-"}</h6>
                                                <h6>ESIC (Employer): {salary.esicEmployer || "-"}</h6>
                                                <h6>Total Employer Contribution: {salary.totalEmployerContribution || "-"}</h6>
                                                <h6>EPF (Employee): {salary.epfEmployee || "-"}</h6>
                                                <h6>ESIC (Employee): {salary.esicEmployee || "-"}</h6>
                                                <h6>Total Employee Deduction: {salary.totalEmployeeDeduction || "-"}</h6>
                                            </div>
                                            <div>
                                                <h6>TDS Applicable: {salary.tdsApplicable ? 'Yes' : 'No'}</h6>
                                                <h6>Total TDS Deduction: {salary.totalTdsDeduction || "-"}</h6>
                                                <h6>Total In Hand Salary: {salary.totalInHandSalary || "-"}</h6>
                                                <h6>Total Payable Salary: {salary.totalPayableSalary || "-"}</h6>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            )) : (
                                <h5 className='text-center'>No salary history found for this employee.</h5>
                            )}
                        </div>
                    )}
                    <div className="modal-footer d-flex align-items-center justify-content-between">
                        <button type="button" className="btn btn-danger" onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SalaryHistory;











