import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Dashboard.css';
import SidebarEmployee from '../../components/sidebar/SidebarEmployee';
import SearchBarEmployee from '../../components/sidebar/SearchBarEmployee';
import { fontSize } from '@mui/system';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner



function E_AttendanceDetails({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [IsadvanceRepayemnt, setIsadvanceRepayemnt] = useState(false);
    const [selectedMonthAdvance, setSelectedMonthAdvance] = useState(new Date().getMonth());
    const [selectedYearAdvance, setSelectedYearAdvance] = useState(new Date().getFullYear());
    const [advanceRecords, setAdvanceRecords] = useState([]);
    const [advanceRepayments, setAdvanceRepayments] = useState([]);
    const [advanceBalances, setAdvanceBalances] = useState([]);
    const [filteredAdvanceRecords, setFilteredAdvanceRecords] = useState([]);
    const [filteredAdvanceRepayments, setFilteredAdvanceRepayments] = useState([]);
    const [filteredAdvanceBalances, setFilteredAdvanceBalances] = useState([]);
    const [totalAdvanceCurrentMonth, setTotalAdvanceCurrentMonth] = useState(0);
    const [totalAdvanceAmount, setTotalAdvanceAmount] = useState(0);
    const [advanceRepayment, setAdvanceRepayment] = useState(null);
    const [isAdvanceRepayment, setIsAdvanceRepayment] = useState(false);
    const [totalRepayment, setTotalRepayment] = useState(0);

    // profile 
    const employeeId = localStorage.getItem('employeeId'); // Fix: Correctly retrieve employeeId from localStorage


    // Advance Payment and Repayments 
    const handleAdvanceRepayment = (record, totalAdvanceAmount) => {
        setAdvanceRepayment(record);
        setIsAdvanceRepayment(true);
    };

    const fetchAdvanceRecords = async (employeeId) => {

        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/advancepayment/${employeeId}`);
            const allRecords = response.data;
            setAdvanceRecords(allRecords);
            filterAdvanceRecords(selectedMonthAdvance, selectedYearAdvance, allRecords);
        } catch (error) {
            console.error('Error fetching advance records:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAdvanceRepaymentRecords = async (employeeId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/advancerepayments/${employeeId}`);
            const allRecords = response.data;
            setAdvanceRepayments(allRecords);
            filterAdvanceRepayments(selectedMonthAdvance, selectedYearAdvance, allRecords);
        } catch (error) {
            console.error('Error fetching advance repayment records:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAdvanceBalance = async (employeeId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/advancebalance/${employeeId}`);
            setAdvanceBalances(response.data);
            filterAdvanceBalances(selectedMonthAdvance, selectedYearAdvance, response.data);
        } catch (error) {
            console.error('Error fetching advance balance:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAdvanceRecords(employeeId);
        fetchAdvanceRepaymentRecords(employeeId);
        fetchAdvanceBalance(employeeId);
    }, [employeeId]);

    const filterAdvanceRecords = (month, year, data = advanceRecords) => {
        const filteredRecords = data.filter(record => {
            const recordDate = new Date(record.date);
            return recordDate.getMonth() === month && recordDate.getFullYear() === year;
        });

        const totalAdvance = filteredRecords.reduce((total, record) => total + record.amount, 0);
        setTotalAdvanceCurrentMonth(totalAdvance);
        setTotalAdvanceAmount(totalAdvance);
        setFilteredAdvanceRecords(filteredRecords);
    };

    const filterAdvanceRepayments = (month, year, data = advanceRepayments) => {
        const filteredRecords = data.filter(record => {
            const recordDate = new Date(record.date);
            return recordDate.getMonth() === month && recordDate.getFullYear() === year;
        });

        const totalRepayment = filteredRecords.reduce((total, record) => total + record.amount, 0);
        setTotalRepayment(totalRepayment);
        setFilteredAdvanceRepayments(filteredRecords);
    };

    const filterAdvanceBalances = (month, year, data = advanceBalances) => {
        const currentMonth = new Date(year, month);
        const previousMonth = new Date(year, month - 1);

        const currentMonthBalance = data.find(balance => {
            const balanceDate = new Date(balance.month);
            return balanceDate.getFullYear() === currentMonth.getFullYear() && balanceDate.getMonth() === currentMonth.getMonth();
        }) || { balance: 0 };

        let previousMonthBalance = { balance: 0 };

        for (let i = 1; i <= 12; i++) {
            const checkMonth = new Date(year, month - i);
            previousMonthBalance = data.find(balance => {
                const balanceDate = new Date(balance.month);
                return balanceDate.getFullYear() === checkMonth.getFullYear() && balanceDate.getMonth() === checkMonth.getMonth();
            }) || previousMonthBalance;

            if (previousMonthBalance.balance !== 0) break;
        }

        setFilteredAdvanceBalances({
            currentMonth: currentMonthBalance.balance,
            previousMonth: previousMonthBalance.balance
        });
    };

    useEffect(() => {
        filterAdvanceRecords(selectedMonthAdvance, selectedYearAdvance);
        filterAdvanceRepayments(selectedMonthAdvance, selectedYearAdvance);
        filterAdvanceBalances(selectedMonthAdvance, selectedYearAdvance);
    }, [selectedMonthAdvance, selectedYearAdvance]);

    // Advance Payment and Repayments 

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    };


    return (
        <div className=''>
            <div className='d-flex w-100 h-100 bg-white'>
                <SidebarEmployee />
                <div className='w-100'>
                    <SearchBarEmployee username={username} handleLogout={handleLogout} />
                    <div className="container-fluid">
                        <ToastContainer />
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="bg-white card-header py-3 d-flex flex-column flex-md-row align-items-center justify-content-between">
                                        <div className='d-flex align-items-center align-items-md-start flex-column '>
                                            <h6 className="m-0 font-weight-bold text-primary">Advance Payment & Repayment List</h6>
                                            <h6 className='m-0 font-weight-bold text-danger'>Carry Forward Balance: {filteredAdvanceBalances.previousMonth || "0"}</h6>
                                        </div>
                                        <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-center gap-1 mt-2 mt-md-0'>
                                            <label className='m-0 text-black fw-bolder'>Filter:</label>
                                            <select className="form-control" value={selectedMonthAdvance}
                                                onChange={(e) => setSelectedMonthAdvance(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Month</option>
                                                {Array.from({ length: 12 }, (_, i) => (
                                                    <option key={i} value={i}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
                                                ))}
                                            </select>
                                            <select className="form-control" value={selectedYearAdvance}
                                                onChange={(e) => setSelectedYearAdvance(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Year</option>
                                                {Array.from({ length: 10 }, (_, i) => (
                                                    <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row">
                                        {/*  <!-- Total employee Card Example --> */}
                                        <div className="col-xl-6 col-md-6 mb-2">
                                            <div className=" shadow h-100 py-1">
                                                <div className="card-body">
                                                    <div className="row no-gutters align-items-center">
                                                        <div className="col mr-2">
                                                            <div className="text-xs font-weight-bold  text-uppercase mb-1" style={{ fontSize: '1rem' }}>
                                                                Advance Payment</div>
                                                        </div>
                                                    </div>
                                                    <div className="row no-gutters align-items-center">
                                                        <div className="col-md-12">
                                                            <hr className="my-2" />
                                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                                    <tr>
                                                                        <th>Payment Date</th>
                                                                        <th>Amount</th>
                                                                        <th>Payment Mode Name</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {isLoading ? (
                                                                        <div className="d-flex justify-content-center align-items-center">
                                                                            {/* Correct usage of spinner */}
                                                                            <ThreeDots
                                                                                color="#00BFFF"
                                                                                height={80}
                                                                                width={80}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        filteredAdvanceRecords.length === 0 ? (
                                                                            <tr>
                                                                                <td colSpan="3" className="text-center">No advance records found for the selected month and year.</td>
                                                                            </tr>
                                                                        ) : (
                                                                            filteredAdvanceRecords.map(record => (
                                                                                <tr key={record.id}>
                                                                                    <td>{formatDate(record.date)}</td>
                                                                                    <td>{record.amount}</td>
                                                                                    <td>{record.paymentModeName || "-"}</td>
                                                                                </tr>
                                                                            ))
                                                                        ))}
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td colSpan="1">Total :</td>
                                                                        <td>{totalAdvanceCurrentMonth}</td>
                                                                        <td>Month: {new Date(selectedYearAdvance, selectedMonthAdvance).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}</td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*  <!-- Total employee Card Example --> */}
                                        <div className="col-xl-6 col-md-6 mb-2">
                                            <div className=" shadow h-100 py-1">
                                                <div className="card-body">
                                                    <div className="row no-gutters align-items-center">
                                                        <div className="col mr-2">
                                                            <div className="text-xs font-weight-bold  text-uppercase mb-1" style={{ fontSize: '1rem' }}>
                                                                Advance Repayment</div>
                                                        </div>
                                                    </div>
                                                    <div className="row no-gutters align-items-center">
                                                        <div className="col-md-12">
                                                            <hr className="my-2" />
                                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                                    <tr>
                                                                        <th>Repayment Date</th>
                                                                        <th>Amount</th>
                                                                        <th>Payment Mode Name</th>

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {isLoading ? (
                                                                        <div className="d-flex justify-content-center align-items-center">
                                                                            {/* Correct usage of spinner */}
                                                                            <ThreeDots
                                                                                color="#00BFFF"
                                                                                height={80}
                                                                                width={80}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        filteredAdvanceRepayments.length === 0 ? (
                                                                            <tr>
                                                                                <td colSpan="3" className="text-center">No advance repayments found for the selected month and year.</td>
                                                                            </tr>
                                                                        ) : (
                                                                            filteredAdvanceRepayments.map(record => (
                                                                                <tr key={record.id}>
                                                                                    <td>{formatDate(record.date)}</td>
                                                                                    <td>{record.amount}</td>
                                                                                    <td>{record.receivingMode || "-"}</td>
                                                                                </tr>
                                                                            ))
                                                                        ))}
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td colSpan="1">Total :</td>
                                                                        <td>{totalRepayment}</td>
                                                                        <td>Month:{new Date(selectedYearAdvance, selectedMonthAdvance).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}</td>
                                                                    </tr>

                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-12 col-md-12">
                                            <div className="h-100 ">
                                                <div className="card-body">
                                                    <div className="row no-gutters align-items-center">
                                                        <div className="col-md-12 d-flex align-items-center justify-content-around">
                                                            <p style={{ fontSize: "10px" }} className="m-0 text-body-secondary">  Previous Month Balance:- {filteredAdvanceBalances.previousMonth || "0"}</p>
                                                            <p style={{ fontSize: "10px" }} className="m-0 text-body-secondary"> Current Month Amt:-  {totalAdvanceCurrentMonth || "0"}</p>
                                                            <p style={{ fontSize: "10px" }} className="m-0 text-body-secondary"> Current Month Received:- {totalRepayment || "0"}</p>
                                                            <p style={{ fontSize: "10px" }} className="m-0 fw-bolder text-black">Closing Balance:- {filteredAdvanceBalances.currentMonth || "0"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default E_AttendanceDetails;
