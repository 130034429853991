import React, { useState } from 'react';
import axios from 'axios';

const ChangeSalary = ({ employee, onClose, onUpdate }) => {
    const [formData, setFormData] = useState({
        basicSalary: employee.basicSalary || 0,
        vda: employee.vda || 0,
        houserentallowances: employee.houserentallowances || 0,
        conveyanceallowances: employee.conveyanceallowances || 0,
        dearnessallowances: employee.allowances || 0,
        specialallowances: employee.specialallowances || 0,
        grossSalary: 0,
        epfesicApplicable: employee.epfesicApplicable || false,
        esicApplicable: employee.esicApplicable || false,
        tdsApplicable: employee.tdsApplicable || false,
        totalEmployerContribution: 0,
        totalEmployeeDeduction: 0,
        totalTdsDeduction: 0,
        totalPayableSalary: 0,
        totalInHandSalary: 0,
        date: "",

    });

    const [isLoading, setIsLoading] = useState(false);

    const calculateSalaries = (updatedFormData) => {
        const { basicSalary, vda, specialallowances, dearnessallowances, conveyanceallowances, houserentallowances, epfesicApplicable, esicApplicable, tdsApplicable } = updatedFormData;
        const grossSalary = parseFloat(basicSalary) + parseFloat(vda) + parseFloat(specialallowances) + parseFloat(dearnessallowances) + parseFloat(conveyanceallowances) + parseFloat(houserentallowances);

        let epfEmployer = 0, esicEmployer = 0, epfEmployee = 0, esicEmployee = 0, tdsDeduction = 0;

        if (epfesicApplicable) {
            //     epfEmployer = (0.12 * parseFloat(basicSalary)).toFixed(2);
            //     epfEmployee = (0.12 * parseFloat(basicSalary)).toFixed(2);
            //     esicEmployer = (0.0325 * grossSalary).toFixed(2);
            //     esicEmployee = (0.0075 * grossSalary).toFixed(2);
            // }                // EPF Calculation
            const epfLimit = 15000;
            const epfBase = Math.min(basicSalary, epfLimit);
            epfEmployer = (0.12 * parseFloat(epfBase)).toFixed(2);
            epfEmployee = (0.12 * parseFloat(epfBase)).toFixed(2);

            // ESIC Calculation
            const esicLimit = 21000;
            if (grossSalary <= esicLimit) {
                esicEmployer = (0.0325 * grossSalary).toFixed(2);
                esicEmployee = (0.0075 * grossSalary).toFixed(2);
            } else {
                esicEmployer = 0;
                esicEmployee = 0;
            }
        }

        const totalEmployerContribution = (parseFloat(epfEmployer) + parseFloat(esicEmployer)).toFixed(2);
        const totalEmployeeDeduction = (parseFloat(epfEmployee) + parseFloat(esicEmployee)).toFixed(2);

        // if (tdsApplicable) {
        //     tdsDeduction = (0.1 * grossSalary).toFixed(2);
        // }

        // const totalTdsDeduction = parseFloat(tdsDeduction).toFixed(2);
        const totalPayableSalary = (grossSalary + parseFloat(totalEmployerContribution)).toFixed(2);
        const totalInHandSalary = (grossSalary - parseFloat(totalEmployeeDeduction));

        return {
            grossSalary,
            totalEmployerContribution,
            totalEmployeeDeduction,
            // totalTdsDeduction,
            totalPayableSalary,
            totalInHandSalary,
            epfEmployer,
            epfEmployee,
            esicEmployer,
            esicEmployee,
        };
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        };

        const calculatedValues = calculateSalaries(updatedFormData);
        setFormData({
            ...updatedFormData,
            ...calculatedValues
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const payload = {
                ...formData,
                employeeId: employee.id,
                employeeName: employee.employeeName,

            };
            console.log("payload ", payload)
            await axios.put(`${process.env.REACT_APP_LOCAL_URL}/updatesalary/${employee.id}`, payload);
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/changesalary`, payload);

            console.log('Salary updated successfully');

            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload();
            }, 1000); // 1 second delay
        } catch (error) {
            console.error('Error updating salary:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div>
            <div id="changeSalaryModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <form onSubmit={handleSubmit} encType="multipart/form-data" autoComplete="off" noValidate>
                            <div className="modal-header">
                                <h5 className="modal-title">Change Salary</h5>
                                <button type="button" className="close" onClick={handleClose}>&times;</button>
                            </div>
                            <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                                <h6 className='text-primary fw-bolder'>Basic Salary -------</h6>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>Basic Salary <span style={{ color: "red" }}>*</span></label>
                                        <input name="basicSalary" type="number" className="form-control" placeholder="Enter basic salary" onChange={handleChange} value={formData.basicSalary} required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>VDA</label>
                                        <input name="vda" type="number" className="form-control" placeholder="Enter VDA" onChange={handleChange} value={formData.vda} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>HouseRent Allowances</label>
                                        <input name="houserentallowances" type="number" className="form-control" placeholder="Enter allowances" onChange={handleChange} value={formData.allowances} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Conveyance Allowances</label>
                                        <input name="conveyanceallowances" type="number" className="form-control" placeholder="Enter allowances" onChange={handleChange} value={formData.allowances} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Dearness Allowances</label>
                                        <input name="dearnessallowances" type="number" className="form-control" placeholder="Enter allowances" onChange={handleChange} value={formData.allowances} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>Special Allowances</label>
                                        <input name="specialallowances" type="number" className="form-control" placeholder="Enter allowances" onChange={handleChange} value={formData.allowances} />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Gross Salary</label>
                                        <input name="grossSalary" type="number" placeholder='Gross Salary' className="form-control" value={formData.grossSalary} readOnly />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label>Is EPF Applicable?</label>
                                        <div>
                                            <input name="epfesicApplicable" type="checkbox" onChange={handleChange} checked={formData.epfesicApplicable} /> Yes
                                        </div>
                                    </div>

                                    {formData.epfesicApplicable && (
                                        <>
                                            <h6 className='text-primary fw-bolder form-group col-md-12'>Employer Part -------</h6>

                                            <div className="form-group col-md-4">
                                                <label>Employer EPF 12% </label>
                                                <input name="employerEpf" type="number" className="form-control" value={(0.12 * formData.basicSalary).toFixed(2)} readOnly />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>Employer ESIC 3.25%</label>
                                                <input name="employerEsic" type="number" className="form-control" value={(0.0325 * formData.grossSalary).toFixed(2)} readOnly />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>Total Employer Contribution</label>
                                                <input name="totalEmployerContribution" placeholder='Total Employer Contribution' type="number" className="form-control" value={formData.totalEmployerContribution} readOnly />
                                            </div>


                                            <h6 className='text-primary fw-bolder form-group col-md-12'>Employee Part -------</h6>

                                            <div className="form-group col-md-4">
                                                <label>Employee EPF 12%</label>
                                                <input name="employeeEpf" type="number" className="form-control" value={(0.12 * formData.basicSalary).toFixed(2)} readOnly />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>Employee ESIC 0.75%</label>
                                                <input name="employeeEsic" type="number" className="form-control" value={(0.0075 * formData.grossSalary).toFixed(2)} readOnly />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>Total Employee Deduction</label>
                                                <input name="totalEmployeeDeduction" type="number" placeholder='Total Employee Deduction' className="form-control" value={formData.totalEmployeeDeduction} readOnly />
                                            </div>

                                        </>
                                    )}

                                    <div className="form-group col-md-12">
                                        <label>Is TDS Applicable?</label>
                                        <div>
                                            <input name="tdsApplicable" type="checkbox" onChange={handleChange} checked={formData.tdsApplicable} /> Yes
                                        </div>
                                    </div>
                                    {/* {formData.tdsApplicable && (
                                        <div className="form-group col-md-12">
                                            <label>Total TDS Deduction @10%</label>
                                            <input name="totalTdsDeduction" type="number" placeholder='Total TDS Deduction' className="form-control" value={formData.totalTdsDeduction} readOnly />
                                        </div>
                                    )} */}
                                    <h6 className='text-primary fw-bolder form-group col-md-12 '>Total Salary -------</h6>
                                    <div className="form-group col-md-4">
                                        <label>Total Payable Salary</label>
                                        <input name="totalPayableSalary" placeholder='Total Payable Salary' type="number" className="form-control" value={formData.totalPayableSalary} readOnly />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Total In Hand Salary</label>
                                        <input name="totalInHandSalary" placeholder='Total In Hand Salary' type="number" className="form-control" value={formData.totalInHandSalary} readOnly />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label>Date</label>
                                        <input
                                            name="date"
                                            type="date"
                                            className="form-control"
                                            value={formData.date}
                                            onChange={handleChange} // Add onChange handler to update formData.date
                                        />

                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                    {isLoading ? 'Loading...' : 'Submit'}
                                </button>
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangeSalary;













