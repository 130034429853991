import React, { useState } from 'react';
import axios from 'axios';

const DocumentionForm = ({ employee, onClose, onUpdate }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        panCardPhoto: null,
        aadharCardPhoto: null,
        passportSizePhoto: null,
        marksheet10thPhoto: null,
        marksheet12thPhoto: null,
        graductionmarksheet: null,
        postGraductionmarksheet: null,
        professionaldegree: null,
        offerletter: null,
        joiningletter: null,
        appointmentletter: null,
        employeementletter: null,
        experienceletter: null,
        drivinglicense: null,
        passport: null,
        resumePhoto: null,
        otherPhoto: null,
    });
    const [validationErrors, setValidationErrors] = useState({});
    const [error, setError] = useState('');

    const handleImageChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];

        // Validation conditions
        const maxSize = 200 * 1024; // 200KB
        const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];

        if (file) {
            // Check file type
            if (!allowedTypes.includes(file.type)) {
                setValidationErrors(prevErrors => ({
                    ...prevErrors,
                    [name]: 'Only JPG, PNG, and PDF files are allowed.'
                }));
                return;
            }

            // Check file size
            if (file.size > maxSize) {
                setValidationErrors(prevErrors => ({
                    ...prevErrors,
                    [name]: 'File size should be less than or equal to 200KB.'
                }));
                return;
            }

            // Clear previous error if the file is valid
            setValidationErrors(prevErrors => ({
                ...prevErrors,
                [name]: ''
            }));
        }

        // Process the file as needed, e.g., set the file in state or form data
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: file
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const formDataToSend = new FormData();
            Object.keys(formData).forEach((key) => {
                formDataToSend.append(key, formData[key]);
            });
            const response = await axios.put(
                `${process.env.REACT_APP_LOCAL_URL}/updateDocumentation/${employee.id}`,
                formDataToSend
            );
            console.log("update Documention updated:", response.data);
            onUpdate(formData);

            setTimeout(() => {
                onClose();
                window.location.reload();
            }, 1000); // 1 second delay
        } catch (error) {
            console.error('Error updating Documentation:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    const inputClass = (field) => validationErrors[field] ? 'form-control is-invalid' : 'form-control';

    return (
        <div>
            <div id="changeDocumentionModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <form onSubmit={handleSubmit} encType="multipart/form-data" autoComplete="off" noValidate>
                            <div className="modal-header">
                                <h5 className="modal-title">Documention Upload/Update</h5>
                                <button type="button" className="close" onClick={handleClose}>&times;</button>
                            </div>
                            <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                                <div>
                                    <h4 className='text-dark py-2 fw-bolder'>Upload Documents</h4>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label>Passport Size Photo <span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="passportSizePhoto"
                                                type="file"
                                                className={`form-control-file ${inputClass('passportSizePhoto')}`}
                                                onChange={handleImageChange}
                                            />
                                            {validationErrors.passportSizePhoto && <div className="invalid-feedback">{validationErrors.passportSizePhoto}</div>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>PAN Card</label>
                                            <input
                                                name="panCardPhoto"
                                                type="file"
                                                className={`form-control-file ${inputClass('panCardPhoto')}`}
                                                onChange={handleImageChange}
                                            />
                                            {validationErrors.panCardPhoto && <div className="invalid-feedback">{validationErrors.panCardPhoto}</div>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Aadhar Card<span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="aadharCardPhoto"
                                                type="file"
                                                className={`form-control-file ${inputClass('aadharCardPhoto')}`}
                                                onChange={handleImageChange}
                                            />
                                            {validationErrors.aadharCardPhoto && <div className="invalid-feedback">{validationErrors.aadharCardPhoto}</div>}
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label>Driving Licence<span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="drivinglicense"
                                                type="file"
                                                className={`form-control-file ${inputClass('drivinglicense')}`}
                                                onChange={handleImageChange}
                                            />
                                            {validationErrors.drivinglicense && <div className="invalid-feedback">{validationErrors.drivinglicense}</div>}
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label>Pass Port<span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="passport"
                                                type="file"
                                                className={`form-control-file ${inputClass('passport')}`}
                                                onChange={handleImageChange}
                                            />
                                            {validationErrors.passport && <div className="invalid-feedback">{validationErrors.passport}</div>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>10th Marksheet</label>
                                            <input
                                                name="marksheet10thPhoto"
                                                type="file"
                                                className={`form-control-file ${inputClass('marksheet10thPhoto')}`}
                                                onChange={handleImageChange}
                                            />
                                            {validationErrors.marksheet10thPhoto && <div className="invalid-feedback">{validationErrors.marksheet10thPhoto}</div>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>12th Marksheet</label>
                                            <input
                                                name="marksheet12thPhoto"
                                                type="file"
                                                className={`form-control-file ${inputClass('marksheet12thPhoto')}`}
                                                onChange={handleImageChange}
                                            />
                                            {validationErrors.marksheet12thPhoto && <div className="invalid-feedback">{validationErrors.marksheet12thPhoto}</div>}
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label>Graduction Marksheet</label>
                                            <input
                                                name="graductionmarksheet"
                                                type="file"
                                                className={`form-control-file ${inputClass('graductionmarksheet')}`}
                                                onChange={handleImageChange}
                                            />
                                            {validationErrors.graductionmarksheet && <div className="invalid-feedback">{validationErrors.graductionmarksheet}</div>}
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label>Post Graduction Marksheet</label>
                                            <input
                                                name="postgraductionmarksheet"
                                                type="file"
                                                className={`form-control-file ${inputClass('postgraductionmarksheet')}`}
                                                onChange={handleImageChange}
                                            />
                                            {validationErrors.postgraductionmarksheet && <div className="invalid-feedback">{validationErrors.postgraductionmarksheet}</div>}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Professional Degree</label>
                                            <input
                                                name="professionaldegree"
                                                type="file"
                                                className={`form-control-file ${inputClass('professionaldegree')}`}
                                                onChange={handleImageChange}
                                            />
                                            {validationErrors.professionaldegree && <div className="invalid-feedback">{validationErrors.professionaldegree}</div>}
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label>Resume<span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="resumePhoto"
                                                type="file"
                                                className={`form-control-file ${inputClass('resumePhoto')}`}
                                                onChange={handleImageChange}
                                            />
                                            {validationErrors.resumePhoto && <div className="invalid-feedback">{validationErrors.resumePhoto}</div>}
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label>Offer Letter<span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="offerletter"
                                                type="file"
                                                className={`form-control-file ${inputClass('offerletter')}`}
                                                onChange={handleImageChange}
                                            />
                                            {validationErrors.offerletter && <div className="invalid-feedback">{validationErrors.offerletter}</div>}
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label>Joining Letter<span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="joiningletter"
                                                type="file"
                                                className={`form-control-file ${inputClass('joiningletter')}`}
                                                onChange={handleImageChange}
                                            />
                                            {validationErrors.joiningletter && <div className="invalid-feedback">{validationErrors.joiningletter}</div>}
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label>Appointment Letter<span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="appointmentletter"
                                                type="file"
                                                className={`form-control-file ${inputClass('appointmentletter')}`}
                                                onChange={handleImageChange}
                                            />
                                            {validationErrors.appointmentletter && <div className="invalid-feedback">{validationErrors.appointmentletter}</div>}
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label>Employeement Letter<span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="employeementletter"
                                                type="file"
                                                className={`form-control-file ${inputClass('employeementletter')}`}
                                                onChange={handleImageChange}
                                            />
                                            {validationErrors.employeementletter && <div className="invalid-feedback">{validationErrors.employeementletter}</div>}
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label>Experience Letter<span style={{ color: "red" }}>*</span></label>
                                            <input
                                                name="experienceletter"
                                                type="file"
                                                className={`form-control-file ${inputClass('experienceletter')}`}
                                                onChange={handleImageChange}
                                            />
                                            {validationErrors.experienceletter && <div className="invalid-feedback">{validationErrors.experienceletter}</div>}
                                        </div>

                                        <div className="form-group col-md-6">
                                            <label>Other Photo</label>
                                            <input
                                                name="otherPhoto"
                                                type="file"
                                                className={`form-control-file ${inputClass('otherPhoto')}`}
                                                onChange={handleImageChange}
                                            />
                                            {validationErrors.otherPhoto && <div className="invalid-feedback">{validationErrors.otherPhoto}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                    {isLoading ? 'Loading...' : 'Update Documention'}
                                </button>
                                <button type="button" className="btn btn-secondary" onClick={handleClose} >
                                    Close
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocumentionForm;
