import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { FaEdit } from 'react-icons/fa'; // Import the FaEdit icon from Font Awesome
import { toast, ToastContainer } from 'react-toastify';
import myImage from '../../images/employee_profile.png';
import 'react-toastify/dist/ReactToastify.css';
import SidebarEmployee from '../../components/sidebar/SidebarEmployee';
import SearchBarEmployee from '../../components/sidebar/SearchBarEmployee';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner


const E_Profile = ({ handleLogout, username }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState({
        employeeName: '',
        employeeCode: '',
        departmentName: '',
        positionName: '',
        employeePhone: '',
        employeeEmail: '',
        employeeAddress1: '',
        employeeState1: '',
        employeeCity1: '',
        employeePincode1: '',
        passportSizePhoto: ''
    });
    const employeeId = localStorage.getItem('employeeId');

    useEffect(() => {
        if (employeeId) {
            fetchEmployeeDetails(employeeId);
        }
    }, [employeeId]);

    const fetchEmployeeDetails = async (employeeId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);
            if (response.data.length > 0) {
                const employeeData = response.data[0];
                setSelectedEmployee(employeeData);
                console.log("Employee Details:", employeeData);
            }
        } catch (error) {
            console.error('Error fetching employee details:', error);
            toast.error('Failed to load employee details');
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedEmployee(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <div className=''>
            <div className='d-flex w-100 h-100 bg-white'>
                <SidebarEmployee />
                <div className='w-100'>
                    <SearchBarEmployee username={username} handleLogout={handleLogout} />
                    <div className="container-fluid">
                        <ToastContainer /> {/* Toast container */}
                        <div style={{ minHeight: "87vh" }} className="row bg-white shadow-sm rounded p-2 d-flex align-items-center justify-content-center">
                            <div className="col-lg-4 m-1">
                                <div style={{ backgroundImage: "linear-gradient(to bottom, #00509d, #FFFFFF)" }} className="card border rounded shadow-sm">
                                    {isLoading ? (
                                        <div className="d-flex justify-content-center align-items-center">
                                            {/* Correct usage of spinner */}
                                            <ThreeDots
                                                color="#00BFFF"
                                                height={80}
                                                width={80}
                                            />
                                        </div>
                                    ) : (
                                        <div className="card-body d-flex align-items-center justify-content-center flex-column py-5 px-0">
                                            <div style={{ maxHeight: "30vh", maxWidth: "30vh", overflow: "hidden" }} className='d-flex bg-dark rounded-circle align-items-center justify-content-center'>
                                                <img
                                                    src={selectedEmployee.passportSizePhoto
                                                        ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${selectedEmployee.passportSizePhoto}`
                                                        : myImage}
                                                    className='w-100 h-100'
                                                    
                                                />
                                            </div>
                                            <div>
                                                <h4 style={{ color: "#00509d" }} className="title-detail fw-bolder text-center font-bold my-3">{selectedEmployee.employeeName}</h4>
                                                <div>
                                                    <p className="mb-2 fw-bold">
                                                        <i className="fa fa-building"></i> <span> Department: {selectedEmployee.departmentName || "N/A"}</span>
                                                    </p>
                                                    <p className="mb-2 fw-bold">
                                                        <i className="fas fa-users"></i> <span> Designation: {selectedEmployee.positionName || "N/A"}</span>
                                                    </p>
                                                    <p className="mb-2 fw-bold">
                                                        <i className="fa fa-envelope" aria-hidden="true"></i> <span>{selectedEmployee.employeeEmail || "N/A"}</span>
                                                    </p>
                                                    <p className="mb-2 fw-bold">
                                                        <i className="fa fa-phone"></i> <span> Phone: {selectedEmployee.employeePhone || "N/A"}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-7 bg-light rounded border shadow-sm p-3 m-1">
                                <div className="modal-header p-1">
                                    <h5 style={{ color: "#00509d" }} className="modal-title fw-bolder text-center font-bold">Information</h5>
                                </div>
                                <form className='pt-1'>
                                    {isLoading ? (
                                        <div className="d-flex justify-content-center align-items-center">
                                            {/* Correct usage of spinner */}
                                            <ThreeDots
                                                color="#00BFFF"
                                                height={80}
                                                width={80}
                                            />
                                        </div>
                                    ) : (
                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label>Employee Name</label>
                                                <input name="employeeName" type="text" className="form-control" placeholder="Enter Employee Name" value={selectedEmployee.employeeName} onChange={handleInputChange} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>Employee Code</label>
                                                <input name="employeeCode" type="text" className="form-control" placeholder="Enter Employee Code" value={selectedEmployee.employeeCode} onChange={handleInputChange} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>Department</label>
                                                <input name="departmentName" type="text" className="form-control" placeholder="Enter Department" value={selectedEmployee.departmentName} onChange={handleInputChange} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>Designation</label>
                                                <input name="positionName" type="text" className="form-control" placeholder="Enter Designation" value={selectedEmployee.positionName} onChange={handleInputChange} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>Phone</label>
                                                <input name="employeePhone" type="number" className="form-control" placeholder="Enter phone number" value={selectedEmployee.employeePhone} onChange={handleInputChange} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>Email</label>
                                                <input name="employeeEmail" type="email" className="form-control" placeholder="Enter email" value={selectedEmployee.employeeEmail} onChange={handleInputChange} />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Address</label>
                                                <textarea name="employeeAddress1" className="form-control" placeholder="Enter address" value={selectedEmployee.employeeAddress1} onChange={handleInputChange} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>State<span style={{ color: "red" }}>*</span></label>
                                                <input name="employeeState1" type="text" className="form-control" placeholder="Enter State" value={selectedEmployee.employeeState1} onChange={handleInputChange} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>City</label>
                                                <input name="employeeCity1" type="text" className="form-control" placeholder="Enter City" value={selectedEmployee.employeeCity1} onChange={handleInputChange} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>Pincode</label>
                                                <input name="employeePincode1" type="number" className="form-control" placeholder="Enter pincode number" value={selectedEmployee.employeePincode1} onChange={handleInputChange} />
                                            </div>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default E_Profile;
