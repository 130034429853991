import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EditAdvancePayment = ({ advance, onClose, onUpdate }) => {
    const [formData, setFormData] = useState({
        date: '', 
        amount: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (advance) {
            setFormData({ ...advance });
        }
    }, [advance]);

    const { date, amount } = formData;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');  // Clear any existing errors

        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/editadvance/${advance.id}`, {
                date, 
                amount
            });
            console.log('Advance payment updated successfully:', response.data);

            onUpdate(); // Call onUpdate to refresh data in the parent component
            setTimeout(() => {
                onClose(); // Close modal after update
                window.location.reload(); // Optional, if needed to reload the page
            }, 1000); // 1 second delay
        } catch (error) {
            console.error('Error updating advance payment:', error);
            setError('Failed to update advance payment.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="modal fade show" role="dialog" style={{ display: "block" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit Advance Payment</h5>
                        <button type="button" className="close" onClick={onClose}>
                            <span>&times;</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="amount">Amount<span style={{ color: 'red' }}>*</span></label>
                                <input
                                    type="number"
                                    name="amount"
                                    className="form-control"
                                    placeholder="Enter amount"
                                    id="amount"
                                    value={amount}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="date">Payment Date<span style={{ color: 'red' }}>*</span></label>
                                <input
                                    type="date"
                                    name="date"
                                    className="form-control"
                                    id="date"
                                    value={date}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? 'Updating...' : 'Submit'}
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={onClose}>
                                Close
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditAdvancePayment;
