import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/sidebar/Sidebar';
import SearchBar from '../../components/sidebar/SearchBar';
import LeaveReportPreview from './LeaveReportPreview';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner


function LeaveReport({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Initialize with current month (+1 since months are 0-indexed)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year
    const [leaveData, setLeaveData] = useState([]); // State to hold leave data
    const [filteredLeaves, setFilteredLeaves] = useState([]); // State for filtered leave data
    const [grandTotals, setGrandTotals] = useState({}); // State for grand totals
    const [showSidebar, setShowSidebar] = useState(true); // State to control sidebar visibility
    const [showSearchBar, setShowSearchBar] = useState(true); // State to control search bar visibility
    const [leaveDetailsVisible, setLeaveDetailsVisible] = useState(false); // State for leave details modal
    const [selectedRecord, setSelectedRecord] = useState(null); // State for selected record

    useEffect(() => {
        fetchLeaveData();
    }, []);

    useEffect(() => {
        filterLeaves();
    }, [selectedMonth, selectedYear, leaveData]);

    useEffect(() => {
        calculateTotals();
    }, [filteredLeaves]);

    const fetchLeaveData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/leavereport`);
            const leaveData = response.data;
            setLeaveData(leaveData);
        } catch (error) {
            console.error('Error fetching leave data:', error);
            toast.error('Error fetching leave data');
        } finally {
            setIsLoading(false);
        }
    };

    const filterLeaves = () => {
        // Filter the leave data based on selected month and year
        const filtered = leaveData.filter(record => {
            const date = new Date(record.fromDate);
            return (
                date.getMonth() + 1 === selectedMonth && // +1 because JS months are 0-based
                date.getFullYear() === selectedYear
            );
        });
        setFilteredLeaves(filtered);
    };

    const calculateTotals = () => {
        const totals = filteredLeaves.reduce((acc, record) => {
            acc.totalEmployees += 1;
            // Add other totals if needed
            return acc;
        }, {
            totalEmployees: 0,
        });

        setGrandTotals(totals);
    };

    const handleLeaveDetails = () => {
        setSelectedRecord({
            date: new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
            status: filteredLeaves.length > 0 ? filteredLeaves[0].status : '',
            leaveData: { filteredLeaves },
            selectedMonth,
            selectedYear
        });

        setLeaveDetailsVisible(true);
        setShowSidebar(false);
        setShowSearchBar(false);
    };

    const handleClosePreview = () => {
        setShowSidebar(true); // Show sidebar when closing preview
        setShowSearchBar(true); // Show search bar when closing preview
        setLeaveDetailsVisible(false); // Hide leave details
    };

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            {showSidebar && <Sidebar />}
            <div className='w-100'>
                {showSearchBar && <SearchBar className="searchbarr" username={username} handleLogout={handleLogout} />}
                <div className="container-fluid">
                    <ToastContainer />
                    {leaveDetailsVisible ? (
                        <LeaveReportPreview
                            record={selectedRecord}
                            onClose={handleClosePreview}
                        />
                    ) : (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">Leave Report</h6>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <label className='pt-2 text-black fw-bolder'>Filter:</label>
                                            <select
                                                className="form-control"
                                                value={selectedMonth}
                                                onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Month</option>
                                                {Array.from({ length: 12 }, (_, i) => (
                                                    <option key={i} value={i + 1}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
                                                ))}
                                            </select>
                                            <select
                                                className="form-control"
                                                value={selectedYear}
                                                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Year</option>
                                                {Array.from({ length: 10 }, (_, i) => (
                                                    <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <button className="btn btn-outline-primary" onClick={handleLeaveDetails}><i className="fa fa-download"></i> PDF</button>
                                        </div>
                                    </div>
                                    <div className="card-body form-row">
                                        <div className='col-md-12' style={{ maxHeight: "450px", overflowY: "auto" }}>
                                            
                                                <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                    <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                        <tr>
                                                            <th>Month</th>
                                                            <th>Employee</th>
                                                            <th>Employee Code</th>
                                                            <th>Status</th>
                                                            <th>Period</th>
                                                            <th>Reason</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {/* Correct usage of spinner */}
                                                    <ThreeDots
                                                        color="#00BFFF"
                                                        height={80}
                                                        width={80}
                                                    />
                                                </div>
                                            ) : (filteredLeaves.length === 0 ? (
                                                            <tr>
                                                                <td colSpan="6" className="text-center">No Leave Details Found.</td>
                                                            </tr>
                                                        ) : (
                                                            filteredLeaves.map((record, index) => (
                                                                <tr key={index}>
                                                                    <td>{new Date(record.fromDate).toLocaleString('default', { month: 'long', year: 'numeric' })}</td>
                                                                    <td>{record.employeeName}</td>
                                                                    <td>{record.employeeCode}</td>
                                                                    <td>{record.status}</td>
                                                                    <td>{new Date(record.fromDate).toLocaleDateString()} - {new Date(record.toDate).toLocaleDateString()}</td>
                                                                    <td>{record.reason}</td>
                                                                    <td>{record.description}</td>
                                                                </tr>
                                                            ))
                                                        ))}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th>Totals</th>
                                                            <th>{grandTotals.totalEmployees}</th>
                                                            {/* Add other totals if needed */}
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default LeaveReport;
