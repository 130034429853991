import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/sidebar/Sidebar';
import SearchBar from '../../components/sidebar/SearchBar';
import LoanReportPreview from './LoanReportPreview';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner


function LoanReport({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Initialize with current month (+1 since months are 0-indexed)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year
    const [payrollData, setPayrollData] = useState([]); // State to hold payroll data
    const [filteredLoans, setFilteredLoans] = useState([]); // State for filtered loan data
    const [grandTotals, setGrandTotals] = useState({}); // State for grand totals
    const [showSidebar, setShowSidebar] = useState(true); // State to control sidebar visibility
    const [showSearchBar, setShowSearchBar] = useState(true); // State to control search bar visibility
    const [loanDetailsVisible, setLoanDetailsVisible] = useState(false); // State for loan details modal
    const [selectedRecord, setSelectedRecord] = useState(null); // State for selected record

    useEffect(() => {
        fetchPreviousPayroll();
    }, []);

    useEffect(() => {
        filterLoans();
    }, [selectedMonth, selectedYear, payrollData]);

    useEffect(() => {
        calculateTotals();
    }, [filteredLoans]);

    const fetchPreviousPayroll = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/loanreport`);
            const payrollData = response.data;
            setPayrollData(payrollData);
        } catch (error) {
            console.error('Error fetching payroll by employee:', error);
            toast.error('Error fetching payroll data');
        } finally {
            setIsLoading(false);
        }
    };

    const filterLoans = () => {
        // Filter the payroll data based on selected month and year
        const filtered = payrollData.filter(record => {
            const date = new Date(record.loanDate);
            return (
                date.getMonth() + 1 === selectedMonth && // +1 because JS months are 0-based
                date.getFullYear() === selectedYear
            );
        });
        setFilteredLoans(filtered);
    };

    const calculateTotals = () => {
        const totals = filteredLoans.reduce((acc, record) => {
            acc.totalEmployees += 1;
            acc.totalPrincipalAmount += record.principalAmount ? parseFloat(record.principalAmount) : 0;
            acc.totalInterestAmount += record.interestAmount ? parseFloat(record.interestAmount) : 0;
            acc.totalLoanAmount += record.loanAmount ? parseFloat(record.loanAmount) : 0;
            return acc;
        }, {
            totalEmployees: 0,
            totalPrincipalAmount: 0,
            totalInterestAmount: 0,
            totalLoanAmount: 0,
        });

        setGrandTotals(totals);
    };

    const handleLoanDetails = () => {
        setSelectedRecord({
            date: new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
            status: filteredLoans.length > 0 ? filteredLoans[0].status : '',
            loanData: { filteredLoans },
            selectedMonth,
            selectedYear
        });

        setLoanDetailsVisible(true);
        setShowSidebar(false);
        setShowSearchBar(false);
    };

    const handleClosePreview = () => {
        setShowSidebar(true); // Show sidebar when closing preview
        setShowSearchBar(true); // Show search bar when closing preview
        setLoanDetailsVisible(false); // Hide loan details
    };

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            {showSidebar && <Sidebar />}
            <div className='w-100'>
                {showSearchBar && <SearchBar className="searchbarr" username={username} handleLogout={handleLogout} />}
                <div className="container-fluid">
                    <ToastContainer />
                    {loanDetailsVisible ? (
                        <LoanReportPreview
                            record={selectedRecord}
                            onClose={handleClosePreview}
                        />
                    ) : (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">Loan Report</h6>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <label className='pt-2 text-black fw-bolder'>Filter:</label>
                                            <select
                                                className="form-control"
                                                value={selectedMonth}
                                                onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Month</option>
                                                {Array.from({ length: 12 }, (_, i) => (
                                                    <option key={i} value={i + 1}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
                                                ))}
                                            </select>
                                            <select
                                                className="form-control"
                                                value={selectedYear}
                                                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Year</option>
                                                {Array.from({ length: 10 }, (_, i) => (
                                                    <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <button className="btn btn-outline-primary" onClick={handleLoanDetails}><i className="fa fa-download"></i> PDF</button>
                                        </div>
                                    </div>
                                    <div className="card-body form-row">
                                        <div className='col-md-12' style={{ maxHeight: "450px", overflowY: "auto" }}>

                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>Month</th>
                                                        <th>Employee</th>
                                                        <th>Employee Code</th>
                                                        <th>Loan Number</th>
                                                        <th>Principal Amount</th>
                                                        <th>Interest Amount</th>
                                                        <th>Loan Amount</th>
                                                        <th>Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isLoading ? (
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            {/* Correct usage of spinner */}
                                                            <ThreeDots
                                                                color="#00BFFF"
                                                                height={80}
                                                                width={80}
                                                            />
                                                        </div>
                                                    ) : (filteredLoans.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="12" className="text-center">No Loan Details Found.</td>
                                                        </tr>
                                                    ) : (
                                                        filteredLoans.map((record, index) => (
                                                            <tr key={index}>
                                                                <td>{new Date(record.loanDate).toLocaleString('default', { month: 'long', year: 'numeric' })}</td>
                                                                <td>{record.employeeName}</td>
                                                                <td>{record.employeeCode}</td>
                                                                <td>{record.loanNumber}</td>
                                                                <td>&#x20B9;{record.principalAmount != null ? record.principalAmount : '0.00'}</td>
                                                                <td>&#x20B9;{record.interestAmount != null ? record.interestAmount : '0.00'}</td>
                                                                <td>&#x20B9;{record.loanAmount != null ? record.loanAmount : '0.00'}</td>
                                                                <td>{record.loanFor === "other" ? record.otherLoanForReason : record.loanFor}</td>
                                                            </tr>
                                                        ))
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Totals</th>
                                                        <th>{grandTotals.totalEmployees}</th>
                                                        <th></th>
                                                        <th>&#x20B9;{grandTotals.totalPrincipalAmount}</th>
                                                        <th>&#x20B9;{grandTotals.totalInterestAmount}</th>
                                                        <th>&#x20B9;{grandTotals.totalLoanAmount}</th>
                                                        <th></th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default LoanReport;

