import React from 'react';
import myImage from '../../images/employee_profile.png';

const AttendancePreview = ({
    employeeId, name, attendanceData, totalPresent, totalAbsent, totalHalfDay, totalPaidLeave, totalUnpaidLeave, totalOvertime, totalWeeklyOff, onClose }) => {
    console.log(employeeId)
    return (
        <div style={{ position: "absolute" }} className="container">
            <div className="row">
                <div className="col">
                    <h2 className="text-center mb-4">Attendance Preview</h2>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{name}</h5>
                            <p className="card-text">Employee Attendance Details</p>
                            <hr />
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Photo</th>
                                            <th>Name</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {attendanceData.map(record => (
                                            <tr key={record.id}>
                                                <td>
                                                    {/* <img
                                                        src={`${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${record.passportSizePhoto}`}
                                                        style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                                                    /> */}
                                                    <img
                                                        src={record.passportSizePhoto
                                                            ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${record.passportSizePhoto}`
                                                            : myImage}
                                                        style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                                                    />
                                                </td>
                                                <td>{record.employeeName}</td>
                                                <td>{new Date(record.date).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</td>
                                                <td>{record.status}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button className="btn btn-primary me-2" onClick={() => window.print()}>
                                Print
                            </button>
                            <button className="btn btn-secondary" onClick={onClose}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AttendancePreview;
