import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/sidebar/Sidebar';
import SearchBar from '../../components/sidebar/SearchBar';
import AdvanceReportPreview from './AdvanceReportPreview';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner

function AdvancePaymentReport({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Initialize with current month (+1 since months are 0-indexed)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year
    const [advanceData, setAdvanceData] = useState([]); // State to hold advance payment data
    const [filteredAdvances, setFilteredAdvances] = useState([]); // State for filtered advance data
    const [grandTotals, setGrandTotals] = useState({}); // State for grand totals
    const [showSidebar, setShowSidebar] = useState(true); // State to control sidebar visibility
    const [showSearchBar, setShowSearchBar] = useState(true); // State to control search bar visibility
    const [advanceDetailsVisible, setAdvanceDetailsVisible] = useState(false); // State for advance details modal
    const [selectedRecord, setSelectedRecord] = useState(null); // State for selected record

    useEffect(() => {
        fetchAdvancePayments();
    }, []);

    useEffect(() => {
        filterAdvances();
    }, [selectedMonth, selectedYear, advanceData]);

    useEffect(() => {
        calculateTotals();
    }, [filteredAdvances]);

    const fetchAdvancePayments = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/AdvancePayment`);
            const data = response.data;
            setAdvanceData(data);
        } catch (error) {
            console.error('Error fetching advance payments:', error);
            toast.error('Error fetching advance payments');
        }finally {
            setIsLoading(false);
        }
    };

    const filterAdvances = () => {
        // Filter the advance data based on selected month and year
        const filtered = advanceData.filter(record => {
            const date = new Date(record.date            );
            return (
                date.getMonth() + 1 === selectedMonth && // +1 because JS months are 0-based
                date.getFullYear() === selectedYear
            );
        });
        setFilteredAdvances(filtered);
    };

    const calculateTotals = () => {
        const totals = filteredAdvances.reduce((acc, record) => {
            acc.totalEmployees += 1;
            acc.totalAmount += record.amount ? parseFloat(record.amount) : 0;
            
            return acc;
        }, {
            totalEmployees: 0,
            totalAmount: 0,
        });

        setGrandTotals(totals);
    };

    const handleAdvanceDetails = () => {
        setSelectedRecord({
            date: new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
            status: filteredAdvances.length > 0 ? filteredAdvances[0].status : '',
            advanceData: filteredAdvances,
            selectedMonth,
            selectedYear
        });

        setAdvanceDetailsVisible(true);
        setShowSidebar(false);
        setShowSearchBar(false);
    };

    const handleClosePreview = () => {
        setShowSidebar(true); // Show sidebar when closing preview
        setShowSearchBar(true); // Show search bar when closing preview
        setAdvanceDetailsVisible(false); // Hide advance details
    };

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            {showSidebar && <Sidebar />}
            <div className='w-100'>
                {showSearchBar && <SearchBar className="searchbarr" username={username} handleLogout={handleLogout} />}
                <div className="container-fluid">
                    <ToastContainer />
                    {advanceDetailsVisible ? (
                        <AdvanceReportPreview
                            record={selectedRecord}
                            onClose={handleClosePreview}
                        />
                    ) : (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">Advance Payment Report</h6>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <label className='pt-2 text-black fw-bolder'>Filter:</label>
                                            <select
                                                className="form-control"
                                                value={selectedMonth}
                                                onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Month</option>
                                                {Array.from({ length: 12 }, (_, i) => (
                                                    <option key={i} value={i + 1}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
                                                ))}
                                            </select>
                                            <select
                                                className="form-control"
                                                value={selectedYear}
                                                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Year</option>
                                                {Array.from({ length: 10 }, (_, i) => (
                                                    <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <button className="btn btn-outline-primary" onClick={handleAdvanceDetails}><i className="fa fa-download"></i> PDF</button>
                                        </div>
                                    </div>
                                    <div className="card-body form-row">
                                        <div className='col-md-12' style={{ maxHeight: "450px", overflowY: "auto" }}>
                                        
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>Month</th>
                                                        <th>Employee</th>
                                                        <th>Employee code</th>
                                                        <th>Advance Amt.</th>
                                                        <th>Payment Mode</th>
                                                        <th>Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {/* Correct usage of spinner */}
                                                    <ThreeDots
                                                        color="#00BFFF"
                                                        height={80}
                                                        width={80}
                                                    />
                                                </div>
                                            ) : (filteredAdvances.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="12" className="text-center">No Advance Details Found.</td>
                                                        </tr>
                                                    ) : (
                                                        filteredAdvances.map((record, index) => (
                                                            <tr key={index}>
                                                                <td>{new Date(record.date).toLocaleString('default', { month: 'long', year: 'numeric' })}</td>
                                                                <td>{record.employeeName ||  "N/A"}</td>
                                                                <td>{record.employeeCode ||  "N/A"}</td>
                                                                <td className='text-end'>&#x20B9;{record.amount != null ? record.amount : '0.00'}</td>
                                                                <td>{record.paymentModeName || "N/A"}</td>
                                                                <td>{record.description || "N/A"}</td>
                                                            </tr>
                                                        ))
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Totals</th>
                                                        <th>{grandTotals.totalEmployees}</th>
                                                        <th></th>
                                                        <th className='text-end'>&#x20B9;{grandTotals.totalAmount}</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AdvancePaymentReport;
