// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import SearchBar from '../../components/sidebar/SearchBar';
// import Sidebar from '../../components/sidebar/Sidebar';
// import { useNavigate, Link, useLocation } from 'react-router-dom';

// const EditSalarySlip = ({ username, handleLogout }) => {
//     const navigate = useNavigate();
//     const location = useLocation();

//     const { salarydata } = location.state || {};
//     console.log("salary data", salarydata);

//     const [attendanceRecords, setAttendanceRecords] = useState([]);
//     const [filteredAttendance, setFilteredAttendance] = useState([]);
//     const [totalPresent, setTotalPresent] = useState(0);
//     const [totalAbsent, setTotalAbsent] = useState(0);
//     const [totalHalfDay, setTotalHalfDay] = useState(0);
//     const [totalPaidLeave, setTotalPaidLeave] = useState(0);
//     const [totalUnpaidLeave, setTotalUnpaidLeave] = useState(0);
//     const [totalOvertime, setTotalOvertime] = useState(0);
//     const [totalWeeklyOff, setTotalWeeklyOff] = useState(0);
//     const [advanceBalances, setadvanceBalances] = useState([]);
//     const [totalAdvanceAmount, setTotalAdvanceAmount] = useState(0);
//     const [totalAttendance, setTotalAttendance] = useState(0);
//     // employeedetails 
//     const [employeeDetails, setEmployeeDetails] = useState(null);
//     const [basicSalaryMonth, setBasicSalaryMonth] = useState(0);
//     const [VDAmonth, setVDAmonth] = useState(0);
//     const [allowancesMonth, setAllowancesMonth] = useState(0);
//     const [conveyanceAllowancesMonth, setConveyanceAllowancesMonth] = useState(0);
//     const [specialallowancesMonth, setSpecialAllowancesMonth] = useState(0);
//     const [houseRentallowancesMonth, setHouseRentAllowancesMonth] = useState(0);
//     const [dearnessallowancesMonth, setDearnessAllowancesMonth] = useState(0);
//     const [grossSalaryMonth, setGrossSalaryMonth] = useState(0);
//     // epf esicEmployee 
//     const [totalEmployerContributionMonth, settotalEmployerContributionMonth] = useState(0)
//     const [totalEmployeeDeductionMonth, settotalEmployeeDeductionMonth] = useState(0)
//     const [totalTdsDeductionMonth, settotalTdsDeductionMonth] = useState(0)
//     const [totalPayableSalaryMonth, settotalPayableSalaryMonth] = useState(0)
//     const [totalInHandSalaryMonth, settotalInHandSalaryMonth] = useState(0)
//     const [grossPayableSalaryMonth, setGrossPayableSalaryMonth] = useState(0)
//     const [grossInHandSalaryMonth, setGrossInHandSalaryMonth] = useState(0)
//     const [netSalaryPayableMonth, setNetSalaryPayableMonth] = useState(0)
//     const [epfEmployerMonth, setEpfEmployerMonth] = useState(0);
//     const [epfEmployeeMonth, setEpfEmployeeMonth] = useState(0);
//     const [esicEmployeeMonth, setEsicEmployeeMonth] = useState(0);
//     const [esicEmployerMonth, setEsicEmployerMonth] = useState(0);
//     const [overtimeMonth, setovertimeMonth] = useState(0);
//     const [halfDayMonth, sethalfDayMonth] = useState(0);
//     const [epfesicApplicableMonth, setepfesicApplicableMonth] = useState(0)
//     const [tdsApplicableMonth, settdsApplicableMonth] = useState(salarydata?.tdsApplicableMonth || '');
//     const [tdsDeductionPercentage, setTdsDeductionPercentage] = useState(salarydata?.tdsDeductionPercentage || 0);

//     // New state variables for additional allowances and deductions
//     const [additionalAllowance, setAdditionalAllowance] = useState(salarydata?.additionalAllowance || 0);
//     const [allowanceDescription, setAllowanceDescription] = useState(salarydata?.allowanceDescription || '');
//     const [salaryDeduction, setSalaryDeduction] = useState(salarydata?.salaryDeduction || 0);
//     const [deductionDescription, setDeductionDescription] = useState(salarydata?.deductionDescription || '');
//     const [salaryAdvanceDeduction, setSalaryAdvanceDeduction] = useState(salarydata?.advanceDeduction || 0);
//     const [showAllowanceDescription, setShowAllowanceDescription] = useState(false);
//     const [showDeductionDescription, setShowDeductionDescription] = useState(false);

//     // Salary Details  
//     const [totalsalary, setTotalsalary] = useState(0)
//     const [salaryWithContribution, setSalaryWithContribution] = useState(0)
//     const [salaryAfterDeduction, setsalaryAfterDeduction] = useState(0)

//     // Total Attendance Check 
//     const [totalAttencance, setTotalAttencance] = useState(0);
//     const [daysAttendance, setDaysAttendance] = useState(false);
//     const [totalDays, setTotalDays] = useState(0); // State for total days in selected month
//     // Hr Manager 
//     const [hrManager, setHrManager] = useState([]);
//     useEffect(() => {
//         fetchHrManager();
//     }, []);


//     // Attendance 
//     useEffect(() => {
//         if (salarydata && salarydata.month !== undefined && salarydata.year !== undefined) {
//             fetchAttendanceRecords();
//             fetchEmployeeDetails();

//         }
//     }, [salarydata]);

//     useEffect(() => {
//         if (salarydata && salarydata.month !== undefined && salarydata.year !== undefined) {
//             filterAttendanceRecords(salarydata.month, salarydata.year);
//         }
//     }, [attendanceRecords, salarydata]);
//     // Attendance 
//     useEffect(() => {
//         calculateMonthlySalaries(employeeDetails);
//     }, [attendanceRecords, advanceBalances, filteredAttendance, salaryDeduction, additionalAllowance]);

//     useEffect(() => {
//         calculateMonthlySalaries(employeeDetails);
//     }, [salarydata.month, salarydata.year, attendanceRecords, advanceBalances, filteredAttendance, salaryDeduction, additionalAllowance, employeeDetails, tdsDeductionPercentage]);

//     useEffect(() => {
//         // Calculate total days in selected month and update state
//         const daysInMonth = new Date(salarydata.year, salarydata.month + 1, 0).getDate();
//         console.log("Total Attendance", totalAttencance)
//         setTotalDays(daysInMonth);
//     }, [salarydata.month, salarydata.year]);

//     const fetchEmployeeDetails = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${salarydata.employeeId}`);
//             setEmployeeDetails(response.data);
//             console.log('Employee details fetched:', response.data);
//         } catch (error) {
//             console.error('Error fetching employee details:', error);
//         }
//     };

//     const fetchHrManager = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/hrmanager`);
//             setHrManager(response.data);
//         } catch (error) {
//             console.error("Error fetching timelines:", error);
//         }
//     };

//     const fetchAttendanceRecords = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/attendance/${salarydata.employeeId}`);
//             const attendanceData = response.data || [];
//             setAttendanceRecords(attendanceData);
//             // Filter records immediately if month and year are available
//             if (salarydata.month !== undefined && salarydata.year !== undefined) {
//                 filterAttendanceRecords(salarydata.month, salarydata.year, attendanceData);
//             }
//         } catch (error) {
//             console.error('Error fetching attendance records:', error);
//         }
//     };

//     const filterAttendanceRecords = (month, year, data = attendanceRecords) => {
//         // Ensure month is zero-based for Date object
//         const monthZeroBased = month - 1;

//         const filteredRecords = data.filter(record => {
//             const recordDate = new Date(record.date);
//             return recordDate.getMonth() === monthZeroBased && recordDate.getFullYear() === year;
//         });

//         const presentCount = filteredRecords.filter(record =>
//             ['present'].includes(record.status.toLowerCase())
//         ).length;
//         const absentCount = filteredRecords.filter(record =>
//             ['absent'].includes(record.status.toLowerCase())
//         ).length;
//         const halfDayCount = filteredRecords.filter(record =>
//             ['half day'].includes(record.status.toLowerCase())
//         ).length;
//         const paidLeaveCount = filteredRecords.filter(record =>
//             ['paid leave'].includes(record.status.toLowerCase())
//         ).length;
//         const unpaidLeaveCount = filteredRecords.filter(record =>
//             ['unpaid leave'].includes(record.status.toLowerCase())
//         ).length;
//         const overtimeCount = filteredRecords.filter(record =>
//             ['overtime'].includes(record.status.toLowerCase())
//         ).length;
//         const weeklyOffCount = filteredRecords.filter(record =>
//             ['weekly off'].includes(record.status.toLowerCase())
//         ).length;

//         const totalAttendance = presentCount + absentCount + halfDayCount + paidLeaveCount + unpaidLeaveCount + overtimeCount + weeklyOffCount;
//         setTotalAttendance(totalAttendance);
//         setTotalPresent(presentCount);
//         setTotalAbsent(absentCount);
//         setTotalHalfDay(halfDayCount);
//         setTotalPaidLeave(paidLeaveCount);
//         setTotalUnpaidLeave(unpaidLeaveCount);
//         setTotalOvertime(overtimeCount);
//         setTotalWeeklyOff(weeklyOffCount);
//         setFilteredAttendance(filteredRecords);
//     }

//     const calculateMonthlySalaries = (employeeDetails) => {
//         if (employeeDetails && employeeDetails.length > 0) {
//             const selectedEmployeeObj = employeeDetails[0];
//             const basicSalary = selectedEmployeeObj.basicSalary || 0;
//             const VDA = selectedEmployeeObj.vda || 0;
//             const allowances = selectedEmployeeObj.allowances || 0;
//             const specialallowances = selectedEmployeeObj.specialallowances || 0;
//             const dearnessallowances = selectedEmployeeObj.dearnessallowances || 0;
//             const conveyanceallowances = selectedEmployeeObj.conveyanceallowances || 0;
//             const houserentallowances = selectedEmployeeObj.houserentallowances || 0;
//             const grossSalary = selectedEmployeeObj.grossSalary || 0;
//             const epfesicApplicable = selectedEmployeeObj.epfesicApplicable || false;
//             const tdsApplicable = selectedEmployeeObj.tdsApplicable || false;
//             const employeeName = selectedEmployeeObj.employeeName;
//             const departmentName = selectedEmployeeObj.departmentName;
//             console.log("totabss", totalAbsent)
//             console.log("totleave", totalPaidLeave)
//             console.log("cal", basicSalary, totalUnpaidLeave, totalAbsent)
//             console.log("employeeName", employeeName)
//             console.log("departmentName", departmentName)
//             // Calculate deductions
//             // const submissionofovertimehalfday = Math.floor((totalOvertime+totalHalfDay)/2);
//             const deductionBasic = basicSalary - (basicSalary / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);
//             const deductionVDA = VDA - (VDA / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);
//             const deductionHouseRentAllowances = houserentallowances - (houserentallowances / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);
//             const deductionConveyanceAllowances = conveyanceallowances - (conveyanceallowances / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);
//             const deductionDearnessAllowances = dearnessallowances - (dearnessallowances / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);
//             const deductionSpecialAllowances = specialallowances - (specialallowances / 30) * (totalUnpaidLeave + totalAbsent + totalHalfDay);

//             // salary 
//             const grossSalaryMonth = deductionBasic + deductionVDA + deductionHouseRentAllowances + deductionConveyanceAllowances + deductionDearnessAllowances + deductionSpecialAllowances;
//             // overtime  
//             const deductionovertime = ((grossSalary / 30) / 2) * totalOvertime;
//             // half day
//             const deductionhalfDay = ((grossSalary / 30) / 2) * totalHalfDay;
//             // Total Salary 
//             const totalsalary = (grossSalaryMonth + deductionovertime + deductionhalfDay)
//             console.log("---------------------------------------------------------")
//             console.log("dedbasic 1", deductionBasic)
//             console.log("gross slary 7", grossSalaryMonth)
//             console.log("overtime 8", deductionovertime)
//             console.log("halfDay 9", deductionhalfDay)

//             console.log("Total Salary 7+8+9 = 10 = ", totalsalary)


//             setTotalsalary(totalsalary);
//             setBasicSalaryMonth(deductionBasic);
//             setVDAmonth(deductionVDA);
//             // setAllowancesMonth(deductionAllowances);
//             setConveyanceAllowancesMonth(deductionConveyanceAllowances)
//             setSpecialAllowancesMonth(deductionSpecialAllowances)
//             setHouseRentAllowancesMonth(deductionHouseRentAllowances)
//             setDearnessAllowancesMonth(deductionDearnessAllowances)
//             setGrossSalaryMonth(grossSalaryMonth);

//             // usestate 
//             const parsedtdsDeductionPercentage = parseFloat(tdsDeductionPercentage) || 0; // Default to 0 if tdsDeductionPercentage is not a valid number

//             let epfEmployer = 0;
//             let epfEmployee = 0;
//             let esicEmployer = 0;
//             let esicEmployee = 0;
//             let tdsDeduction = 0;

//             if (epfesicApplicable) {
//                 // EPF Calculation
//                 const epfLimit = 15000;
//                 const epfBase = Math.min(deductionBasic, epfLimit);
//                 epfEmployer = (0.12 * epfBase).toFixed(2);
//                 epfEmployee = (0.12 * epfBase).toFixed(2);

//                 // ESIC Calculation
//                 const esicLimit = 21000;
//                 if (grossSalaryMonth <= esicLimit) {
//                     esicEmployer = (0.0325 * grossSalaryMonth).toFixed(2);
//                     esicEmployee = (0.0075 * grossSalaryMonth).toFixed(2);
//                 } else {
//                     esicEmployer = 0;
//                     esicEmployee = 0;
//                 }
//             }
//             console.log("tdsApplicable", tdsApplicable)
//             console.log("grossSalaryMonth", grossSalaryMonth)
//             console.log("parsedtdsDeductionPercentage", parsedtdsDeductionPercentage)

//             if (tdsApplicable) {
//                 tdsDeduction = ((grossSalaryMonth * parsedtdsDeductionPercentage) / 100).toFixed(2);
//             }

//             console.log("tdsDeduction", tdsDeduction)

//             const totalEmployerContribution = (parseFloat(epfEmployer) + parseFloat(esicEmployer)).toFixed(2);
//             const totalEmployeeDeduction = (parseFloat(epfEmployee) + parseFloat(esicEmployee)).toFixed(2);
//             const totalTdsDeduction = parseFloat(tdsDeduction).toFixed(2);
//             const grossPayableSalary = (grossSalaryMonth + deductionovertime + deductionhalfDay + parseFloat(totalEmployerContribution)).toFixed(2);
//             const grossInHandSalary = (deductionovertime + deductionhalfDay + grossSalaryMonth - parseFloat(totalEmployeeDeduction) - parseFloat(totalTdsDeduction)).toFixed(2);

//             // Parse the fixed numbers back to float for further calculations
//             const parsedGrossPayableSalary = parseFloat(grossPayableSalary);
//             const parsedGrossInHandSalary = parseFloat(grossInHandSalary);
//             const parsedAdditionalAllowance = parseFloat(additionalAllowance) || 0; // Default to 0 if additionalAllowance is not a valid number
//             const parsedSalaryDeduction = parseFloat(salaryDeduction) || 0; // Default to 0 if salaryDeduction is not a valid number

//             console.log("additionalAllowance", parsedAdditionalAllowance);
//             console.log("grosssalary", (parsedGrossPayableSalary + parsedAdditionalAllowance).toFixed(2));

//             // Salary Contribution 
//             const salarywithcontribution = (parseFloat(totalsalary) + parseFloat(totalEmployerContribution)).toFixed(2);
//             // Salary Deduction 
//             const salaryAfterDeduction = parseFloat((parseFloat(totalsalary) - parseFloat(totalEmployeeDeduction) - parseFloat(totalTdsDeduction)).toFixed(2));
//             // Gross In Hand Salary 
//             const totalPayableSalary = (parsedGrossPayableSalary + parsedAdditionalAllowance - parsedSalaryDeduction).toFixed(2);
//             // Here we parse salaryAfterDeduction back to a number before adding parsedAdditionalAllowance and subtracting parsedSalaryDeduction
//             const totalInHandSalary = (salaryAfterDeduction + parsedAdditionalAllowance - parsedSalaryDeduction).toFixed(2);
//             // Net Payable Salary 
//             const NetSalaryPayable = (parseFloat(totalInHandSalary) - parseFloat(salaryAdvanceDeduction)).toFixed(2);




//             console.log("totalPayableSalary", totalPayableSalary);
//             console.log("totalInHandSalary", totalInHandSalary);
//             console.log("totaladvance", totalAdvanceAmount);



//             // const NetSalaryPayable = (totalInHandSalary - parsedSalaryDeduction.toFixed(2));
//             console.log("NetSalaryPayable", NetSalaryPayable)

//             // salary with contribution 
//             setsalaryAfterDeduction(salaryAfterDeduction);
//             setSalaryWithContribution(salarywithcontribution);
//             setGrossSalaryMonth(grossSalaryMonth);
//             setBasicSalaryMonth(deductionBasic);
//             setVDAmonth(deductionVDA);
//             // setAllowancesMonth(deductionAllowances);
//             setGrossSalaryMonth(grossSalaryMonth);
//             setepfesicApplicableMonth(epfesicApplicable);
//             settdsApplicableMonth(tdsApplicableMonth);
//             setEpfEmployerMonth(epfEmployer);
//             setEpfEmployeeMonth(epfEmployee);
//             setEsicEmployeeMonth(esicEmployee);
//             setEsicEmployerMonth(esicEmployer);
//             settotalEmployerContributionMonth(totalEmployerContribution);
//             settotalEmployeeDeductionMonth(totalEmployeeDeduction);
//             settotalTdsDeductionMonth(totalTdsDeduction);
//             sethalfDayMonth(deductionhalfDay);
//             setovertimeMonth(deductionovertime);
//             setGrossPayableSalaryMonth(grossPayableSalary);
//             setGrossInHandSalaryMonth(grossInHandSalary);
//             settotalPayableSalaryMonth(totalPayableSalary);
//             settotalInHandSalaryMonth(totalInHandSalary);
//             setNetSalaryPayableMonth(NetSalaryPayable);
//         }
//     };

//     // For Add the allowances and salary Deduction 
//     const toggleAllowanceDescription = () => {
//         setShowAllowanceDescription(!showAllowanceDescription);
//         if (showDeductionDescription) {
//             setShowDeductionDescription(false);
//         }
//     };
//     const toggleDeductionDescription = () => {
//         setShowDeductionDescription(!showDeductionDescription);
//         if (showAllowanceDescription) {
//             setShowAllowanceDescription(false);
//         }
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         // Prepare the data to send
//         const updatedSalaryData = {
//             basicSalaryMonth,
//             VDAmonth,
//             allowancesMonth,
//             conveyanceAllowancesMonth,
//             houseRentallowancesMonth,
//             dearnessallowancesMonth,
//             specialallowancesMonth,
//             grossSalaryMonth,
//             epfesicApplicableMonth,
//             tdsApplicableMonth,
//             epfEmployerMonth,
//             epfEmployeeMonth,
//             esicEmployeeMonth,
//             esicEmployerMonth,
//             totalEmployerContributionMonth,
//             totalEmployeeDeductionMonth,
//             totalTdsDeductionMonth,
//             halfDayMonth,
//             overtimeMonth,
//             grossPayableSalaryMonth,
//             grossInHandSalaryMonth,
//             totalPayableSalaryMonth,
//             totalInHandSalaryMonth,
//             netSalaryPayableMonth,
//             deductionDescription,
//             allowanceDescription,
//             additionalAllowance,
//             salaryDeduction,
//             // slary details 
//             tdsDeductionPercentage,
//             salaryAfterDeduction,
//             salaryWithContribution,
//             totalsalary,
//         };

//         try {
//             // Make the PUT request
//             const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/api/salary/${salarydata.id}`, updatedSalaryData);
//             // Handle success
//             if (response.status === 200) {
//                 toast.success("Salary details updated successfully!");
//                 navigate("/salarylist")
//             } else {
//                 toast.error("Failed to update salary details. Please try again.");
//             }
//         } catch (error) {
//             console.error("Error updating salary details:", error);
//             toast.error("An error occurred while updating salary details. Please try again.");
//         }
//     };


//     return (
//         <div className='d-flex w-100 h-100 bg-white'>
//             <Sidebar />
//             <div className='w-100'>
//                 <SearchBar username={username} handleLogout={handleLogout} />
//                 <div className="container-fluid">
//                     <ToastContainer />
//                     <div className="row bg-dark bg-white p-2 rounded border">
//                         <div className="col-sm-12 col-md-12" >
//                             <div className="panel panel-bd">
//                                 <div className="panel-heading">
//                                     <div className="panel-title">
//                                         <h4 className='p-1 text-black text-capitalize m-0 fw-bolder'>Edit Salary Slip</h4>
//                                     </div>
//                                 </div>
//                                 <div className="panel-body">
//                                     <form onSubmit={handleSubmit} className="bg-white p-2 rounded " encType="multipart/form-data" acceptCharset="utf-8">
//                                         <div className="form-row">
//                                             <div className="form-group col-md-4">
//                                                 <label htmlFor="department" className="col-xs-3 col-form-label">Department<span style={{ color: "red" }}>*</span></label>
//                                                 <div className="col-xs-9">
//                                                     <input name="departmentName" type="text" className="form-control" value={salarydata.departmentName} />
//                                                 </div>
//                                             </div>
//                                             <div className="form-group col-md-4">
//                                                 <label htmlFor="employee" className="col-xs-3 col-form-label">Employee<span style={{ color: "red" }}>*</span></label>
//                                                 <div className="col-xs-9">
//                                                     <input name="employeeName" type="text" className="form-control" value={salarydata.employeeName} />
//                                                 </div>
//                                             </div>
//                                             <div className="form-group col-md-4">
//                                                 <label className="col-xs-3 text-black col-form-label">Filter :</label>
//                                                 <div className='col-xs-6 d-flex gap-2'>
//                                                     <input name="employeeName" type="text" className="form-control" value={salarydata.month} />
//                                                     <input name="employeeName" type="text" className="form-control" value={salarydata.year} />
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className=' p-2 bg-light shadow-sm  rounded mt-3'>
//                                             <div className='d-flex align-items-center justify-content-between p-2'>
//                                                 <h4 className='text-black fw-bolder '>Attendance/Salary Details</h4>
//                                                 {daysAttendance == true ? (
//                                                     <small className="m-0 font-weight-bold text-danger">Please fill the attendance first - {totalDays - totalAttencance}</small>
//                                                 ) : null}
//                                             </div>
//                                             <div className='rounded border bg-white' style={{ maxHeight: "calc(100vh - 120px)", overflowY: "auto", overflowX: "hidden" }}>
//                                                 <div className='d-flex'>
//                                                     <div className='col-md-6 p-2'>
//                                                         <h6 style={{ backgroundColor: "#00509d" }} className='text-white w-100 fw-bolder m-0 p-2'> Total Attendance Details :</h6>
//                                                         <table style={{ backgroundColor: "#00509d" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
//                                                             <tbody >
//                                                                 <div className='d-flex justify-content-between bg-danger'>
//                                                                     <div className='p-0 w-100 bg-dark'>
//                                                                         <table className="table table-striped table-bordered m-0">
//                                                                             <tbody>
//                                                                                 <tr>
//                                                                                     <td bgcolor="#f2f3f4" >
//                                                                                         <p className="mb-0 fw-bold text-success"> Present </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> {totalPresent || '0'} Days</p>
//                                                                                     </td>
//                                                                                 </tr>

//                                                                                 <tr>
//                                                                                     <td bgcolor="#f2f3f4" >
//                                                                                         <p className="mb-0 fw-bold text-success"> Over Time </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> {totalOvertime || '0'} Days</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                                 <tr>
//                                                                                     <td bgcolor="#f2f3f4" >
//                                                                                         <p className="mb-0 fw-bold text-success"> Weekly Off </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> {totalWeeklyOff || '0'} Days</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                                 <tr>
//                                                                                     <td bgcolor="#f2f3f4" >
//                                                                                         <p className="mb-0 fw-bold text-success"> Half Day </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> {totalHalfDay || '0'} Days</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                             </tbody>
//                                                                         </table>
//                                                                     </div>
//                                                                     <div className='p-0 w-100'>
//                                                                         <table className="table table-striped table-bordered">
//                                                                             <tbody>
//                                                                                 <tr>
//                                                                                     <td bgcolor="#f2f3f4" >
//                                                                                         <p className="mb-0 fw-bold text-success"> Paid Leave </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> {totalPaidLeave || '0'} Days</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                                 <tr>
//                                                                                     <td bgcolor="#f2f3f4" >
//                                                                                         <p className="mb-0 fw-bold text-danger"> Absent</p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> {totalAbsent || '0'} Days</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                                 <tr>
//                                                                                     <td bgcolor="#f2f3f4" >
//                                                                                         <p className="mb-0 fw-bold text-danger"> UnPaid Leave </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> {totalUnpaidLeave || '0'} Days</p>
//                                                                                     </td>
//                                                                                 </tr>

//                                                                                 <tr>
//                                                                                     <td bgcolor="#f2f3f4">
//                                                                                         <p className="mb-0 fw-bold text-black"> Total Days </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> {totalAttendance || '0'} Days</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                             </tbody>
//                                                                         </table>
//                                                                     </div>
//                                                                 </div>
//                                                             </tbody>
//                                                         </table>
//                                                         <h6 style={{ backgroundColor: "#00509d" }} className='text-white w-100 fw-bolder m-0 p-2'> Salary Details :</h6>
//                                                         <table style={{ backgroundColor: "#00509d" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
//                                                             <tbody >
//                                                                 <div className='d-flex justify-content-between bg-danger'>
//                                                                     <div className='p-0 w-100'>
//                                                                         <table className="table table-striped table-bordered ">
//                                                                             <tbody>
//                                                                                 <tr>
//                                                                                     <td>
//                                                                                         <p className="mb-0 fw-bold text-black">1. Basic </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0">&#x20B9;{basicSalaryMonth.toFixed(2) || '0'}</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                                 <tr>
//                                                                                     <td>
//                                                                                         <p className="mb-0  text-black" style={{ cursor: "pointer" }} title="Variable Dearness Allowance">3. VDA </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0">&#x20B9;{VDAmonth.toFixed(2) || '0'}</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                                 <tr>
//                                                                                     <td>
//                                                                                         <p className="mb-0  text-black" style={{ cursor: "pointer" }} title="House Rent Allowance">5. HRA </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0">&#x20B9;{houseRentallowancesMonth.toFixed(2) || '0'}</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                             </tbody>
//                                                                         </table>
//                                                                         <tr >
//                                                                             <td>
//                                                                                 <p className="mb-0 fw-bolder font-bold">7. Gross Salary </p>
//                                                                             </td>
//                                                                         </tr>

//                                                                     </div>
//                                                                     <div className='p-0 w-100'>
//                                                                         <table className="table table-striped table-bordered">
//                                                                             <tbody>
//                                                                                 <tr>
//                                                                                     <td>
//                                                                                         <p className="mb-0  text-black" style={{ cursor: "pointer" }} title="Conveyance Allowance">2. CA </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0">&#x20B9;{conveyanceAllowancesMonth.toFixed(2) || '0'}</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                                 <tr>
//                                                                                     <td>
//                                                                                         <p className="mb-0  text-black" style={{ cursor: "pointer" }} title="Dearness Allowance">4. DA</p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0"> &#x20B9;{dearnessallowancesMonth.toFixed(2) || '0'}</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                                 <tr>
//                                                                                     <td>
//                                                                                         <p className="mb-0  text-black" style={{ cursor: "pointer" }} title="Special Allowance">6. SA </p>
//                                                                                     </td>
//                                                                                     <td>
//                                                                                         <p className="mb-0">&#x20B9;{specialallowancesMonth.toFixed(2) || '0'}</p>
//                                                                                     </td>
//                                                                                 </tr>
//                                                                             </tbody>
//                                                                         </table>
//                                                                         <tr>
//                                                                             <td className=''>
//                                                                                 <p className="mb-0 fw-bolder ">: &#x20B9;{grossSalaryMonth.toFixed(2) || '0'}</p>
//                                                                             </td>
//                                                                         </tr>
//                                                                     </div>
//                                                                 </div>
//                                                             </tbody>
//                                                         </table>
//                                                     </div>
//                                                     <div className='col-md-6 p-2'>
//                                                         <table style={{ backgroundColor: "#00509d" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
//                                                             <tbody >
//                                                                 <h6 className='text-white fw-bolder m-0 p-2'>EPF ESIC Contribution :</h6>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0">EPF - ESIC Applicable</p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <p className="mb-0">:
//                                                                             {epfesicApplicableMonth === 1 || epfesicApplicableMonth === true ? 'Yes' : (epfesicApplicableMonth === 0 || epfesicApplicableMonth === false ? 'No' : '0')}
//                                                                         </p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <h6 className='text-white fw-bolder m-0 p-2'> Employeer Contribution :</h6>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0">EPF 12% <span className="mb-0">: &#x20B9;{epfEmployerMonth || '0'}</span> </p>
//                                                                     </td>

//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0"> ESIC 3.25% <span className="mb-0">: &#x20B9;{esicEmployerMonth || '0'}</span> </p>
//                                                                     </td>

//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 fw-bolder">18. Total Employer Contribution </p>
//                                                                     </td>
//                                                                     <td className='bg-body-secondary'>
//                                                                         <p className="mb-0 fw-bolder">: &#x20B9;{totalEmployerContributionMonth || '0'}</p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <h6 className='text-white fw-bolder m-0 p-2'>Employee Contribution :</h6>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0">EPF 12% <span className="mb-0">: &#x20B9;{epfEmployeeMonth || '0'}</span> </p>
//                                                                     </td>

//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0">ESIC 0.75% <span className="mb-0">: &#x20B9;{esicEmployeeMonth || '0'}</span></p>
//                                                                     </td>

//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 fw-bolder">19. Total Employee Deduction </p>
//                                                                     </td>
//                                                                     <td className='bg-body-secondary'>
//                                                                         <p className="mb-0 fw-bolder">: &#x20B9;{totalEmployeeDeductionMonth || '0'}</p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <h6 className='text-white fw-bolder m-0 p-2'>TDS :</h6>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="300">
//                                                                         <p className="mb-0">TDS Applicable</p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <div className="d-flex align-items-center justify-content-between gap-4">
//                                                                             <p className='m-0' >
//                                                                                 :{tdsApplicableMonth ? 'Yes' : 'No'}
//                                                                             </p>
//                                                                             {tdsApplicableMonth && (
//                                                                                 <input
//                                                                                     type="number"
//                                                                                     id="tdsDeductionPercentage"
//                                                                                     value={tdsDeductionPercentage}
//                                                                                     onChange={(e) => setTdsDeductionPercentage(parseFloat(e.target.value))}
//                                                                                     className="form-control"
//                                                                                     placeholder='TDS %'
//                                                                                     required
//                                                                                 />
//                                                                             )}
//                                                                         </div>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td className='bg-body-secondary' bgcolor="#f2f3f4" width="300">
//                                                                         <p className="mb-0 fw-bolder">20. Total TDS Deduction</p>
//                                                                     </td>
//                                                                     <td className='bg-body-secondary'>
//                                                                         <p className="mb-0 fw-bolder">: &#x20B9;{totalTdsDeductionMonth}</p>
//                                                                     </td>
//                                                                 </tr>
//                                                             </tbody>
//                                                         </table>
//                                                     </div>
//                                                 </div>
//                                                 <hr />
//                                                 <div className='d-flex'>
//                                                     <div className='col-md-6 p-2'>
//                                                         <table style={{ backgroundColor: "#00509d" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
//                                                             <tbody >
//                                                                 {/* <h6 className='text-white fw-bolder m-0 p-2'>Overtime/Half Day :</h6> */}

//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 font-bold">8. Over Time </p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <p className="mb-0">: &#x20B9;{overtimeMonth.toFixed(2) || '0'}</p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 font-bold">9. Half Day </p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <p className="mb-0">: &#x20B9;{halfDayMonth.toFixed(2) || '0'}</p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 {/* <h6 className='text-white fw-bolder m-0 p-2'>Total Salary :</h6> */}
//                                                                 {/* <h6 className='text-white fw-bolder m-0 p-2'>Total Salary :</h6> */}
//                                                                 <tr>
//                                                                     <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 font-bold">10. Total Salary </p>
//                                                                     </td>
//                                                                     <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 ">: &#x20B9;{totalsalary != null ? totalsalary.toFixed(2) : '0.00'}  </p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 font-bold">11. Salary With Contribution </p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <p className="mb-0">: &#x20B9;{salaryWithContribution != null ? salaryWithContribution : '0.00'} </p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 font-bold">12. Salary After Deduction </p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <p className="mb-0">: &#x20B9;{salaryAfterDeduction != null ? salaryAfterDeduction : '0.00'} </p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <label htmlFor="additionalAllowance">Additional Allowance:</label>
//                                                                     </td>
//                                                                     <td>
//                                                                         <div className='d-flex gap-2'>
//                                                                             <input
//                                                                                 type="number"
//                                                                                 id="additionalAllowance"
//                                                                                 value={additionalAllowance}
//                                                                                 onChange={(e) => setAdditionalAllowance(e.target.value)}
//                                                                                 className="form-control"
//                                                                                 placeholder='Additional Allowance'
//                                                                                 required
//                                                                             />
//                                                                             <button type="button" onClick={() => setShowAllowanceDescription(!showAllowanceDescription)} className="btn btn-success">
//                                                                                 <i className="fa fa-plus" aria-hidden="true"></i>
//                                                                             </button>
//                                                                         </div>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td colSpan="2" className='p-0'>
//                                                                         {showAllowanceDescription && (
//                                                                             <textarea
//                                                                                 id="allowanceDescription"
//                                                                                 value={allowanceDescription}
//                                                                                 onChange={(e) => setAllowanceDescription(e.target.value)}
//                                                                                 className="form-control"
//                                                                                 placeholder='Additional Allowance Description'
//                                                                             />
//                                                                         )}
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <label htmlFor="salaryDeduction">Additional Deduction:</label>
//                                                                     </td>
//                                                                     <td>
//                                                                         <div className='d-flex gap-2'>
//                                                                             <input
//                                                                                 type="number"
//                                                                                 id="salaryDeduction"
//                                                                                 value={salaryDeduction}
//                                                                                 onChange={(e) => setSalaryDeduction(e.target.value)}
//                                                                                 className="form-control"
//                                                                                 placeholder='Salary Deduction'
//                                                                                 required
//                                                                             />
//                                                                             <button type="button" onClick={() => setShowDeductionDescription(!showDeductionDescription)} className="btn btn-success">
//                                                                                 <i className="fa fa-plus" aria-hidden="true"></i>
//                                                                             </button>
//                                                                         </div>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td colSpan="2" className='p-0'>
//                                                                         {showDeductionDescription && (
//                                                                             <textarea
//                                                                                 id="deductionDescription"
//                                                                                 value={deductionDescription}
//                                                                                 onChange={(e) => setDeductionDescription(e.target.value)}
//                                                                                 className="form-control"
//                                                                                 placeholder='Additional Deduction Description'
//                                                                             />
//                                                                         )}
//                                                                     </td>
//                                                                 </tr>
//                                                                 {/* <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 font-bold">Total Payable Salary </p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <p className="mb-0 ">: &#x20B9;{totalPayableSalaryMonth || '0'} </p>
//                                                                     </td>
//                                                                 </tr> */}
//                                                                 <tr >
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 font-bold">15. Gross in Hand Salary </p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <p className="mb-0">: &#x20B9;{totalInHandSalaryMonth != null ? totalInHandSalaryMonth : '0.00'} </p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 font-bold">Total Advance Amt. </p>
//                                                                     </td>
//                                                                     <td>
//                                                                         <p className="mb-0">: &#x20B9;{salarydata.totalAdvanceAmount.toFixed(2) || '0'}</p>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td bgcolor="#f2f3f4" width="200">
//                                                                         <label htmlFor="advanceDeduction">16. Advance Deduction:</label>
//                                                                     </td>
//                                                                     <td>
//                                                                         <div className='d-flex gap-2'>
//                                                                             <input
//                                                                                 type="number"
//                                                                                 id="advanceDeduction"
//                                                                                 value={salarydata.advanceDeduction}
//                                                                                 className="form-control"
//                                                                                 placeholder='Advance Deduction'
//                                                                                 required
//                                                                                 readOnly
//                                                                             />
//                                                                         </div>
//                                                                     </td>
//                                                                 </tr>
//                                                                 <tr>
//                                                                     <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
//                                                                         <p className="mb-0 fw-bolder  font-bold">17. Net Salary Payable </p>
//                                                                     </td>
//                                                                     <td className='bg-body-secondary'>
//                                                                         <p className="mb-0 fw-bolder">: &#x20B9;{netSalaryPayableMonth || '0'} </p>
//                                                                     </td>
//                                                                 </tr>
//                                                             </tbody>
//                                                         </table>
//                                                     </div>
//                                                     <div className='col-md-6 p-2 '>
//                                                         <div className='bg-light  p-3'>
//                                                             <div className='d-flex w-100 align-align-items-center justify-content-around '>
//                                                                 <div className='shadow-sm w-50 bg-white border rounded p-1 m-1 d-flex align-items-center justify-content-center flex-column'>
//                                                                     <h5 className='text-dark m-0 fw-bolder'>Salary With Contribution</h5>
//                                                                     <small className='text-center border-bottom'>(10-19-20)</small>
//                                                                     <h1 style={{ fontSize: "30px" }} className='font-bold text-center text-black fw-bold text-primary'> &#x20B9;{salaryWithContribution || '0'}</h1>
//                                                                 </div>
//                                                                 <div className='shadow-sm w-50 bg-white border rounded p-1 m-1 d-flex align-items-center justify-content-center flex-column'>
//                                                                     <h5 className='text-dark m-0 fw-bolder'>Salary After Deduction</h5>
//                                                                     <small className='text-center border-bottom'>(12+13-14)</small>
//                                                                     <h1 style={{ fontSize: "30px" }} className='font-bold text-center text-black fw-bold text-primary'> &#x20B9;{salaryAfterDeduction || '0'}</h1>
//                                                                 </div>

//                                                             </div>
//                                                             <div className='d-flex rounded w-100 p-1 align-items-center justify-content-center flex-column'>
//                                                                 <div className='shadow-sm w-100 bg-white border rounded p-1 m-1 d-flex align-items-center justify-content-center flex-column'>
//                                                                     <h5 className='text-dark m-0 fw-bolder'>Net Payable Salary</h5>
//                                                                     <small className='text-center border-bottom'>(15-16)</small>
//                                                                     <h1 style={{ fontSize: "30px" }} className='font-bold text-center text-black fw-bold text-primary'> &#x20B9;{netSalaryPayableMonth || '0'}</h1>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                         <small className=' fw-bold'>HR Manager - {hrManager.employeeName}</small>
//                                                     </div>

//                                                 </div>


//                                             </div>
//                                         </div>
//                                         <div className="form-group mt-2 d-flex mt-2 gap-1">
//                                             <button type="submit" className="btn btn-primary ">Submit</button>
//                                             <Link to="/salarylist" className="btn btn-success "> Back </Link>
//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default EditSalarySlip;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchBar from '../../components/sidebar/SearchBar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useNavigate, Link, useLocation } from 'react-router-dom';

const EditSalarySlip = ({ username, handleLogout }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { salarydata } = location.state || {};
    console.log("salary data", salarydata);

    const [attendanceRecords, setAttendanceRecords] = useState([]);
    const [filteredAttendance, setFilteredAttendance] = useState([]);
    const [totalPresent, setTotalPresent] = useState(0);
    const [totalAbsent, setTotalAbsent] = useState(0);
    const [totalHalfDay, setTotalHalfDay] = useState(0);
    const [totalPaidLeave, setTotalPaidLeave] = useState(0);
    const [totalUnpaidLeave, setTotalUnpaidLeave] = useState(0);
    const [totalOvertime, setTotalOvertime] = useState(0);
    const [totalWeeklyOff, setTotalWeeklyOff] = useState(0);
    const [totalOvertimeHours, setTotalOvertimeHours] = useState(0);
    const [advanceBalances, setadvanceBalances] = useState([]);
    const [totalAdvanceAmount, setTotalAdvanceAmount] = useState(0);
    const [totalAttendance, setTotalAttendance] = useState(0);
    // employeedetails 
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [basicSalaryMonth, setBasicSalaryMonth] = useState(0);
    const [VDAmonth, setVDAmonth] = useState(0);
    const [allowancesMonth, setAllowancesMonth] = useState(0);
    const [conveyanceAllowancesMonth, setConveyanceAllowancesMonth] = useState(0);
    const [specialallowancesMonth, setSpecialAllowancesMonth] = useState(0);
    const [houseRentallowancesMonth, setHouseRentAllowancesMonth] = useState(0);
    const [dearnessallowancesMonth, setDearnessAllowancesMonth] = useState(0);
    const [grossSalaryMonth, setGrossSalaryMonth] = useState(0);
    // epf esicEmployee 
    const [totalEmployerContributionMonth, settotalEmployerContributionMonth] = useState(0)
    const [totalEmployeeDeductionMonth, settotalEmployeeDeductionMonth] = useState(0)
    const [totalTdsDeductionMonth, settotalTdsDeductionMonth] = useState(0)
    const [totalPayableSalaryMonth, settotalPayableSalaryMonth] = useState(0)
    const [totalInHandSalaryMonth, settotalInHandSalaryMonth] = useState(0)
    const [grossPayableSalaryMonth, setGrossPayableSalaryMonth] = useState(0)
    const [grossInHandSalaryMonth, setGrossInHandSalaryMonth] = useState(0)
    const [netSalaryPayableMonth, setNetSalaryPayableMonth] = useState(0)
    const [epfEmployerMonth, setEpfEmployerMonth] = useState(0);
    const [epfEmployeeMonth, setEpfEmployeeMonth] = useState(0);
    const [esicEmployeeMonth, setEsicEmployeeMonth] = useState(0);
    const [esicEmployerMonth, setEsicEmployerMonth] = useState(0);
    const [overtimeMonth, setovertimeMonth] = useState(0);
    const [halfDayMonth, sethalfDayMonth] = useState(0);
    const [epfesicApplicableMonth, setepfesicApplicableMonth] = useState(0)
    const [tdsApplicableMonth, settdsApplicableMonth] = useState(salarydata?.tdsApplicableMonth || '');
    const [tdsDeductionPercentage, setTdsDeductionPercentage] = useState(salarydata?.tdsDeductionPercentage || 0);

    // New state variables for additional allowances and deductions
    const [additionalAllowance, setAdditionalAllowance] = useState(salarydata?.additionalAllowance || 0);
    const [allowanceDescription, setAllowanceDescription] = useState(salarydata?.allowanceDescription || '');
    const [salaryDeduction, setSalaryDeduction] = useState(salarydata?.salaryDeduction || 0);
    const [deductionDescription, setDeductionDescription] = useState(salarydata?.deductionDescription || '');
    const [salaryAdvanceDeduction, setSalaryAdvanceDeduction] = useState(salarydata?.advanceDeduction || 0);
    const [showAllowanceDescription, setShowAllowanceDescription] = useState(false);
    const [showDeductionDescription, setShowDeductionDescription] = useState(false);

    // Salary Details  
    const [totalsalary, setTotalsalary] = useState(0)
    const [salaryWithContribution, setSalaryWithContribution] = useState(0)
    const [salaryAfterDeduction, setsalaryAfterDeduction] = useState(0)

    // Total Attendance Check 
    const [totalAttencance, setTotalAttencance] = useState(0);
    const [daysAttendance, setDaysAttendance] = useState(false);
    const [totalDays, setTotalDays] = useState(0); // State for total days in selected month
    // Hr Manager 
    const [hrManager, setHrManager] = useState([]);
    useEffect(() => {
        fetchHrManager();
    }, []);


    // Attendance 
    useEffect(() => {
        if (salarydata && salarydata.month !== undefined && salarydata.year !== undefined) {
            fetchAttendanceRecords();
            fetchEmployeeDetails();

        }
    }, [salarydata]);

    useEffect(() => {
        if (salarydata && salarydata.month !== undefined && salarydata.year !== undefined) {
            filterAttendanceRecords(salarydata.month, salarydata.year);
        }
    }, [attendanceRecords, salarydata]);
    // Attendance 
    useEffect(() => {
        calculateMonthlySalaries(employeeDetails);
    }, [attendanceRecords, advanceBalances, filteredAttendance, salaryDeduction, additionalAllowance]);

    useEffect(() => {
        calculateMonthlySalaries(employeeDetails);
    }, [salarydata.month, salarydata.year, attendanceRecords, advanceBalances, filteredAttendance, salaryDeduction, additionalAllowance, employeeDetails, tdsDeductionPercentage]);

    useEffect(() => {
        // Calculate total days in selected month and update state
        const daysInMonth = new Date(salarydata.year, salarydata.month + 1, 0).getDate();
        console.log("Total Attendance", totalAttencance)
        setTotalDays(daysInMonth);
    }, [salarydata.month, salarydata.year]);

    const fetchEmployeeDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${salarydata.employeeId}`);
            setEmployeeDetails(response.data);
            console.log('Employee details fetched:', response.data);
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const fetchHrManager = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/hrmanager`);
            setHrManager(response.data);
        } catch (error) {
            console.error("Error fetching timelines:", error);
        }
    };

    const fetchAttendanceRecords = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/attendance/${salarydata.employeeId}`);
            const attendanceData = response.data || [];
            setAttendanceRecords(attendanceData);
            // Filter records immediately if month and year are available
            if (salarydata.month !== undefined && salarydata.year !== undefined) {
                filterAttendanceRecords(salarydata.month, salarydata.year, attendanceData);
            }
        } catch (error) {
            console.error('Error fetching attendance records:', error);
        }
    };

    const filterAttendanceRecords = (month, year, data = attendanceRecords) => {
        // Ensure month is zero-based for Date object
        const monthZeroBased = month - 1;

        const filteredRecords = data.filter(record => {
            const recordDate = new Date(record.date);
            return recordDate.getMonth() === monthZeroBased && recordDate.getFullYear() === year;
        });

        const presentCount = filteredRecords.filter(record =>
            ['present'].includes(record.status.toLowerCase())
        ).length;
        const absentCount = filteredRecords.filter(record =>
            ['absent'].includes(record.status.toLowerCase())
        ).length;
        const halfDayCount = filteredRecords.filter(record =>
            ['half day'].includes(record.status.toLowerCase())
        ).length;
        const paidLeaveCount = filteredRecords.filter(record =>
            ['paid leave'].includes(record.status.toLowerCase())
        ).length;
        const unpaidLeaveCount = filteredRecords.filter(record =>
            ['unpaid leave'].includes(record.status.toLowerCase())
        ).length;
        const overtimeCount = filteredRecords.filter(record =>
            ['overtime'].includes(record.status.toLowerCase())
        ).length;
        const weeklyOffCount = filteredRecords.filter(record =>
            ['weekly off'].includes(record.status.toLowerCase())
        ).length;

        // Calculate total overtime hours
        const totalOvertimeHours = filteredRecords
            .filter(record => record.status.toLowerCase() === 'overtime')
            .reduce((sum, record) => sum + (parseFloat(record.overtimeHours) || 0), 0);



        const totalAttendance = presentCount + absentCount + halfDayCount + paidLeaveCount + unpaidLeaveCount + overtimeCount + weeklyOffCount;
        setTotalAttendance(totalAttendance);
        setTotalPresent(presentCount);
        setTotalAbsent(absentCount);
        setTotalHalfDay(halfDayCount);
        setTotalPaidLeave(paidLeaveCount);
        setTotalUnpaidLeave(unpaidLeaveCount);
        setTotalOvertime(overtimeCount);
        setTotalWeeklyOff(weeklyOffCount);
        // Set state for overtime hours
        setTotalOvertimeHours(totalOvertimeHours);
        setFilteredAttendance(filteredRecords);
    }

    const calculateMonthlySalaries = (employeeDetails) => {
        if (employeeDetails && employeeDetails.length > 0) {
            const selectedEmployeeObj = employeeDetails[0];
            const basicSalary = selectedEmployeeObj.basicSalary || 0;
            const VDA = selectedEmployeeObj.vda || 0;
            const allowances = selectedEmployeeObj.allowances || 0;
            const specialallowances = selectedEmployeeObj.specialallowances || 0;
            const dearnessallowances = selectedEmployeeObj.dearnessallowances || 0;
            const conveyanceallowances = selectedEmployeeObj.conveyanceallowances || 0;
            const houserentallowances = selectedEmployeeObj.houserentallowances || 0;
            const grossSalary = selectedEmployeeObj.grossSalary || 0;
            const epfesicApplicable = selectedEmployeeObj.epfesicApplicable || false;
            const tdsApplicable = selectedEmployeeObj.tdsApplicable || false;
            const employeeName = selectedEmployeeObj.employeeName;
            const departmentName = selectedEmployeeObj.departmentName;
            console.log("totabss", totalAbsent)
            console.log("totleave", totalPaidLeave)
            console.log("cal", basicSalary, totalUnpaidLeave, totalAbsent)
            console.log("employeeName", employeeName)
            console.log("departmentName", departmentName)
            // Calculate deductions
            // const submissionofovertimehalfday = Math.floor((totalOvertime+totalHalfDay)/2);
            const workingDay = (totalPresent + totalPaidLeave + totalWeeklyOff + (totalHalfDay * 0.5) + totalOvertime + (totalOvertimeHours / 8));
            const deductionBasic = (workingDay / totalAttendance) * basicSalary;
            const deductionVDA = (workingDay / totalAttendance) * VDA;
            const deductionHouseRentAllowances = (workingDay / totalAttendance) * houserentallowances;
            const deductionConveyanceAllowances = (workingDay / totalAttendance) * conveyanceallowances;
            const deductionDearnessAllowances = (workingDay / totalAttendance) * dearnessallowances;
            const deductionSpecialAllowances = (workingDay / totalAttendance) * specialallowances;


            // salary 
            const grossSalaryMonth = deductionBasic + deductionVDA + deductionHouseRentAllowances + deductionConveyanceAllowances + deductionDearnessAllowances + deductionSpecialAllowances;
            // overtime  
            const deductionovertime = ((totalOvertimeHours / 8) / totalAttendance) * basicSalary;
            // half day
            const deductionhalfDay = ((totalHalfDay * 0.5) / totalAttendance) * basicSalary;
            // Total Salary 
            const totalsalary = (grossSalaryMonth)

            console.log("---------------------------------------------------------")
            console.log("dedbasic 1", deductionBasic)
            console.log("gross slary 7", grossSalaryMonth)
            console.log("overtime 8", deductionovertime)
            console.log("halfDay 9", deductionhalfDay)

            console.log("Total Salary 7+8+9 = 10 = ", totalsalary)


            setTotalsalary(totalsalary);
            setBasicSalaryMonth(deductionBasic);
            setVDAmonth(deductionVDA);
            // setAllowancesMonth(deductionAllowances);
            setConveyanceAllowancesMonth(deductionConveyanceAllowances)
            setSpecialAllowancesMonth(deductionSpecialAllowances)
            setHouseRentAllowancesMonth(deductionHouseRentAllowances)
            setDearnessAllowancesMonth(deductionDearnessAllowances)
            setGrossSalaryMonth(grossSalaryMonth);

            // usestate 
            const parsedtdsDeductionPercentage = parseFloat(tdsDeductionPercentage) || 0; // Default to 0 if tdsDeductionPercentage is not a valid number

            let epfEmployer = 0;
            let epfEmployee = 0;
            let esicEmployer = 0;
            let esicEmployee = 0;
            let tdsDeduction = 0;

            if (epfesicApplicable) {
                // EPF Calculation
                const epfLimit = 15000;
                const epfBase = Math.min(deductionBasic, epfLimit);
                epfEmployer = (0.12 * epfBase).toFixed(2);
                epfEmployee = (0.12 * epfBase).toFixed(2);

                // ESIC Calculation
                const esicLimit = 21000;
                if (grossSalaryMonth <= esicLimit) {
                    esicEmployer = (0.0325 * grossSalaryMonth).toFixed(2);
                    esicEmployee = (0.0075 * grossSalaryMonth).toFixed(2);
                } else {
                    esicEmployer = 0;
                    esicEmployee = 0;
                }
            }
            console.log("tdsApplicable", tdsApplicable)
            console.log("grossSalaryMonth", grossSalaryMonth)
            console.log("parsedtdsDeductionPercentage", parsedtdsDeductionPercentage)

            if (tdsApplicable) {
                tdsDeduction = ((grossSalaryMonth * parsedtdsDeductionPercentage) / 100).toFixed(2);
            }

            console.log("tdsDeduction", tdsDeduction)

            const totalEmployerContribution = (parseFloat(epfEmployer) + parseFloat(esicEmployer)).toFixed(2);
            const totalEmployeeDeduction = (parseFloat(epfEmployee) + parseFloat(esicEmployee)).toFixed(2);
            const totalTdsDeduction = parseFloat(tdsDeduction).toFixed(2);
            const grossPayableSalary = (grossSalaryMonth + deductionovertime + deductionhalfDay + parseFloat(totalEmployerContribution)).toFixed(2);
            const grossInHandSalary = (deductionovertime + deductionhalfDay + grossSalaryMonth - parseFloat(totalEmployeeDeduction) - parseFloat(totalTdsDeduction)).toFixed(2);

            // Parse the fixed numbers back to float for further calculations
            const parsedGrossPayableSalary = parseFloat(grossPayableSalary);
            const parsedGrossInHandSalary = parseFloat(grossInHandSalary);
            const parsedAdditionalAllowance = parseFloat(additionalAllowance) || 0; // Default to 0 if additionalAllowance is not a valid number
            const parsedSalaryDeduction = parseFloat(salaryDeduction) || 0; // Default to 0 if salaryDeduction is not a valid number

            console.log("additionalAllowance", parsedAdditionalAllowance);
            console.log("grosssalary", (parsedGrossPayableSalary + parsedAdditionalAllowance).toFixed(2));

            // Salary Contribution 
            const salarywithcontribution = (parseFloat(totalsalary) + parseFloat(totalEmployerContribution)).toFixed(2);
            // Salary Deduction 
            const salaryAfterDeduction = parseFloat((parseFloat(totalsalary) - parseFloat(totalEmployeeDeduction) - parseFloat(totalTdsDeduction)).toFixed(2));
            // Gross In Hand Salary 
            const totalPayableSalary = (parsedGrossPayableSalary + parsedAdditionalAllowance - parsedSalaryDeduction).toFixed(2);
            // Here we parse salaryAfterDeduction back to a number before adding parsedAdditionalAllowance and subtracting parsedSalaryDeduction
            const totalInHandSalary = (salaryAfterDeduction + parsedAdditionalAllowance - parsedSalaryDeduction).toFixed(2);
            // Net Payable Salary 
            const NetSalaryPayable = (parseFloat(totalInHandSalary) - parseFloat(salaryAdvanceDeduction)).toFixed(2);




            console.log("totalPayableSalary", totalPayableSalary);
            console.log("totalInHandSalary", totalInHandSalary);
            console.log("totaladvance", totalAdvanceAmount);



            // const NetSalaryPayable = (totalInHandSalary - parsedSalaryDeduction.toFixed(2));
            console.log("NetSalaryPayable", NetSalaryPayable)

            // salary with contribution 
            setsalaryAfterDeduction(salaryAfterDeduction);
            setSalaryWithContribution(salarywithcontribution);
            setGrossSalaryMonth(grossSalaryMonth);
            setBasicSalaryMonth(deductionBasic);
            setVDAmonth(deductionVDA);
            // setAllowancesMonth(deductionAllowances);
            setGrossSalaryMonth(grossSalaryMonth);
            setepfesicApplicableMonth(epfesicApplicable);
            settdsApplicableMonth(tdsApplicableMonth);
            setEpfEmployerMonth(epfEmployer);
            setEpfEmployeeMonth(epfEmployee);
            setEsicEmployeeMonth(esicEmployee);
            setEsicEmployerMonth(esicEmployer);
            settotalEmployerContributionMonth(totalEmployerContribution);
            settotalEmployeeDeductionMonth(totalEmployeeDeduction);
            settotalTdsDeductionMonth(totalTdsDeduction);
            sethalfDayMonth(deductionhalfDay);
            setovertimeMonth(deductionovertime);
            setGrossPayableSalaryMonth(grossPayableSalary);
            setGrossInHandSalaryMonth(grossInHandSalary);
            settotalPayableSalaryMonth(totalPayableSalary);
            settotalInHandSalaryMonth(totalInHandSalary);
            setNetSalaryPayableMonth(NetSalaryPayable);
        }
    };

    // For Add the allowances and salary Deduction 
    const toggleAllowanceDescription = () => {
        setShowAllowanceDescription(!showAllowanceDescription);
        if (showDeductionDescription) {
            setShowDeductionDescription(false);
        }
    };
    const toggleDeductionDescription = () => {
        setShowDeductionDescription(!showDeductionDescription);
        if (showAllowanceDescription) {
            setShowAllowanceDescription(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare the data to send
        const updatedSalaryData = {
            basicSalaryMonth,
            VDAmonth,
            allowancesMonth,
            conveyanceAllowancesMonth,
            houseRentallowancesMonth,
            dearnessallowancesMonth,
            specialallowancesMonth,
            grossSalaryMonth,
            epfesicApplicableMonth,
            tdsApplicableMonth,
            epfEmployerMonth,
            epfEmployeeMonth,
            esicEmployeeMonth,
            esicEmployerMonth,
            totalEmployerContributionMonth,
            totalEmployeeDeductionMonth,
            totalTdsDeductionMonth,
            halfDayMonth,
            overtimeMonth,
            grossPayableSalaryMonth,
            grossInHandSalaryMonth,
            totalPayableSalaryMonth,
            totalInHandSalaryMonth,
            netSalaryPayableMonth,
            deductionDescription,
            allowanceDescription,
            additionalAllowance,
            salaryDeduction,
            // slary details 
            tdsDeductionPercentage,
            salaryAfterDeduction,
            salaryWithContribution,
            totalsalary,
        };

        try {
            // Make the PUT request
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/api/salary/${salarydata.id}`, updatedSalaryData);
            // Handle success
            if (response.status === 200) {
                toast.success("Salary details updated successfully!");
                navigate("/salarylist")
            } else {
                toast.error("Failed to update salary details. Please try again.");
            }
        } catch (error) {
            console.error("Error updating salary details:", error);
            toast.error("An error occurred while updating salary details. Please try again.");
        }
    };


    return (
        <div className='d-flex w-100 h-100 bg-white'>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    <div className="row bg-dark bg-white p-2 rounded border">
                        <div className="col-sm-12 col-md-12" >
                            <div className="panel panel-bd">
                                <div className="panel-heading">
                                    <div className="panel-title">
                                        <h4 className='p-1 text-black text-capitalize m-0 fw-bolder'>Edit Salary Slip</h4>
                                    </div>
                                </div>
                                <div className="panel-body">
                                    <form onSubmit={handleSubmit} className="bg-white p-2 rounded " encType="multipart/form-data" acceptCharset="utf-8">
                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <label htmlFor="department" className="col-xs-3 col-form-label">Department<span style={{ color: "red" }}>*</span></label>
                                                <div className="col-xs-9">
                                                    <input name="departmentName" type="text" className="form-control" value={salarydata.departmentName} />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="employee" className="col-xs-3 col-form-label">Employee<span style={{ color: "red" }}>*</span></label>
                                                <div className="col-xs-9">
                                                    <input name="employeeName" type="text" className="form-control" value={salarydata.employeeName} />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label className="col-xs-3 text-black col-form-label">Filter :</label>
                                                <div className='col-xs-6 d-flex gap-2'>
                                                    <input name="employeeName" type="text" className="form-control" value={salarydata.month} />
                                                    <input name="employeeName" type="text" className="form-control" value={salarydata.year} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className=' p-2 bg-light shadow-sm  rounded mt-3'>
                                            <div className='d-flex align-items-center justify-content-between p-2'>
                                                <h4 className='text-black fw-bolder '>Attendance/Salary Details</h4>
                                                {daysAttendance == true ? (
                                                    <small className="m-0 font-weight-bold text-danger">Please fill the attendance first - {totalDays - totalAttencance}</small>
                                                ) : null}
                                            </div>
                                            <div className='rounded border bg-white' style={{ maxHeight: "calc(100vh - 120px)", overflowY: "auto", overflowX: "hidden" }}>
                                                <div className='d-flex'>
                                                    <div className='col-md-6 p-2'>
                                                        <h6 style={{ backgroundColor: "#00509d" }} className='text-white w-100 fw-bolder m-0 p-2'> Total Attendance Details :</h6>
                                                        <table style={{ backgroundColor: "#00509d" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
                                                            <tbody >
                                                                <div className='d-flex justify-content-between bg-danger'>
                                                                    <div className='p-0 w-100 bg-dark'>
                                                                        <table className="table table-striped table-bordered m-0">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td bgcolor="#f2f3f4" >
                                                                                        <p className="mb-0 fw-bold text-success"> Present {totalDays} </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> {totalPresent || '0'} Days</p>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td bgcolor="#f2f3f4" >
                                                                                        <p className="mb-0 fw-bold text-success"> Over Time </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> {totalOvertime || '0'} Days</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td bgcolor="#f2f3f4" >
                                                                                        <p className="mb-0 fw-bold text-success"> Weekly Off </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> {totalWeeklyOff || '0'} Days</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td bgcolor="#f2f3f4" >
                                                                                        <p className="mb-0 fw-bold text-success"> Half Day </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> {totalHalfDay || '0'} Days</p>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className='p-0 w-100'>
                                                                        <table className="table table-striped table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td bgcolor="#f2f3f4" >
                                                                                        <p className="mb-0 fw-bold text-success"> Paid Leave </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> {totalPaidLeave || '0'} Days</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td bgcolor="#f2f3f4" >
                                                                                        <p className="mb-0 fw-bold text-danger"> Absent</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> {totalAbsent || '0'} Days</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td bgcolor="#f2f3f4" >
                                                                                        <p className="mb-0 fw-bold text-danger"> UnPaid Leave </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> {totalUnpaidLeave || '0'} Days</p>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td bgcolor="#f2f3f4">
                                                                                        <p className="mb-0 fw-bold text-black"> Total Days </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> {totalAttendance || '0'} Days</p>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </tbody>
                                                        </table>
                                                        <h6 style={{ backgroundColor: "#00509d" }} className='text-white w-100 fw-bolder m-0 p-2'> Salary Details :</h6>
                                                        <table style={{ backgroundColor: "#00509d" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
                                                            <tbody >
                                                                <div className='d-flex justify-content-between bg-danger'>
                                                                    <div className='p-0 w-100'>
                                                                        <table className="table table-striped table-bordered ">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        <p className="mb-0 fw-bold text-black">1. Basic </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0">&#x20B9;{basicSalaryMonth.toFixed(2) || '0'}</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <p className="mb-0  text-black" style={{ cursor: "pointer" }} title="Variable Dearness Allowance">3. VDA </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0">&#x20B9;{VDAmonth.toFixed(2) || '0'}</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <p className="mb-0  text-black" style={{ cursor: "pointer" }} title="House Rent Allowance">5. HRA </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0">&#x20B9;{houseRentallowancesMonth.toFixed(2) || '0'}</p>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <tr >
                                                                            <td>
                                                                                <p className="mb-0 fw-bolder font-bold">7. Gross Salary </p>
                                                                            </td>
                                                                        </tr>

                                                                    </div>
                                                                    <div className='p-0 w-100'>
                                                                        <table className="table table-striped table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        <p className="mb-0  text-black" style={{ cursor: "pointer" }} title="Conveyance Allowance">2. CA </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0">&#x20B9;{conveyanceAllowancesMonth.toFixed(2) || '0'}</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <p className="mb-0  text-black" style={{ cursor: "pointer" }} title="Dearness Allowance">4. DA</p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0"> &#x20B9;{dearnessallowancesMonth.toFixed(2) || '0'}</p>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <p className="mb-0  text-black" style={{ cursor: "pointer" }} title="Special Allowance">6. SA </p>
                                                                                    </td>
                                                                                    <td>
                                                                                        <p className="mb-0">&#x20B9;{specialallowancesMonth.toFixed(2) || '0'}</p>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <tr>
                                                                            <td className=''>
                                                                                <p className="mb-0 fw-bolder ">: &#x20B9;{grossSalaryMonth.toFixed(2) || '0'}</p>
                                                                            </td>
                                                                        </tr>
                                                                    </div>
                                                                </div>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='col-md-6 p-2'>
                                                        <table style={{ backgroundColor: "#00509d" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
                                                            <tbody >
                                                                <h6 className='text-white fw-bolder m-0 p-2'>EPF ESIC Contribution :</h6>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0">EPF - ESIC Applicable</p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="mb-0">:
                                                                            {epfesicApplicableMonth === 1 || epfesicApplicableMonth === true ? 'Yes' : (epfesicApplicableMonth === 0 || epfesicApplicableMonth === false ? 'No' : '0')}
                                                                        </p>
                                                                    </td>
                                                                </tr>
                                                                <h6 className='text-white fw-bolder m-0 p-2'> Employeer Contribution :</h6>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0">EPF 12% <span className="mb-0">: &#x20B9;{epfEmployerMonth || '0'}</span> </p>
                                                                    </td>

                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0"> ESIC 3.25% <span className="mb-0">: &#x20B9;{esicEmployerMonth || '0'}</span> </p>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 fw-bolder">18. Total Employer Contribution </p>
                                                                    </td>
                                                                    <td className='bg-body-secondary'>
                                                                        <p className="mb-0 fw-bolder">: &#x20B9;{totalEmployerContributionMonth || '0'}</p>
                                                                    </td>
                                                                </tr>
                                                                <h6 className='text-white fw-bolder m-0 p-2'>Employee Contribution :</h6>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0">EPF 12% <span className="mb-0">: &#x20B9;{epfEmployeeMonth || '0'}</span> </p>
                                                                    </td>

                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0">ESIC 0.75% <span className="mb-0">: &#x20B9;{esicEmployeeMonth || '0'}</span></p>
                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 fw-bolder">19. Total Employee Deduction </p>
                                                                    </td>
                                                                    <td className='bg-body-secondary'>
                                                                        <p className="mb-0 fw-bolder">: &#x20B9;{totalEmployeeDeductionMonth || '0'}</p>
                                                                    </td>
                                                                </tr>
                                                                <h6 className='text-white fw-bolder m-0 p-2'>TDS :</h6>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="300">
                                                                        <p className="mb-0">TDS Applicable</p>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center justify-content-between gap-4">
                                                                            <p className='m-0' >
                                                                                :{tdsApplicableMonth ? 'Yes' : 'No'}
                                                                            </p>
                                                                            {tdsApplicableMonth && (
                                                                                <input
                                                                                    type="number"
                                                                                    id="tdsDeductionPercentage"
                                                                                    value={tdsDeductionPercentage}
                                                                                    onChange={(e) => setTdsDeductionPercentage(parseFloat(e.target.value))}
                                                                                    className="form-control"
                                                                                    placeholder='TDS %'
                                                                                    required
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='bg-body-secondary' bgcolor="#f2f3f4" width="300">
                                                                        <p className="mb-0 fw-bolder">20. Total TDS Deduction</p>
                                                                    </td>
                                                                    <td className='bg-body-secondary'>
                                                                        <p className="mb-0 fw-bolder">: &#x20B9;{totalTdsDeductionMonth}</p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className='d-flex'>
                                                    <div className='col-md-6 p-2'>
                                                        <table style={{ backgroundColor: "#00509d" }} className="table table-hover border border-5" cellpadding="0" cellspacing="0">
                                                            <tbody >
                                                                {/* <h6 className='text-white fw-bolder m-0 p-2'>Overtime/Half Day :</h6> */}

                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 font-bold">8. Over Time Hours - {totalOvertimeHours}Hrs </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="mb-0">: &#x20B9;{overtimeMonth.toFixed(2) || '0'}</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 font-bold">9. Half Day </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="mb-0">: &#x20B9;{halfDayMonth.toFixed(2) || '0'}</p>
                                                                    </td>
                                                                </tr>
                                                                {/* <h6 className='text-white fw-bolder m-0 p-2'>Total Salary :</h6> */}
                                                                {/* <h6 className='text-white fw-bolder m-0 p-2'>Total Salary :</h6> */}
                                                                <tr>
                                                                    <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 font-bold">10. Total Salary </p>
                                                                    </td>
                                                                    <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 ">: &#x20B9;{totalsalary != null ? totalsalary.toFixed(2) : '0.00'}  </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 font-bold">11. Salary With Contribution </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="mb-0">: &#x20B9;{salaryWithContribution != null ? salaryWithContribution : '0.00'} </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 font-bold">12. Salary After Deduction </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="mb-0">: &#x20B9;{salaryAfterDeduction != null ? salaryAfterDeduction : '0.00'} </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <label htmlFor="additionalAllowance">Additional Allowance:</label>
                                                                    </td>
                                                                    <td>
                                                                        <div className='d-flex gap-2'>
                                                                            <input
                                                                                type="number"
                                                                                id="additionalAllowance"
                                                                                value={additionalAllowance}
                                                                                onChange={(e) => setAdditionalAllowance(e.target.value)}
                                                                                className="form-control"
                                                                                placeholder='Additional Allowance'
                                                                                required
                                                                            />
                                                                            <button type="button" onClick={() => setShowAllowanceDescription(!showAllowanceDescription)} className="btn btn-success">
                                                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="2" className='p-0'>
                                                                        {showAllowanceDescription && (
                                                                            <textarea
                                                                                id="allowanceDescription"
                                                                                value={allowanceDescription}
                                                                                onChange={(e) => setAllowanceDescription(e.target.value)}
                                                                                className="form-control"
                                                                                placeholder='Additional Allowance Description'
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <label htmlFor="salaryDeduction">Additional Deduction:</label>
                                                                    </td>
                                                                    <td>
                                                                        <div className='d-flex gap-2'>
                                                                            <input
                                                                                type="number"
                                                                                id="salaryDeduction"
                                                                                value={salaryDeduction}
                                                                                onChange={(e) => setSalaryDeduction(e.target.value)}
                                                                                className="form-control"
                                                                                placeholder='Salary Deduction'
                                                                                required
                                                                            />
                                                                            <button type="button" onClick={() => setShowDeductionDescription(!showDeductionDescription)} className="btn btn-success">
                                                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan="2" className='p-0'>
                                                                        {showDeductionDescription && (
                                                                            <textarea
                                                                                id="deductionDescription"
                                                                                value={deductionDescription}
                                                                                onChange={(e) => setDeductionDescription(e.target.value)}
                                                                                className="form-control"
                                                                                placeholder='Additional Deduction Description'
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                {/* <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 font-bold">Total Payable Salary </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="mb-0 ">: &#x20B9;{totalPayableSalaryMonth || '0'} </p>
                                                                    </td>
                                                                </tr> */}
                                                                <tr >
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 font-bold">15. Gross in Hand Salary </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="mb-0">: &#x20B9;{totalInHandSalaryMonth != null ? totalInHandSalaryMonth : '0.00'} </p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 font-bold">Total Advance Amt. </p>
                                                                    </td>
                                                                    <td>
                                                                        <p className="mb-0">: &#x20B9;{salarydata.totalAdvanceAmount.toFixed(2) || '0'}</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td bgcolor="#f2f3f4" width="200">
                                                                        <label htmlFor="advanceDeduction">16. Advance Deduction:</label>
                                                                    </td>
                                                                    <td>
                                                                        <div className='d-flex gap-2'>
                                                                            <input
                                                                                type="number"
                                                                                id="advanceDeduction"
                                                                                value={salarydata.advanceDeduction}
                                                                                className="form-control"
                                                                                placeholder='Advance Deduction'
                                                                                required
                                                                                readOnly
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className='bg-body-secondary' bgcolor="#f2f3f4" width="200">
                                                                        <p className="mb-0 fw-bolder  font-bold">17. Net Salary Payable </p>
                                                                    </td>
                                                                    <td className='bg-body-secondary'>
                                                                        <p className="mb-0 fw-bolder">: &#x20B9;{netSalaryPayableMonth || '0'} </p>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='col-md-6 p-2 '>
                                                        <div className='bg-light  p-3'>
                                                            <div className='d-flex w-100 align-align-items-center justify-content-around '>
                                                                <div className='shadow-sm w-50 bg-white border rounded p-1 m-1 d-flex align-items-center justify-content-center flex-column'>
                                                                    <h5 className='text-dark m-0 fw-bolder'>Salary With Contribution</h5>
                                                                    <small className='text-center border-bottom'>(10-19-20)</small>
                                                                    <h1 style={{ fontSize: "30px" }} className='font-bold text-center text-black fw-bold text-primary'> &#x20B9;{salaryWithContribution || '0'}</h1>
                                                                </div>
                                                                <div className='shadow-sm w-50 bg-white border rounded p-1 m-1 d-flex align-items-center justify-content-center flex-column'>
                                                                    <h5 className='text-dark m-0 fw-bolder'>Salary After Deduction</h5>
                                                                    <small className='text-center border-bottom'>(12+13-14)</small>
                                                                    <h1 style={{ fontSize: "30px" }} className='font-bold text-center text-black fw-bold text-primary'> &#x20B9;{salaryAfterDeduction || '0'}</h1>
                                                                </div>

                                                            </div>
                                                            <div className='d-flex rounded w-100 p-1 align-items-center justify-content-center flex-column'>
                                                                <div className='shadow-sm w-100 bg-white border rounded p-1 m-1 d-flex align-items-center justify-content-center flex-column'>
                                                                    <h5 className='text-dark m-0 fw-bolder'>Net Payable Salary</h5>
                                                                    <small className='text-center border-bottom'>(15-16)</small>
                                                                    <h1 style={{ fontSize: "30px" }} className='font-bold text-center text-black fw-bold text-primary'> &#x20B9;{netSalaryPayableMonth || '0'}</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <small className=' fw-bold'>HR Manager - {hrManager.employeeName}</small>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                        <div className="form-group mt-2 d-flex mt-2 gap-1">
                                            <button type="submit" className="btn btn-primary ">Submit</button>
                                            <Link to="/salarylist" className="btn btn-success "> Back </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditSalarySlip;




