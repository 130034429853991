import React, { useState, useEffect } from "react";
import axios from "axios";
// import TimelineDesc from "./TimelineDesc";
// import EditTimeline from "./EditTimeline";
import DeleteConfirmationModal from "../DeleteConfirmationModal"; // Import the new component
import SearchBar from "../../components/sidebar/SearchBar";
import Sidebar from "../../components/sidebar/Sidebar";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import AddTimelineForm from "./AddTimelineForm";
import AddHRManager from "./AddHRManager";
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner


function HRManagerTimLine({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [timelines, setTimelines] = useState([]);
    const [selectedTimeline, setSelectedTimeline] = useState(null);
    const [showTimelineDetails, setShowTimelineDetails] = useState(false);
    const [isAddTimelineModalOpen, setIsAddTimelineModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editTimeline, setEditTimeline] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [deleteTimeline, setDeleteTimeline] = useState(null); // State to store data of timeline being deleted
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State to manage delete confirmation modal
    const [deleteReason, setDeleteReason] = useState(""); // State to store deletion reason

    useEffect(() => {
        fetchTimelines();
    }, []);

    const fetchTimelines = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/timelines`);
            setTimelines(response.data);
        } catch (error) {
            console.error("Error fetching timelines:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddTimeline = () => {
        setIsAddTimelineModalOpen(true);
    };

    const handleCloseTimelineModal = () => {
        setIsAddTimelineModalOpen(false);
        setIsEditModalOpen(false);
    };

    const handleTimelineDetails = (timeline) => {
        setSelectedTimeline(timeline);
        setShowTimelineDetails(true);
    };

    const handleEditTimelineClick = (timeline) => {
        setEditTimeline(timeline);
        setSelectedTimeline(timeline); // Update selectedTimeline state
        setIsEditModalOpen(true);
    };

    const handleEditTimelineClose = () => {
        setSelectedTimeline(null);
    };

    const handleBackToTable = () => {
        setSelectedTimeline(null);
        setShowTimelineDetails(false);
    };

    const handleDeleteTimeline = (timeline) => {
        setDeleteTimeline(timeline);
        setIsDeleteModalOpen(true);
    };

    const handleUpdateTimeline = async (updatedTimeline) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/timelines/${updatedTimeline.id}`, updatedTimeline);
            console.log("Timeline updated:", response.data);
            const updatedTimelines = timelines.map(timeline => (timeline.id === updatedTimeline.id ? response.data : timeline));
            setTimelines(updatedTimelines);
        } catch (error) {
            console.error("Error updating timeline:", error);
        }
    };

    const handleDeleteConfirmation = async () => {
        try {
            // Perform deletion in the database
            await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/timelines/${deleteTimeline.id}`);

            // Save the deleted data to delete_details table
            const deletedTimeline = { ...deleteTimeline, reason: deleteReason };
            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/delete_details`, deletedTimeline);

            // Remove the deleted timeline from the UI
            setTimelines((prevTimelines) =>
                prevTimelines.filter((timeline) => timeline.id !== deleteTimeline.id)
            );
            // Close the delete modal
            setIsDeleteModalOpen(false);

            console.log("Timeline deleted successfully");
        } catch (error) {
            console.error("Error deleting timeline:", error);
        }
    };

    const handleUpdateTimelines = () => {
        toast.success("Successfully uploaded");
        fetchTimelines();
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = timelines.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    return (
        <div className='d-flex w-100 h-100 bg-white '>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} /> {/* Pass username and handleLogout props */}
                <div className="container-fluid">
                    <ToastContainer />
                    {!showTimelineDetails && (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                            HR Manager Timeline
                                        </h6>
                                        <div className="d-flex gap-2">
                                            {/* <button onClick={(e) => window.print()} className="btn btn-outline-success">
                                                <i className="fa fa-download"></i>
                                            </button> */}
                                            <div onClick={handleAddTimeline} className="btn btn-outline-primary">
                                                Add New Timeline Event
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body" style={{ height: "calc(100% - 40px)" }}                                    >
                                        <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {/* Correct usage of spinner */}
                                                    <ThreeDots
                                                        color="#00BFFF"
                                                        height={80}
                                                        width={80}
                                                    />
                                                </div>
                                            ) : (
                                                <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                    <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                        <tr>
                                                            <th>Employee Name </th>
                                                            <th>Employee Code</th>
                                                            <th>Appoint Date</th>
                                                            <th>Relieving Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <style>
                                                            {`.hyperlink:hover {color: blue;}`}
                                                        </style>
                                                        {currentItems.length === 0 ? (
                                                            <tr>
                                                                <td colSpan="12" className="text-center">Thier is No Company List.</td>
                                                            </tr>
                                                        ) : (currentItems.map((timeline, index) => (
                                                            <tr key={index}>
                                                                {/* <td className='hyperlink' style={{ cursor: "pointer" }} onClick={() => handleTimelineDetails(timeline)}>{timeline.eventName}</td> */}
                                                                <td>{timeline.employeeName}</td>
                                                                <td>{timeline.employeeCode}</td>
                                                                <td>{formatDate(timeline.appointDate)}</td>
                                                                <td>{timeline.relievingDate ? formatDate(timeline.relievingDate) : "-"}</td>
                                                            </tr>
                                                        )))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>

                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                                            </li>
                                            {Array.from({ length: Math.ceil(timelines.length / itemsPerPage) }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 && 'active'}`}>
                                                    <a className="page-link" href="#" onClick={() => paginate(i + 1)}>{i + 1}</a>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === Math.ceil(timelines.length / itemsPerPage) && 'disabled'}`}>
                                                <a className="page-link" href="#" onClick={() => paginate(currentPage + 1)}>Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {isAddTimelineModalOpen && <AddHRManager onClose={handleCloseTimelineModal} onUpdate={handleUpdateTimelines} />}
                    <DeleteConfirmationModal
                        isOpen={isDeleteModalOpen}
                        onClose={() => setIsDeleteModalOpen(false)}
                        onConfirm={handleDeleteConfirmation}
                        deleteReason={deleteReason}
                        setDeleteReason={setDeleteReason}
                    />
                </div>
            </div>
        </div>
    );
}

export default HRManagerTimLine;
