import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EditBonous_Insentive = ({ onClose, onUpdate, bonousinsentive }) => {
    const [formData, setFormData] = useState({
        departmentId: '',
        departmentName: '',
        employeeId: '',
        employeeName: '',
        employeeCode: '',
        paymentType: '', // Incentive or Bonus
        fromDate: '',
        toDate: '',
        amount: '',
        reason: '',
        declarationDate: '',
        remark: '',
        username: localStorage.getItem('username'),
    });

    const [isLoading, setIsLoading] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [errors, setErrors] = useState({});
    const [isIncentive, setIsIncentive] = useState(true);

    useEffect(() => {
        // Set initial form data when bonousinsentive is provided
        if (bonousinsentive) {
            setFormData({ ...bonousinsentive });
            setIsIncentive(bonousinsentive.paymentType === 'incentive');
        }
    }, [bonousinsentive]);

    const fetchDepartments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`);
            setDepartments(response.data);
        } catch (error) {
            console.error('Error fetching departments:', error);
        }
    };

    const fetchEmployees = async (departmentId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employee_department/${departmentId}`);
            setEmployees(response.data);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    useEffect(() => {
        fetchDepartments();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'departmentId') {
            const department = departments.find(dep => dep.id === parseInt(value, 10));
            setFormData({
                ...formData,
                departmentId: value,
                departmentName: department ? department.name : '',
            });
            fetchEmployees(value);
        } else if (name === 'employeeId') {
            const employee = employees.find(emp => emp.id === parseInt(value, 10));
            setFormData({
                ...formData,
                employeeId: value,
                employeeName: employee ? employee.employeeName : '',
                employeeCode: employee ? employee.employeeCode : '',
            });
        } else if (name === 'paymentType') {
            setIsIncentive(value === 'incentive');
            setFormData({
                ...formData,
                paymentType: value,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await axios.put(`${process.env.REACT_APP_LOCAL_URL}/addBonusIncentive/${bonousinsentive.id}`, formData);
            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload();
            }, 1000);
        } catch (error) {
            console.error('Error updating data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div id="edit-bonus-incentive" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit {isIncentive ? 'Incentive' : 'Bonus'}</h5>
                            <button type="button" className="close" onClick={handleClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label>Department<span style={{ color: "red" }}>*</span></label>
                                    <input name="departmentId" type="text" className="form-control" value={formData.departmentName} onChange={handleChange} readOnly />
                                    {errors.departmentId && <span style={{ color: "red" }}>{errors.departmentId}</span>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Employee<span style={{ color: "red" }}>*</span></label>
                                    <input name="employeeId" type="text" className="form-control" value={formData.employeeName} onChange={handleChange} readOnly />
                                    {errors.employeeId && <span style={{ color: "red" }}>{errors.employeeId}</span>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-4">
                                    <label>Payment Type<span style={{ color: "red" }}>*</span></label>
                                    <select className="form-control" name="paymentType" value={formData.paymentType} onChange={handleChange}>
                                        <option value="">Select Payment Type</option>
                                        <option value="incentive">Incentive</option>
                                        <option value="bonus">Bonus</option>
                                    </select>
                                    {errors.paymentType && <span style={{ color: "red" }}>{errors.paymentType}</span>}
                                </div>
                                <div className="form-group col-md-4">
                                    <label>{isIncentive ? 'Incentive' : 'Bonus'} From Date<span style={{ color: "red" }}>*</span></label>
                                    <input name="fromDate" type="date" className="form-control" value={formData.fromDate} onChange={handleChange} />
                                    {errors.fromDate && <span style={{ color: "red" }}>{errors.fromDate}</span>}
                                </div>
                                <div className="form-group col-md-4">
                                    <label>{isIncentive ? 'Incentive' : 'Bonus'} To Date<span style={{ color: "red" }}>*</span></label>
                                    <input name="toDate" type="date" className="form-control" value={formData.toDate} onChange={handleChange} />
                                    {errors.toDate && <span style={{ color: "red" }}>{errors.toDate}</span>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label>{isIncentive ? 'Incentive' : 'Bonus'} Reason<span style={{ color: "red" }}>*</span></label>
                                    <textarea name="reason" type="text" className="form-control" placeholder="Reason" value={formData.reason} onChange={handleChange} />
                                    {errors.reason && <span style={{ color: "red" }}>{errors.reason}</span>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label>{isIncentive ? 'Incentive' : 'Bonus'} Amount<span style={{ color: "red" }}>*</span></label>
                                    <input name="amount" type="number" className="form-control" placeholder="Amount" value={formData.amount} onChange={handleChange} />
                                    {errors.amount && <span style={{ color: "red" }}>{errors.amount}</span>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Declaration Date<span style={{ color: "red" }}>*</span></label>
                                    <input name="declarationDate" type="date" className="form-control" value={formData.declarationDate} onChange={handleChange} />
                                    {errors.declarationDate && <span style={{ color: "red" }}>{errors.declarationDate}</span>}
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Remark</label>
                                <textarea name="remark" type="text" className="form-control" placeholder="Remark" value={formData.remark} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? 'Loading...' : 'Submit'}
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditBonous_Insentive;
