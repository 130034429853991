import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../../components/sidebar/Sidebar';
import SearchBar from '../../components/sidebar/SearchBar';
import EmployeeJoiningPreview from './EmployeeJoiningPreview';
import { width } from '@mui/system';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner


function EmployeeJoining({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Initialize with current month (+1 since months are 0-indexed)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // Initialize with current year
    const [employeeData, setEmployeeData] = useState([]); // State to hold employee joining data
    const [filteredEmployee, setFilteredEmployee] = useState([]); // State for filtered employee data
    const [grandTotals, setGrandTotals] = useState({ totalEmployees: 0 }); // State for grand totals
    const [showSidebar, setShowSidebar] = useState(true); // State to control sidebar visibility
    const [showSearchBar, setShowSearchBar] = useState(true); // State to control search bar visibility
    const [employeeDetailsVisible, setEmployeeDetailsVisible] = useState(false); // State for employee details modal
    const [selectedRecord, setSelectedRecord] = useState(null); // State for selected record


    useEffect(() => {
        fetchEmployees();
    }, []);

    useEffect(() => {
        filterEmployee();
    }, [selectedMonth, selectedYear, employeeData]);

    useEffect(() => {
        calculateTotals();
    }, [filteredEmployee]);

    const fetchEmployees = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
            const filteredEmployees = response.data.filter(
                employee => employee.status !== 'resign_terminate'
            );
            setEmployeeData(filteredEmployees);
        } catch (error) {
            console.error("Error fetching employees:", error);
            toast.error("Failed to fetch employee data.");
        }finally {
            setIsLoading(false);
        }
    };

    const filterEmployee = () => {
        // Filter the employee joining data based on selected month and year
        const filtered = employeeData.filter(employee => {
            const joiningDate = new Date(employee.joiningDate);
            return (
                joiningDate.getMonth() + 1 === selectedMonth && // +1 because JS months are 0-based
                joiningDate.getFullYear() === selectedYear
            );
        });
        setFilteredEmployee(filtered);
    };

    const calculateTotals = () => {
        // Calculate the total number of employees who joined in the selected month and year
        const totalEmployees = filteredEmployee.length;
        setGrandTotals({ totalEmployees });
    };

    const handleEmployeeDetails = () => {
        setSelectedRecord({
            date: new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
            status: filteredEmployee.length > 0 ? filteredEmployee[0].status : '',
            employeeData: { filteredEmployee },
            selectedMonth,
            selectedYear
        });

        setEmployeeDetailsVisible(true);
        setShowSidebar(false);
        setShowSearchBar(false);
    };

    const handleClosePreview = () => {
        setShowSidebar(true); // Show sidebar when closing preview
        setShowSearchBar(true); // Show search bar when closing preview
        setEmployeeDetailsVisible(false); // Hide employee details
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} `;
    };

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            {showSidebar && <Sidebar />}
            <div className='w-100'>
                {showSearchBar && <SearchBar className="searchbarr" username={username} handleLogout={handleLogout} />}
                <div className="container-fluid">
                    <ToastContainer />
                    {employeeDetailsVisible ? (
                        <EmployeeJoiningPreview
                            record={selectedRecord}
                            onClose={handleClosePreview}
                        />
                    ) : (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">Employee Joining Report</h6>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <label className='pt-2 text-black fw-bolder'>Filter:</label>
                                            <select
                                                className="form-control"
                                                value={selectedMonth}
                                                onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Month</option>
                                                {Array.from({ length: 12 }, (_, i) => (
                                                    <option key={i} value={i + 1}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
                                                ))}
                                            </select>
                                            <select
                                                className="form-control"
                                                value={selectedYear}
                                                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Year</option>
                                                {Array.from({ length: 10 }, (_, i) => (
                                                    <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <button className="btn btn-outline-primary" onClick={handleEmployeeDetails}><i className="fa fa-download"></i> PDF</button>
                                        </div>
                                    </div>
                                    <div className="card-body form-row">
                                        <div className='col-md-12' style={{ maxHeight: "450px", overflowY: "auto" }}>
                                        
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Employee</th>
                                                        <th>Employee Code</th>
                                                        <th>Employee Number</th>
                                                        <th>Department Name</th>
                                                        <th>Designation Name</th>
                                                        <th>Joining Company</th>
                                                        <th>Joining Office</th>
                                                        <th>Employee Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {/* Correct usage of spinner */}
                                                    <ThreeDots
                                                        color="#00BFFF"
                                                        height={80}
                                                        width={80}
                                                    />
                                                </div>
                                            ) : (filteredEmployee.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="9" className="text-center">No Employee Details Found for This Month.</td>
                                                        </tr>
                                                    ) : (
                                                        filteredEmployee.map((employee, index) => (
                                                            <tr key={index}>
                                                                <td className='text-nowrap'>{formatDate(employee.joiningDate)}</td>
                                                                
                                                                <td>{employee.employeeName}</td>
                                                                <td>{employee.employeeCode}</td>
                                                                <td>{employee.employeePhone}</td>
                                                                <td>{employee.departmentName}</td>
                                                                <td>{employee.positionName}</td>
                                                                <td>{employee.joiningCompany}</td>
                                                                <td>{employee.joiningOffice}</td>
                                                                <td>{employee.employeeType}</td>
                                                            </tr>
                                                        ))
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Totals</th>
                                                        <th>{grandTotals.totalEmployees}</th>
                                                        <th colSpan="7"></th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default EmployeeJoining;

