import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import '../Dashboard.css';
import AttendanceDetailsModal from '../AttendenceMaster/AttendanceDetailsModal';
import SidebarEmployee from '../../components/sidebar/SidebarEmployee';
import SearchBarEmployee from '../../components/sidebar/SearchBarEmployee';
import myImage from '../../images/employee_profile.png';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner


function E_AttendanceDetails({ handleLogout, username }) {
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [attendanceRecords, setAttendanceRecords] = useState([]);
    const [filteredAttendance, setFilteredAttendance] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [totalPresent, setTotalPresent] = useState(0);
    const [totalAbsent, setTotalAbsent] = useState(0);
    const [totalHalfDay, setTotalHalfDay] = useState(0);
    const [totalPaidLeave, setTotalPaidLeave] = useState(0);
    const [totalUnpaidLeave, setTotalUnpaidLeave] = useState(0);
    const [totalOvertime, setTotalOvertime] = useState(0);
    const [totalWeeklyOff, setTotalWeeklyOff] = useState(0);
    const [totalAttendance, setTotalAttendance] = useState(0);
    const [totalDays, setTotalDays] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const employeeId = localStorage.getItem('employeeId');

    useEffect(() => {
        if (employeeId) {
            fetchEmployeeDetails(employeeId);
        }
    }, [employeeId]);

    useEffect(() => {
        if (selectedEmployee) {
            fetchAttendanceRecords(selectedEmployee.id);
        }
    }, [selectedEmployee, selectedMonth, selectedYear]);

    useEffect(() => {
        const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
        setTotalDays(daysInMonth);
    }, [selectedMonth, selectedYear]);

    const fetchEmployeeDetails = async (employeeId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employee/${employeeId}`);
            if (response.data.length > 0) {
                const employeeData = response.data[0];
                setSelectedEmployee(employeeData);
                console.log("Employee Details:", employeeData);
            }
        } catch (error) {
            console.error('Error fetching employee details:', error);
        }
    };

    const fetchAttendanceRecords = async (employeeId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/attendance/${employeeId}`);
            const attendanceData = response.data || [];

            const combinedData = attendanceData.map(attendance => {
                const employee = employees.find(emp => emp.id === attendance.employee_id);
                return {
                    ...attendance,
                    employeeName: employee ? employee.employeeName : '',
                    passportSizePhoto: employee ? employee.passportSizePhoto : ''
                };
            });

            setAttendanceRecords(combinedData);
            filterAttendanceRecords(selectedMonth, selectedYear, combinedData);
        } catch (error) {
            console.error('Error fetching attendance records:', error);
        }finally {
            setIsLoading(false);
        }
    };

    const filterAttendanceRecords = (month, year, data) => {
        const filteredRecords = data.filter(record => {
            const recordDate = new Date(record.date);
            return recordDate.getMonth() === month && recordDate.getFullYear() === year;
        });

        const presentCount = filteredRecords.filter(record =>
            record.status.toLowerCase() === 'present'
        ).length;
        const absentCount = filteredRecords.filter(record =>
            record.status.toLowerCase() === 'absent'
        ).length;
        const halfDayCount = filteredRecords.filter(record =>
            record.status.toLowerCase() === 'half day'
        ).length;
        const paidLeaveCount = filteredRecords.filter(record =>
            record.status.toLowerCase() === 'paid leave'
        ).length;
        const unpaidLeaveCount = filteredRecords.filter(record =>
            record.status.toLowerCase() === 'unpaid leave'
        ).length;
        const overtimeCount = filteredRecords.filter(record =>
            record.status.toLowerCase() === 'overtime'
        ).length;
        const weeklyOffCount = filteredRecords.filter(record =>
            record.status.toLowerCase() === 'weekly off'
        ).length;

        const totalAttendance = presentCount + absentCount + halfDayCount + paidLeaveCount + unpaidLeaveCount + overtimeCount + weeklyOffCount;
        setTotalAttendance(totalAttendance);
        setTotalPresent(presentCount);
        setTotalAbsent(absentCount);
        setTotalHalfDay(halfDayCount);
        setTotalPaidLeave(paidLeaveCount);
        setTotalUnpaidLeave(unpaidLeaveCount);
        setTotalOvertime(overtimeCount);
        setTotalWeeklyOff(weeklyOffCount);
        setFilteredAttendance(filteredRecords);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
    };


    return (
        <div className=''>
            <div className='d-flex w-100 h-100 bg-white'>
                <SidebarEmployee />
                <div className='w-100'>
                    <SearchBarEmployee username={username} handleLogout={handleLogout}/>
                    <div className="container-fluid">
                        <ToastContainer />
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-column flex-md-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">Attendance List</h6>
                                        <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-center gap-1 mt-2 mt-md-0'>
                                            <label className='pt-2 text-black fw-bolder'>Filter:</label>
                                            <select className="form-control" value={selectedMonth}
                                                onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Month</option>
                                                {Array.from({ length: 12 }, (_, i) => (
                                                    <option key={i} value={i}>{new Date(2000, i).toLocaleString('default', { month: 'long' })}</option>
                                                ))}
                                            </select>
                                            <select className="form-control" value={selectedYear}
                                                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                                            >
                                                <option value="">Select Year</option>
                                                {Array.from({ length: 10 }, (_, i) => (
                                                    <option key={i} value={new Date().getFullYear() - i}>{new Date().getFullYear() - i}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="card-body row">
                                        <div className='col-md-10' style={{ maxHeight: "500px", overflowY: "auto" }}>
                                            <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                    <tr>
                                                        <th className="d-none d-md-table-cell">Photo</th>
                                                        <th>Name</th>
                                                        <th>Date</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {isLoading ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {/* Correct usage of spinner */}
                                                    <ThreeDots
                                                        color="#00BFFF"
                                                        height={80}
                                                        width={80}
                                                    />
                                                </div>
                                            ) : (
                                                    filteredAttendance.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="4" className="text-center">No Attendance Found of This Month.</td>
                                                        </tr>
                                                    ) : (
                                                        filteredAttendance
                                                            .sort((a, b) => new Date(a.date) - new Date(b.date))
                                                            .map(record => (
                                                                <tr key={`${record.id}-${record.date}`}>
                                                                    <td className="text-center d-none d-md-table-cell">
                                                                        <img
                                                                            src={
                                                                                selectedEmployee.passportSizePhoto
                                                                                    ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${selectedEmployee.passportSizePhoto}`
                                                                                    : myImage
                                                                            }
                                                                            style={{ width: "50px", height: "auto" }}
                                                                            className="img-fluid "
                                                                            
                                                                        />
                                                                    </td>
                                                                    <td>{selectedEmployee.employeeName}</td>
                                                                    <td>{formatDate(record.date)}</td>
                                                                    <td>{record.status}</td>
                                                                </tr>
                                                            ))
                                                    ))}
                                                </tbody>
                                            </table>

                                        </div>
                                        <div className="card-footer col-md-2">
                                            <h5 className="text-primary text-center fw-semibold">Total Days</h5>
                                            <hr />
                                            <div className="d-flex flex-md-column flex-wrap gap-3">
                                                <div className="bg-success col text-white p-2 text-center border rounded">Present: {totalPresent}</div>
                                                <div className="bg-danger col text-white p-2 text-center border rounded">Absent: {totalAbsent}</div>
                                                <div className="bg-info col text-white p-2 text-center border rounded">Half Day: {totalHalfDay}</div>
                                                <div className="bg-warning text-white p-2 text-center border rounded">Weekly Off: {totalWeeklyOff}</div>
                                                <div className="bg-warning text-white p-2 text-center border rounded">Unpaid Leave: {totalUnpaidLeave}</div>
                                                <div className="bg-warning text-white p-2 text-center border rounded">Paid Leave: {totalPaidLeave}</div>
                                                <div className="bg-primary text-white p-2 text-center border rounded">Overtime: {totalOvertime}</div>
                                                <div className="bg-black text-white p-2 text-center border rounded">Total: {totalAttendance}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default E_AttendanceDetails;








