
import React, { useState, useEffect } from "react";
import axios from "axios";
import AddEmployeeTable from "./AddEmployeeTable";
import EmployeeDetails from "./EmployeeDetails";
import EditEmployeeModal from "./EditEmployeeModal";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import CreateUser from "./CreateUser";
import Sidebar from "../../components/sidebar/Sidebar";
import SearchBar from "../../components/sidebar/SearchBar";
import ActiveInactiveModal from "./ActiveInactiveModal";
import AddTransferEmployee from "./AddTransferEmployee";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Employeelist.css"
import Employee_Resign_Termination_List from "./Employee_Resign_Termination_List";
import myImage from '../../images/employee_profile.png';
import EmployeePrint from "./EmployeePrint";
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner


function Employeelist({ handleLogout, username }) {
  const [isLoading, setIsLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showEmployeeDetails, setShowEmployeeDetails] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddTransferEmployeeOpen, setIsAddTransferEmployeeOpen] = useState(false);
  const [editEmployeeData, setEditEmployeeData] = useState(null);
  const [employeeData, setEmployeeData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);
  const [deleteEmployee, setDeleteEmployee] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false); // State for ActiveInactiveModal 
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = employees.slice(indexOfFirstItem, indexOfLastItem);
  // showresigntermination
  const [showresigntermination, setshowresigntermination] = useState(false); // Added state to control visibility of RenewalInsurance modal
  // Show EmployeePint 
  const [printEmployeeData, setPrintEmployeeData] = useState(null);
  const [showEmployeePrint, setShowEmployeePrint] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(true);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employees`);
      const filteredEmployees = response.data.filter(
        employee => employee.status !== 'resign_terminate'
      );
      setEmployees(filteredEmployees);
    } catch (error) {
      console.error("Error fetching employees:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleAddEmployee = () => {
    setIsEmployeeModalOpen(true);
  };

  const handleCloseEmployeeModal = () => {
    setIsEmployeeModalOpen(false);
  };

  const handleEditEmployee = (employee) => {
    setEditEmployeeData(employee);
    setIsEditModalOpen(true);
  };

  const handleDeleteEmployee = (employee) => {
    setDeleteEmployee(employee);
    setIsDeleteModalOpen(true);
  };


  // Transfer modal   

  const handleAddTransfer = (employee) => {
    setIsAddTransferEmployeeOpen(true);
    setEmployeeData(employee)
  };

  const handleCloseTransferModal = () => {
    setIsAddTransferEmployeeOpen(false);
  };


  const handleDeleteConfirmation = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_LOCAL_URL}/employees/${deleteEmployee.id}`
      );

      const deletedEmployee = { ...deleteEmployee, reason: deleteReason };
      await axios.post(
        `${process.env.REACT_APP_LOCAL_URL}/delete_details`,
        deletedEmployee
      );

      setEmployees((prevEmployees) =>
        prevEmployees.filter((employee) => employee.id !== deleteEmployee.id)
      );

      setIsDeleteModalOpen(false);
      console.log("Employee deleted successfully");
    } catch (error) {
      console.error("Error deleting employee:", error);
    }
  };

  const handleEmployeeDetails = (employee) => {
    setSelectedEmployee(employee);
    setShowEmployeeDetails(true);
  };

  const handleUpdateEmployees = () => {
    toast.success('Data uploaded successfully');
    fetchEmployees();
  };

  const handleCreateEmployee = (employee) => {
    setSelectedEmployee(employee); // Set selected employee for creation
    setIsCreateModalOpen(true); // Open create modal
  };

  const handleStatusModalOpen = (employee) => {
    setEditEmployeeData(employee); // Set the selected employee
    setIsStatusModalOpen(true); // Open the modal
  };
  const handleEmployeePrint = (employee) => {
    setPrintEmployeeData(employee)
    setShowSidebar(false); // Set to false to hide sidebar
    setShowSearchBar(false);
    setShowEmployeePrint(true);
  };

  const handleClosePreview = () => {
    setShowSidebar(true); // Set to true to hide sidebar
    setShowSearchBar(true);
    setShowEmployeePrint(false);
  };

  const getToggleClass = (status) => {
    return status === 'active' ? 'toggle active' : 'toggle inactive';
  };
  return (
    <div className='d-flex w-100 h-100 bg-white '>
      {showSidebar && <Sidebar />}
      <div className='w-100'>
        {showSearchBar && <SearchBar className="searchbarr" username={username} handleLogout={handleLogout} />}
        <div className="container-fluid">
          <ToastContainer />
          {showEmployeeDetails ? (
            <EmployeeDetails
              employee={selectedEmployee}
              onClose={() => setShowEmployeeDetails(false)}
            />
          ) : showresigntermination ? (
            <Employee_Resign_Termination_List
              employee={selectedEmployee}
              onClose={() => setshowresigntermination(false)}
            />
          ) : (
            showEmployeePrint ? (
              <EmployeePrint
                record={printEmployeeData}
                onClose={handleClosePreview}
              />
            ) : (
              <div className="row">
                <div className="col-xl-12">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <div className="d-flex gap-3">
                        <button className="m-0 font-weight-bold btn btn-primary">
                          Active Employee List
                        </button>
                        <button onClick={() => setshowresigntermination(true)} className="m-0 font-weight-bold btn btn-outline-primary">
                          Inactive Employee List
                        </button>
                      </div>
                      <div className="d-flex gap-2">
                        {/* <button onClick={(e) => window.print()} className="btn btn-outline-success">
                        <i className="fa fa-download"></i>
                      </button> */}
                        <button onClick={handleAddEmployee} className="btn btn-outline-primary">
                          Add New Employee
                        </button>
                      </div>
                    </div>
                    <div className="card-body" >
                      <div style={{ maxHeight: "450px", overflowY: "auto" }}>
                        <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                          <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                            <tr>
                              <th>Employee Picture</th>
                              <th>Employee Name/Designation</th>
                              <th>Employee Code</th>
                              <th>Phone No.</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <style>
                              {`.hyperlink:hover { color: blue; }`}
                            </style>
                            {isLoading ? (
                              <div className="d-flex justify-content-center align-items-center">
                                {/* Correct usage of spinner */}
                                <ThreeDots
                                  color="#00BFFF"
                                  height={80}
                                  width={80}
                                />
                              </div>
                            ) : (employees && (
                              <>
                                {currentItems.length === 0 ? (
                                  <tr>
                                    <td colSpan="12" className="text-center">There are No Employees.</td>
                                  </tr>
                                ) : (
                                  currentItems.map((employee) => (
                                    <tr key={employee.id}>
                                      <td>
                                        <img
                                          src={employee.passportSizePhoto
                                            ? `${process.env.REACT_APP_LOCAL_URL}/uploads/employees/${employee.passportSizePhoto}`
                                            : myImage}
                                          style={{ width: "90px" }} className="employee-image"
                                        />
                                      </td>
                                      <td className="hyperlink" style={{ cursor: "pointer" }} onClick={() => handleEmployeeDetails(employee)}>
                                        {employee.employeeName || "N/A"}
                                        <hr />
                                        {employee.positionName || "N/A"}
                                        <br />
                                        {employee.departmentName || "N/A"}
                                      </td>
                                      <td>{employee.employeeCode || "N/A"}</td>
                                      <td>{employee.employeePhone || "N/A"}</td>
                                      <td>
                                        <div className="d-flex align-items-center justify-content-start gap-3">
                                          <div className="btn-group">
                                            <button className="btn btn-sm btn-primary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                            </button>
                                            <div className="dropdown-menu actionmenu">
                                              <a className="dropdown-item" href="#" onClick={() => handleEmployeeDetails(employee)}>
                                                <i className="fa fa-file"></i> Details
                                              </a>
                                              <a className="dropdown-item" href="#" onClick={() => handleEditEmployee(employee)}>
                                                <i className="fas fa-edit"></i> Edit
                                              </a>
                                              <a className="dropdown-item" href="#" onClick={() => handleAddTransfer(employee)}>
                                                <i className="fa fa-arrow-right"></i> Transfer
                                              </a>
                                              <a className="dropdown-item" href="#" onClick={() => handleCreateEmployee(employee)}>
                                                <i className="fa fa-plus"></i> Employee Create
                                              </a>
                                              <a className="dropdown-item" href="#" onClick={() => handleEmployeePrint(employee)}>
                                                <i className="fa fa-print"></i> Print
                                              </a>
                                              <a className="dropdown-item" href="#" onClick={() => handleDeleteEmployee(employee)}>
                                                <i className="fa fa-trash"></i> Delete
                                              </a>
                                            </div>
                                          </div>
                                          <div
                                            className={getToggleClass(employee.status)}
                                            onClick={() => handleStatusModalOpen(employee)}
                                          >
                                            <div
                                              className="ball"
                                              style={{
                                                backgroundColor:
                                                  employee.status === 'active' || employee.status === 'request_leave' || employee.status === 'reject' ? 'green' :
                                                    employee.status === 'hold' ? 'blue' :
                                                      employee.status === 'leave' ? 'yellow' :
                                                        employee.status === 'suspended' || employee.status === 'resign_terminate' ? 'red' : ''
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </>
                            ))}
                          </tbody>
                        </table>

                      </div>
                      {/* Pagination */}
                      <ul className="pagination">
                        <li className={`page-item ${currentPage === 1 && "disabled"}`}>
                          <a className="page-link" href="#" onClick={() => paginate(currentPage - 1)}>Previous</a>
                        </li>
                        {Array.from(
                          {
                            length: Math.ceil(employees.length / itemsPerPage),
                          },
                          (_, i) => (
                            <li
                              key={i}
                              className={`page-item ${currentPage === i + 1 && "active"
                                }`}
                            >
                              <a
                                className="page-link"
                                href="#"
                                onClick={() => paginate(i + 1)}
                              >
                                {i + 1}
                              </a>
                            </li>
                          )
                        )}
                        <li
                          className={`page-item ${currentPage ===
                            Math.ceil(employees.length / itemsPerPage) &&
                            "disabled"
                            }`}
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={() => paginate(currentPage + 1)}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {isEditModalOpen && (
            <EditEmployeeModal
              employee={editEmployeeData}
              onClose={() => setIsEditModalOpen(false)}
              onUpdate={handleUpdateEmployees}
            />
          )}
          {isAddTransferEmployeeOpen && (
            <AddTransferEmployee
              employee={employeeData}
              onClose={() => setIsAddTransferEmployeeOpen(false)}
              onUpdate={handleUpdateEmployees}
            />
          )}
          <DeleteConfirmationModal
            isOpen={isDeleteModalOpen}
            itemName={deleteEmployee ? deleteEmployee.employeeName : "dd"}
            onDelete={handleDeleteConfirmation}
            onClose={() => setIsDeleteModalOpen(false)}
            deleteReason={deleteReason}
            setDeleteReason={setDeleteReason}
          />
          {/* Employee Creation Modal */}
          {isCreateModalOpen && (
            <CreateUser
              employee={selectedEmployee}
              onClose={() => setIsCreateModalOpen(false)}
              onUpdate={handleUpdateEmployees}
            />
          )}
          {isEmployeeModalOpen && <AddEmployeeTable
            onClose={handleCloseEmployeeModal}
            onUpdate={handleUpdateEmployees} />}
          {isStatusModalOpen && (
            <ActiveInactiveModal
              employee={editEmployeeData}
              onClose={() => setIsStatusModalOpen(false)}
              onUpdate={handleUpdateEmployees}
              userType={localStorage.getItem('userType')}
            // Other props as needed
            />
          )}
        </div>
      </div>
    </div>

  );
}

export default Employeelist;

